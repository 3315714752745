import React, {useContext, useState} from 'react';
import st from './SelectGroup.module.css';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import {Link, useNavigate} from "react-router-dom";
import SelectGroupCard from "../../../../components/dashboard/group/SelectGroupCard/SelectGroupCard";
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import Base from "../../../../components/UI/bases/Base";
import classes from "../../../../components/templates/Dashboard/Dashboard.module.css";
import Input from "../../../../components/UI/inputs/Input";

const SelectGroup = () => {

    const {store} = useContext(Context);
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    React.useEffect(() => {
        if (store.currentUser && store.currentUser.groups && store.currentUser.groups.length === 0) {
            navigate('/dashboard');
        }
        if (store.currentUser && store.currentUser.groups && store.currentUser.groups.length === 1) {
            store.setCurrentGroupById(store.currentUser.groups[0].id);
            navigate('/dashboard');
        }
        if (store.currentGroup.id) {
            navigate('/dashboard');
        }
    }, [store, store.currentUser, store.currentUser.groups, store.currentGroup.id, navigate]);

    const selectGroup = (groupId) => {
        store.setCurrentGroupById(groupId);
        navigate('/dashboard');
    }

    return (
        <div className={st.BG}>
            <CardTemplate className={st.upperCard}>
                <Input
                    placeholder={"Поиск"}
                    value={searchValue}
                    onChange={handleSearchChange}
                />
                <Link to='/lk'>
                    <Base className={classes.headerUserCard}>
                        <img src={store.currentUser.avatar} alt="avatar"/>
                        <div className={st.userCard}>{store.currentUser.username}</div>
                    </Base>
                </Link>
            </CardTemplate>
            <div className={st.groupsContainer}>
                {
                    store.currentUser &&
                    store.currentUser.groups &&
                    store.currentUser.groups.filter(
                        (group) =>
                            group.name.toLocaleLowerCase().includes(searchValue.toLowerCase()) ||
                            group.description.toLocaleLowerCase().includes(searchValue.toLowerCase()) ||
                            group.alias.toLocaleLowerCase().includes(searchValue.toLowerCase())
                    ).map(group =>
                        <SelectGroupCard action={selectGroup} group={group} key={group.id}/>
                    )
                }
            </div>
        </div>
    );
};

export default observer(SelectGroup);