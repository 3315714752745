import React from 'react';


interface Props {
    title?: String | any
    children?: any
}

const BaseTitle = ({title, children}: Props) => {
    return (
        <div className={'text-lg font-bold'}>
            {title || children}
        </div>
    );
};

export default BaseTitle;