import React from 'react';
import classes from "./QueueCard.module.css";
import {Link} from "react-router-dom";
import cn from 'classnames';
import CardTemplate from "../../../UI/bases/CardTemplate";
import {observer} from "mobx-react-lite";
import LightBadge from "../../../UI/badge/LightBadge/LightBadge";
import {useTranslation} from "react-i18next";

const QueueCard = ({queue}) => {
    const {t} = useTranslation();

    return (
        <Link to={`${queue.alias}`}>
            <CardTemplate hovered={true}
                          className={`${classes.queue} ${queue.status === 'archived' ? classes.archive : ''}`}>
                <div className={classes.queueStatus}>
                <span className={cn({
                    [classes.bollActive]: queue.status === "ACTIVE",
                    [classes.bollClosed]: queue.status === "CLOSED",
                    [classes.bollInactive]: queue.status === "WAITING",
                    [classes.bollArchived]: queue.status === "ARCHIVED",
                })}>
                    <span></span>
                </span>
                </div>
                <div className={classes.queueInfo}>
                    <span className={classes.subject}>{queue.title}</span>
                    <div className={classes.queueInfoList}>

                        <LightBadge
                            title={t('status')}
                            value={queue.status === 'ACTIVE' ? t('open') :
                                queue.status === 'CLOSED' ? t('close') :
                                    queue.status === 'WAITING' ? t('waiting') : t('archive')}
                        />
                        {
                            queue.openTime &&
                            <LightBadge
                                title={t('queueViewPage.openIn')}
                                value={queue.openTime}
                            />
                        }

                        {
                            queue.closeTime &&
                            <LightBadge
                                title={t('queueViewPage.closeIn')}
                                value={queue.closeTime}
                            />
                        }

                        <LightBadge
                            title={t('queueViewPage.queueCard.shuffle')}
                            value={queue.willBeShuffled === 'YES' ? t('yes') : queue.willBeShuffled === 'NO' ? t('no') : t('already')}
                        />

                        <LightBadge
                            title={t('queueViewPage.queueCard.occupied')}
                            value={queue.takenPlaces.toString()}
                        />
                    </div>
                </div>
                {/*<div className={classes.queueBtn}>*/}
                {/*    <Link to={`${queue.alias}`}><Button>{t('enter')}</Button></Link>*/}
                {/*</div>*/}
            </CardTemplate>
        </Link>
    );
};

export default observer(QueueCard);