import React, {useContext, useEffect, useState} from 'react';
import st from './Schedule.module.css';
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";
import {UniversityShortResponse} from "../../../models/university/UniversityShortResponse";
import CardTemplate from "../../UI/bases/CardTemplate";
import ScheduleLesson from "./ScheduleLesson/ScheduleLesson";
import {BackwardIcon} from "@heroicons/react/24/outline";
import Base from "../../UI/bases/Base";
import {runInAction} from "mobx";
import {observer} from "mobx-react-lite";

interface Props {
    university?: UniversityShortResponse | null
    group?: string
}

const Schedule = ({university, group}: Props) => {

    const {store} = useContext(Context);
    const {t} = useTranslation();

    const [isLoaded, setIsLoaded] = React.useState(false)
    const [currentWeek, setCurrentWeek] = React.useState(0)
    const [showWeek, setShowWeek] = React.useState(0)
    const [schedule, setSchedule] = React.useState<any>([])
    const [lessonsTime, setLessonsTime] = useState<any[]>()
    const [evenOrOdd, setEvenOrOdd] = useState('even')
    const [showEmptyLessons] = useState(false)
    const [showMonday, setShowMonday] = useState<Date>(new Date())
    const [currentMonday, setCurrentMonday] = useState<Date>(new Date())

    const getDate = (numOfDay: number) => {
        const date = new Date(showMonday)
        date.setDate(date.getDate() + numOfDay)
        return date.toLocaleDateString()
    }

    const nextWeek = () => {
        runInAction(() => {
            if (showWeek >= 52) return
            setEvenOrOdd(evenOrOdd === 'even' ? 'odd' : 'even')
            setShowWeek(showWeek + 1)
            const date = new Date(showMonday)
            // Смещаем на 7 дней вперед (следующая неделя) с возможностью перехода на следующий месяц
            date.setDate(date.getDate() + 7)
            setShowMonday(date)
        })
    }
    const prevWeek = () => {
        runInAction(() => {
            if (showWeek <= 1) return
            setEvenOrOdd(evenOrOdd === 'even' ? 'odd' : 'even')
            setShowWeek(showWeek - 1)
            const date = new Date(showMonday)
            date.setDate(date.getDate() - 7)
            setShowMonday(date)

        })
    }

    const goCurrentWeek = () => {
        setShowWeek(currentWeek)
        setShowMonday(currentMonday)
        setEvenOrOdd(currentWeek % 2 === 0 ? 'even' : 'odd')
    }

    useEffect(() => {
        const getCurrentWeek = async () => {
            const response = await store.schedule.getCurrentWeek()
            setCurrentWeek(response)
            setShowWeek(response)
            setEvenOrOdd(response % 2 === 0 ? 'even' : 'odd')
        }
        const getSchedule = async () => {
            if (university === undefined) return
            const response = await store.schedule.getSchedule(group || '')
            setSchedule(response[0])

            console.log(response[0])
            parseLessonsTime(response[0].lessonsTimes[0])
        }
        const parseLessonsTime = (data: any) => {
            setLessonsTime(data)
            // console.log(data)
        }
        getCurrentWeek()
        getSchedule()


        // Получаем дату понедельника текущей недели
        const getMonday = () => {
            const today = new Date();
            const dayOfWeek = today.getDay();
            const monday = new Date(today);
            monday.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
            setShowMonday(monday)
            setCurrentMonday(monday)
        }
        getMonday()

        setIsLoaded(true)

    }, [setIsLoaded, setSchedule, setCurrentMonday, setCurrentWeek, setShowWeek, setEvenOrOdd, setShowMonday, university, group, store.schedule, setLessonsTime]);

    return (
        <div>
            <CardTemplate className={st.filterContainer}>

                <Base className={st.weeks}>
                    <div className={st.npBtn} onClick={prevWeek}>
                        <BackwardIcon className={'w-6 h-6'}/>
                    </div>
                    <div className={st.currentWeek} onClick={goCurrentWeek}>
                        {t('week')}: {showWeek}
                    </div>
                    <div className={st.npBtn} onClick={nextWeek}>
                        <BackwardIcon className={'w-6 h-6 transform rotate-180'}/>
                    </div>
                </Base>
            </CardTemplate>
            <div className={'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-4'}>
                {
                    isLoaded &&
                    schedule?.schedule?.map((day: any, index: number) => {
                            const schedule = day[evenOrOdd][0]?.length ? day[evenOrOdd] : day[evenOrOdd]
                            return (
                                <CardTemplate key={index}>
                                    <div className={st.day}>
                                        <div className={st.dayInfo}>
                                            <div className={st.dayName}>
                                                {day.day}
                                            </div>
                                            <div className={st.dayDate}>
                                                {
                                                    getDate(index)
                                                }
                                            </div>
                                        </div>
                                        <div className={'flex flex-col gap-2'} key={index}>
                                            {
                                                schedule.map((lesson: any, index2: number) =>
                                                    <ScheduleLesson
                                                        week={showWeek}
                                                        key={index2}
                                                        number={index2}
                                                        lesson={lesson}
                                                        showEmptyLessons={showEmptyLessons}
                                                        lessonsTime={lessonsTime || []}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                </CardTemplate>
                            )
                        }
                    )
                }
            </div>
        </div>
    );
};

export default observer(Schedule);