import React from 'react';
import Button from "../../../UI/buttons/Button";
import cl from "./GroupsListCard.module.css"
import {Link} from "react-router-dom";
import LightBadge from "../../../UI/badge/LightBadge/LightBadge";
import {useTranslation} from "react-i18next";
import CardTemplate from "../../../UI/bases/CardTemplate";

const GroupsListCard = ({group, isMine, action, isActive}) => {
    const {t} = useTranslation();
    return (
        <Link to={`/d/groups/${group.alias}`}>
            <CardTemplate hovered={true} className={isActive ? 'activeBadge' : ''}>
                <div className={cl.mainBlock}>
                    <div className={cl.groupGeneralBlock}>
                        {
                            group.university?.shortName &&
                            <LightBadge title={t('university')} value={group.university.shortName}/>
                        }
                        <LightBadge title={t('group')} value={group.name}/>
                        <LightBadge title={t('code')} value={group.alias}/>
                        {
                            group.description &&
                            <LightBadge title={t('description')} value={group.description}/>
                        }
                    </div>
                    <div className={cl.buttonBlock}>
                        {
                            !isActive &&
                            <Button
                                onClick={() => action(group.id)}
                                color={"blue"}
                                width={"sm-full"}
                            >
                                {
                                    isMine ? t('choose') : t('application')
                                }
                            </Button>
                        }
                    </div>
                </div>
            </CardTemplate>
        </Link>
    );
};

export default GroupsListCard;