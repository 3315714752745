import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
// @ts-ignore
import {Context} from "../../index";

type Props = {
    group?: boolean,
    perm: string,
    className?: any
    children?: React.ReactNode | any
    groupAlias?: string
} & typeof defaultProps;

const defaultProps = {
    group: false,
    className: '',
};

const HavePermission = ({children, className, group, perm, groupAlias, ...other}: Props) => {
    // @ts-ignore
    const {store} = useContext(Context);

    // флаг инверсии права
    let invert = false;
    if (perm[0] === '!') {
        invert = true;
        perm = perm.slice(1);
    }

    if (!store.havePermission(perm, group, groupAlias) !== invert) {
        return <></>
    }
    return (
        <div className={className} {...other}>
            {children}
        </div>
    );
};

export default observer(HavePermission);