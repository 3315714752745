import React from 'react';
import st from "./Label.module.css"

interface Props {
    title: string,
    svg?: React.ReactNode,
    value?: string | React.ReactNode | React.ReactNode[] | null
}

const Label = ({title, svg, value}: Props) => {
    return (
        <div className={st.label}>
            <div className={st.svg}>{svg === null ? <div className={'w-5 h-5'}/> : svg }</div>
            <div className={st.title}>{title}:</div>
            <div className={st.value}>{value}</div>
        </div>
    );
};

export default Label;