import React, {useState} from 'react';
import classes from './Footer.module.css';
import {LanguageIcon} from "@heroicons/react/24/outline";
import Button from "../buttons/Button";
import ChangeLanguageModal from "../modals/ChangeLanguageModal";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ChangeLanguageModal
                open={isOpen}
                setOpen={setOpen}
            />
            <footer className={classes.footerContainer}>
                <div className={classes.footerContent}>
                    <div className={classes.footerRight}>
                        <b>MBC Studio © 2019-2022</b> {t('allRightsReserved')}
                    </div>
                    <div className={classes.footerRight}>
                        <Button
                            type={'button'}
                            onClick={() => setOpen(true)}
                            isIcon={true}>
                            <LanguageIcon className={'w-6 h-6'}/>
                        </Button>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;