import $api from "../http";
import {UUID} from "crypto";

export default class SwitchProposalService {
    /**
     * Создание предложения на смену
     * @param groupId - id группы
     * @param queueId - id очереди
     * @param userToSwitchId - id пользователя, с которым предлагается смениться
     */
    static async createSwitchProposal(groupId: String, queueId: String, userToSwitchId: String) {
        const json = JSON.stringify({
            queueId: queueId,
            userToSwitch: userToSwitchId});
        try {
            return await $api.post(`/groups/${groupId}/queues/switch`, json);
        } catch (error: any) {
            throw SwitchProposalService.handleError(error);
        }
    }

    static async cancelSwitchProposal(groupId: UUID, proposalId: string) {
        try {
            return await $api.post(`/groups/${groupId}/queues/switch/${proposalId}/cancel`);
        } catch (error: any) {
            throw SwitchProposalService.handleError(error);
        }

    }

    /**
     * Обработка ошибок
     */
    static handleError(error: any) {
        // console.log(error.response.data.detail);
        // console.log(error.response?.data?.detail);
        // console.log(!!error.response?.data?.detail);
        if (error.response) {
            // Ошибка пришла от сервера (например, неверный пароль)
            return error.response.data;
        } else if (error.request) {
            // Ошибка при выполнении запроса (например, проблемы с сетью)
            return {
                success: false,
                message: 'Ошибка выполнения запроса',
            };
        } else if (error.response?.data?.detail) {
            // Ошибка пришла от сервера (например, неверный пароль)
            return {
                success: false,
                message: error.response.data.detail,
            };
        } else {
            // Непредвиденная о
            return {
                success: false,
                message: 'Непредвиденная ошибка',
            }
        }
    }


    /**
     * Получение списка предложений на смену
     * @param groupId - id группы
     * @param proposalId - id предложения
     */
    static async denySwitchProposal(groupId: UUID, proposalId: string) {
        try {
            return await $api.post(`/groups/${groupId}/queues/switch/${proposalId}/reject`);
        } catch (error: any) {
            throw SwitchProposalService.handleError(error);
        }

    }

    /**
     * Получение списка предложений на смену
     * @param groupId - id группы
     * @param proposalId - id предложения
     */
    static async acceptSwitchProposal(groupId: UUID, proposalId: string) {
        try {
            return await $api.post(`/groups/${groupId}/queues/switch/${proposalId}/accept`);
        }
        catch (error: any) {
            throw SwitchProposalService.handleError(error);
        }
    }
}