import React, {useContext} from 'react';
import Dashboard from "../../../components/templates/Dashboard/Dashboard";
import st from './DashboardPage.module.css';
import ScheduleWidget from "../../../components/widgets/ScheduleWidget/ScheduleWidget";
import ProposalsWidget from "../../../components/widgets/WidgetProposals/WidgetProposals";
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";
import CardTemplate from "../../../components/UI/bases/CardTemplate";

const DashboardPage = () => {
    const {store} = useContext(Context);
    const {t} = useTranslation();
    return (
        <Dashboard title='Главная'>
            <div className={st.list}>
                {
                    store.currentGroup?.name ?
                        <>
                            <ScheduleWidget/>
                            <ProposalsWidget/>
                        </>
                        :
                        <CardTemplate className={'p-5'}>{t('widget.utils.noGroups')}</CardTemplate>
                }
            </div>
        </Dashboard>
    );
};

export default DashboardPage;