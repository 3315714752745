import {Dialog} from '@headlessui/react'
import st from './Modals.module.css'
import Button from "../buttons/Button";
import PropTypes from "prop-types";
import ModalTemplate from "../bases/ModalTemplate";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import Textarea from "../inputs/Textarea";
import {Context} from "../../../index";


const EditGroupInfoModal = (props) => {
    const {t} = useTranslation();
    const {store} = useContext(Context)

    const {open, setOpen, group} = props

    const {register, handleSubmit, formState: {errors}, watch} = useForm({
        defaultValues: {
            description: group.description,
        },
        mode: "onBlur"
    });
    const onSubmit = async (data) => {
        await store.group.editGroup(group.id, data.description)
        setOpen(false)
        store.utils.notify(t('groupPage.settings.reloadPage'), 'info')
    }

    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={st.mainContent}>
                        <div className={st.mainContentContainer}>
                            <div className="h-full mt-3 w-full text-center sm:mt-0 sm:text-left">
                                <Dialog.Title as="h3"
                                              className={`${st.header} ${st.createGroupTitle}`}>
                                    {t('groupPage.settings.changeDescriptionTitle')}
                                </Dialog.Title>
                                <div className={st.body}>
                                    <div className={st.body}>
                                        <div className={st.inputBlock}>
                                            <Textarea
                                                title={"Описание"}
                                                name={"description"}
                                                type={"text"} resize={false}
                                                errors={errors}
                                                register={register}
                                                initialvalue={group.description}
                                                validationSchema={{
                                                    maxLength: {
                                                        value: 100,
                                                        message: "Максимум 100 символов",
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={st.btnBlock}>
                        <Button
                            color={"blue"}
                            width={"sm-full"}
                            disabled={group.description === watch('description')}
                        >
                            {t('edit')}
                        </Button>
                        <Button
                            color={"gray"}
                            outline={true}
                            onClick={() => setOpen(false)}
                            width={"sm-full"}
                        >
                            {t('cancel')}
                        </Button>
                    </div>
                </form>
            </Dialog.Panel>
        </ModalTemplate>
    )
}

EditGroupInfoModal.defaultProps = {
    open: false,
    setOpen: null,
    group: null,
}

EditGroupInfoModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    group: PropTypes.object,
}


export default EditGroupInfoModal;