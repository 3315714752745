import React from 'react'
import {ThemeContext, themes} from "../contexts/ThemeContext";

const getTheme = () => {
    const theme = `${window?.localStorage?.getItem('theme')}`
    if (Object.values(themes).includes(theme)) return theme

    return themes.default;
}

const ThemeProvider = ({children}) => {
    const [theme, setTheme] = React.useState(getTheme)

    React.useEffect(() => {
        document.documentElement.dataset.theme = theme
        localStorage.setItem('theme', theme)
    }, [theme])


    // const toggleTheme = ({theme}) => {
    //     const newTheme = theme
    //     console.log('newTheme', newTheme, theme)
    //
    //     window.localStorage.setItem('theme', newTheme)
    //     setTheme(newTheme)
    // }

    return (
        <ThemeContext.Provider value={{theme, setTheme}}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeProvider