import React, {useContext, useState} from 'react';
import st from './UserMainInfo.module.css'
import {IUserAdminProfileResponse} from "../../../../models/user/IUserAdminProfileResponse";
import CardTemplate from "../../../UI/bases/CardTemplate";
import StandardTitle from "../../../UI/titles/StandardTitle";
import CopyInput from "../../../utils/CopyInput/CopyInput";
import {useForm} from "react-hook-form";
import {CheckCircleIcon, PencilSquareIcon, XCircleIcon} from "@heroicons/react/24/outline";
import cn from "classnames";
import ConfirmModal from "../../../UI/modals/ConfirmModal.jsx";
import Input from "../../../UI/inputs/Input.jsx";
import {useTranslation} from "react-i18next";
import {UserEditCredentialsRequest} from "../../../../models/user/UserEditCredentialsRequest";
import {Context} from "../../../../index";

type UserMainInfoProps = {
    user: IUserAdminProfileResponse;
    reload: () => void;
}

const UserMainInfo = ({user, reload}: UserMainInfoProps) => {
    const {t} = useTranslation()
    const {store} = useContext(Context)
    const [editMode, setEditMode] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [userEditCredentialsRequest, setUserEditCredentialsRequest] = useState<UserEditCredentialsRequest>({} as UserEditCredentialsRequest)
    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onChange",
        defaultValues: {
            lastName: user.lastName,
            firstName: user.firstName,
            patronymic: user.patronymic,
        }
    })

    const onSubmit = async (data: any) => {
        setUserEditCredentialsRequest(data)
        setConfirmModal(true)
    }

    const confirmEdit = async () => {
        setConfirmModal(false)
        if (await store.user.editUserCredentials(user.id, userEditCredentialsRequest)) {
            store.utils.notify('Данные успешно изменены', 'success')
            setEditMode(false)
            reload()
        } else {
            store.utils.notify('Ошибка изменения данных', 'error')
        }
    }


    return (
        <CardTemplate className={st.content}>
            <form onSubmit={handleSubmit(onSubmit)} className={st.content}>
                <div className={st.title}>
                    <StandardTitle title={'Основная информация'}/>
                    {
                        editMode ?
                            <div className={st.actions}>
                                <button type={'submit'} className={cn(st.icon, st.iconOk)}>
                                    <CheckCircleIcon
                                        className={cn(st.icon, st.iconOk)}/>
                                </button>
                                <XCircleIcon onClick={() => setEditMode(!editMode)} className={st.icon}/>
                            </div>
                            :
                            <PencilSquareIcon onClick={() => setEditMode(!editMode)} className={st.icon}/>
                    }
                </div>
                <div onSubmit={handleSubmit(onSubmit)}
                     className={st.attrList}>
                    <CopyInput value={user.id} title={'ID'} blurred={editMode}/>
                    <CopyInput value={user.username || ''} title={'Логин'} blurred={editMode}/>

                    {
                        editMode ?
                            <>
                                <Input
                                    title={t('lastName')}
                                    name={"lastName"}
                                    type={"text"}
                                    errors={errors}
                                    register={register}
                                    validationSchema={{
                                        required: t('valMessageAuth.lastName'),
                                        minLength: {
                                            value: 2,
                                            message: t('valMessage.minSymbols2')
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: t('valMessage.maxSymbols')
                                        }
                                    }}
                                />
                                <Input
                                    title={t('firstName')}
                                    name={"firstName"}
                                    type={"text"}
                                    errors={errors}
                                    register={register}
                                    validationSchema={{
                                        required: t('valMessageAuth.firstName'),
                                        minLength: {
                                            value: 2,
                                            message: t('valMessage.minSymbols2')
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: t('valMessage.maxSymbols')
                                        }
                                    }}
                                />

                                <Input
                                    title={t('middleName')}
                                    name={"patronymic"}
                                    type={"text"}
                                    errors={errors}
                                    register={register}
                                    validationSchema={{
                                        maxLength: {
                                            value: 100,
                                            message: t('valMessage.maxSymbols')
                                        }
                                    }}
                                />
                            </>
                            :
                            <>
                                <CopyInput value={user.lastName || ''} title={'Фамилия'}/>
                                <CopyInput value={user.firstName || ''} title={'Имя'}/>
                                <CopyInput value={user.patronymic || ''} title={'Отчество'}/>
                            </>
                    }

                </div>
            </form>

            <ConfirmModal
                open={confirmModal}
                setOpen={setConfirmModal}
                header={'Изменение данных'}
                body={'Вы действительно хотите изменить данные пользователя?'}
                confirmText={'Изменить'}
                action={confirmEdit}
            />
        </CardTemplate>
    );
};

export default UserMainInfo;