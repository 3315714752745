import React from 'react';
import LightBase from "./LightBase";

type Props = {
    hovered?: boolean
    children: React.ReactNode
    className?: any
}

const CardLightTemplate = ({children, className, hovered, ...other}: Props) => {
    return (
        <LightBase hovered={hovered} className={`template__card ${className}`} {...other}>
            {children}
        </LightBase>
    );
};

export default CardLightTemplate;