import React from 'react';
import classes from "./QueueSendSwapOfferUserCard.module.css";
import Button from "../../../../../UI/buttons/Button";
import cn from "classnames";
import {XMarkIcon} from "@heroicons/react/24/solid";

const QueueSendSwapOfferUserCard = ({offer, index, swapAction, movingUp, toPlace}) => {
    return (
        <tr className={`${classes.mainContainer} `}>
            <td className={classes.numberContainer}>
                <div className={cn(classes.number, {
                    [classes.movingUpNumber]: movingUp,
                    [classes.movingDownNumber]: !movingUp
                })}>
                    {toPlace + 1}
                </div>
            </td>
            <td>
                <div className={classes.userInfo}>
                    <img src={offer?.userToSwitch?.avatar}
                         className={classes.avatar}
                         alt={'avatar'}/>
                    <div className={classes.info}>
                        <div className={classes.name}>
                            <div className={classes.name}>{offer.userToSwitch.firstName} <span
                                className="hidden md:inline">{offer.userToSwitch.lastName}</span> <span
                                className="inline md:hidden">{offer.userToSwitch.lastName.substring(0, 1)}.</span></div>
                        </div>
                        <div className={classes.role}>
                            {offer.userToSwitch.username ? 'aka ' + offer.userToSwitch.username : ''}
                        </div>
                    </div>
                </div>
            </td>

            <td className={'w-full'}>
                <div className={classes.btnBlock}>
                    <Button
                        onClick={() => swapAction(index)}
                        widthFull={false}
                        size={"sm"}
                        color={"red"}
                        title={'Отменить'}
                    >
                        <XMarkIcon className="w-5 h-5"/>
                    </Button>
                </div>
            </td>
        </tr>
    );
};

export default QueueSendSwapOfferUserCard;