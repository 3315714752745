import React, {useContext, useEffect, useState} from 'react';
import classes from "./Queues.module.css";
import Button from "../../../../components/UI/buttons/Button";
import QueueCard from "../../../../components/dashboard/queue/QueueCard/QueueCard";
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import CreateQueueModal from "../../../../components/UI/modals/CreateQueueModal";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import Input from "../../../../components/UI/inputs/Input";
import {runInAction} from "mobx";
import {Tooltip} from "@mui/material";

const Queues = () => {
    const {t} = useTranslation();

    const {group} = useParams()

    const [createQueueModal, setCreateQueueModal] = useState(false)

    const [updating, setUpdating] = useState(false)

    const [queues, setQueues] = useState([])

    const navigate = useNavigate();

    const {store} = useContext(Context);

    const updateQueues = () => {
        setUpdating(true)
        store.getQueuesByGroupAlias(group).then((response) => {
            // сортируем по статусу
            // ACTIVE -> Waiting -> Closed
            response.sort((a, b) => {
                if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') {
                    return -1;
                }
                if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') {
                    return 1;
                }
                return 0;
            })
            runInAction(() => {
                setQueues(response)
            })
        })
            .finally(() => {
                setUpdating(false)
                handleSearchChange(searchValue, queueStatus)
            })
    }

    useEffect(() => {
        const fetchQueues = async () => {
            const response = await store.getQueuesByGroupAlias(group);

            // сортируем по статусу
            // ACTIVE -> Waiting -> Closed
            response.sort((a, b) => {
                if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') {
                    return -1;
                }
                if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') {
                    return 1;
                }
                return 0;
            });

            setQueues(response);
            setShowQueues(response);
        };

        fetchQueues();
    }, [store, group]);

    const createQueue = (data) => {
        store.createQueue(group,
            {
                title: data.subject,
                status: 'ACTIVE',
                openTime: data.openByTime ? data.openByTimeDate : null,
                closeTime: data.closeByTime ? data.closeByTimeDate : null,
                willBeShuffled: data.canShuffle ? 'YES' : 'NO',
            }).then((value) => {
            if (value !== undefined) {
                setCreateQueueModal(false)
                // updateQueues()
                navigate(`/g/${group}/queues/${value.alias}`)
                return true
            } else {
                return false
            }
        })
    }

    // Фильтры

    const [searchValue, setSearchValue] = useState("");

    const [showQueues, setShowQueues] = useState([]);

    const [queueStatus, setQueueStatus] = useState('ALL');
    const handleSearchChange = (value, status) => {
        const newQueues =
            queues.filter(
                (queue) =>
                    queue.title.toLocaleLowerCase().includes(value.toLowerCase())
            ).filter(
                (queue) =>
                    queue.status === status || status === 'ALL'
            )
        runInAction(() => {
            setShowQueues(newQueues)
            setSearchValue(value)
            setQueueStatus(status)
        })
    };

    return (
        <Dashboard title={t('queues')}>
            <CardTemplate className={classes.filterContainer}>
                {/*  Поиск, Выбор предмета, Выбор статуса, Кнопка добавить */}
                <div className={classes.title}>
                    <Input
                        width={'sm-full'}
                        placeholder={t('search')}
                        value={searchValue}
                        onChange={(e) => handleSearchChange(e.target.value.trim(), queueStatus)}
                    />

                    <div className={classes.filter}>
                        <Tooltip title={t('queueViewPage.filter.all')} placement="top" arrow>
                            <div className={classes.btn} onClick={
                                () => handleSearchChange(searchValue, 'ALL')
                            }>
                                <div className={cn(classes.bollArchived,
                                    [
                                        queueStatus === 'ALL' ? classes.active : '',
                                    ]
                                )}>
                                    <span></span>
                                </div>
                            </div>
                        </Tooltip>
                        <Tooltip title={t('queueViewPage.filter.open')} placement="top" arrow>
                            <div className={classes.btn} onClick={
                                () => handleSearchChange(searchValue, 'ACTIVE')
                            }>
                                <div className={cn(classes.bollActive,
                                    [
                                        queueStatus === 'ACTIVE' ? classes.active : '',
                                    ]
                                )}>
                                    <span></span>
                                </div>
                            </div>
                        </Tooltip>
                        <Tooltip title={t('queueViewPage.filter.waiting')} placement="top" arrow>
                            <div className={classes.btn} onClick={
                                () => handleSearchChange(searchValue, 'WAITING')
                            }>
                                <div className={cn(classes.bollInactive,
                                    [
                                        queueStatus === 'WAITING' ? classes.active : '',
                                    ]
                                )}>
                                    <span></span>
                                </div>
                            </div>
                        </Tooltip>
                        <Tooltip title={t('queueViewPage.filter.closed')} placement="top" arrow>
                            <div className={classes.btn} onClick={
                                () => handleSearchChange(searchValue, 'CLOSED')
                            }>
                                <div className={cn(classes.bollClosed,
                                    [
                                        queueStatus === 'CLOSED' ? classes.active : '',
                                    ]
                                )}>
                                    <span></span>
                                </div>
                            </div>
                        </Tooltip>
                    </div>

                    <Tooltip title={t('update')} placement="top" arrow>
                        <button
                            color={"gray"}
                            onClick={updateQueues}
                            disabled={updating}
                            className={classes.reload}
                        >
                            <ArrowPathIcon className={
                                cn(
                                    'w-5 h-5', {
                                        [classes.spin]: updating,
                                    }
                                )}/>
                        </button>
                    </Tooltip>
                </div>
                <div className={classes.headerBts}>
                    {
                        store.havePermission(
                            'GROUP_QUEUE_MANAGEMENT',
                            true,
                            group)
                        &&
                        <Button
                            onClick={() => setCreateQueueModal(true)}
                            color={'blue'}
                            width={'sm-full'}
                        >{t('queueViewPage.create')}</Button>
                    }
                </div>
            </CardTemplate>

            <div className={classes.queuesContainer}>
                {showQueues?.map((queue, index) =>
                    <QueueCard key={index} queue={queue}/>
                )}
            </div>

            <CreateQueueModal
                open={createQueueModal}
                setOpen={setCreateQueueModal}
                action={createQueue}
            />
        </Dashboard>

    );
};

export default observer(Queues);