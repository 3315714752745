import Store from "../store";
import {makeAutoObservable} from "mobx";
import {toast} from "react-toastify";

export default class UtilsStore {
    private rootStore: Store;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    notify(message: string, type: "success" | "error" | "warning" | "info" | undefined = undefined) {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            case 'warning':
                toast.warning(message);
                break;
            case 'info':
                toast.info(message);
                break;
            default:
                toast(message);
        }
    }

    copyToClipboard(text: string) {
        navigator.clipboard.writeText(text).then(() =>
            this.notify('Скопировано в буфер обмена')
        );
    }
}