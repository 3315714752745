import React from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ManageAssignment from "../../../../components/dashboard/assigments/ManageAssignment/ManageAssignment";

const CreateGroupAssignments = () => {
    const {group} = useParams();
    const {t} = useTranslation()
    return (
        <Dashboard title={t('assignments.manage.create')}>
            <ManageAssignment isNew={true} group={group}/>
        </Dashboard>
    );
};

export default CreateGroupAssignments;