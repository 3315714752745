import React, {useContext, useEffect, useState} from 'react';
import cl from './GroupInvite.module.css'
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import Button from "../../../../components/UI/buttons/Button";
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../../../../index";
import LightBadge from "../../../../components/UI/badge/LightBadge/LightBadge";
import {useTranslation} from "react-i18next";

const GroupInvite = () => {
    const {t} = useTranslation();

    let {code} = useParams()

    const {store} = useContext(Context);
    const navigate = useNavigate();

    const [invite, setInvite] = useState({});

    useEffect(() => {
        const asyncFunc = async () => {
            setInvite(await store.getInviteByCode(code));

            // Если пользователь уже состоит в группе, то перенаправляем его в группу
            if (store.currentUser.groups?.find(group => group.id === invite.group.id)) {
                navigate("/d/groups/" + invite.group.alias)
            }
        }
        asyncFunc();
    }, [store, code, navigate, invite.group?.id, invite?.group?.alias]);

    const joiningGroup = async () => {
        await store.joinGroupByInvite(invite.code).then(
            () => {
                navigate("/d/groups/" + invite.group.alias)
            }
        )
    }

    return (
        <Dashboard title={t('groupPage.groupInvite.title')}>
            <div className={cl.inviteContainer}>
                <CardTemplate className={cl.card}>
                    <div className={cl.title}>{t('groupPage.groupInvite.info')}</div>
                    <div className={cl.groupBlock}>
                        <LightBadge
                            title={t('university')}
                            value={"РТУ-МИРЭА"}
                        />
                        <LightBadge
                            title={t('group')}
                            loading={!!invite?.group?.name}
                            value={invite?.group?.name}
                        />
                        {/*<LightBadge*/}
                        {/*    title={t('groupPage.groupInvite.usages')}*/}
                        {/*    value={`${invite?.usageCount || "0"} ${t('groupPage.groupInvite.usagesOf')} ${invite?.usage || "??"}`}*/}
                        {/*/>*/}
                    </div>
                    {
                        invite?.group?.description &&
                        <div className={cl.descriptionBlock}>
                            <LightBadge
                                title={t('description')}
                                loading={!!invite?.group?.description}
                                value={invite?.group?.description}
                            />
                        </div>
                    }

                    {
                        invite?.active ?
                            <Button
                                width={"full"}
                                color={"green"}
                                onClick={joiningGroup}
                            >{t('groupPage.groupInvite.buttonEnterGroup')}</Button>
                            :
                            <Button
                                width={"full"}
                                color={"red"}
                                disabled={true}
                            >{t('groupPage.groupInvite.buttonNoActive')}</Button>
                    }
                </CardTemplate>
            </div>
        </Dashboard>
    );
};

export default GroupInvite;