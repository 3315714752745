import React, {useContext} from 'react';
import CardTemplate from "../../../UI/bases/CardTemplate";
import st from "./ChooseLanguage.module.css"
import {useTranslation} from "react-i18next";
import StandardTitle from "../../../UI/titles/StandardTitle";
import Button from "../../../UI/buttons/Button";
import {Context} from "../../../../index";


const ChooseLanguage = () => {
    const {t} = useTranslation();
    const {store} = useContext(Context);

    const langs = [
        {
            name: 'Русский',
            code: 'ru',
            flag: 'ru'
        },
        {
            name: 'English',
            code: 'en',
            flag: 'us'
        },
        {
            name: 'Français',
            code: 'fr',
            flag: 'fr'
        },
        {
            name: '中文',
            code: 'zh',
            flag: 'cn'
        },
        {
            name: ':3',
            code: 'ct',
            flag: 'tn'
        }
    ]
    return (
        <CardTemplate className={st.language}>
            <StandardTitle title={t('languageOfInterface')}/>
            <div className={st.langContainer}>
                {
                    langs.map((lang, index) =>
                        <Button
                            type={'button'}
                            color={store.langKey === lang.code ? 'blue' : 'gray'}
                            key={index}
                            onClick={() => {
                                store.setLangKey(lang.code);
                            }}
                            className={'cursor-pointer flex-grow'}
                            width={'auto'} size={'base'} outline={false} title={''} isIcon={false}                        >
                            <div className={st.langBtn}>
                                <img
                                    src={`https://flagcdn.com/${lang.flag}.svg`}
                                    width="30"
                                    alt="flag"/>
                                <div>{lang.name}</div>
                            </div>
                        </Button>
                    )
                }
            </div>
        </CardTemplate>
    );
};

export default ChooseLanguage;