import React, {useContext, useState} from 'react';
import classes from "./Dashboard.module.css";
import {Link, useNavigate} from "react-router-dom";
import ConfirmModal from "../../../components/UI/modals/ConfirmModal.jsx";
// @ts-ignore
import ThemeProvider from "../../../providers/ThemeProvider";
import {Divide as Hamburger} from 'hamburger-react'
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import Base from "../../UI/bases/Base";
import HavePermission from "../../common/HavePermission";
import {useTranslation} from "react-i18next";
import Chip from "../../UI/badge/Chip/Chip";
import UserDropdown from "../../UI/dropdown/UserDropdown/UserDropdown";

import st from "../../UI/dropdown/UserDropdown/UserDropdown.module.css";
import {ArrowRightOnRectangleIcon} from "@heroicons/react/24/outline";
import BottomMenu from "../../UI/BottomMenu/BottomMenu";

interface Props {
    children?: React.ReactNode | React.ReactNode[] | string | number;
    title?: string | undefined | null
}

const Dashboard = ({children, title}: Props) => {
    let [isOpen, setOpen] = useState(false)
    const {store}: any = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const logout = () => {
        console.log('logout')
        store.logout().then(
            () => {
                navigate('/login');
            }
        );
    }

    const [logoutModal, setLogoutModal] = useState(false);


    return (
        <ThemeProvider>
            <ConfirmModal
                open={logoutModal}
                setOpen={setLogoutModal}
                action={logout}
                header={t('logoutModal.title')}
                body={t('logoutModal.description')}
                confirmText={t('exit')}
            />
            <div className={classes.pageContainer}>
                <div className={classes.page}>
                    {/*<div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 inline-flex left-0 mx-auto justify-between bg-blue-600 w-11/12 rounded-3xl z-[9999]">*/}
                    {/*    <a aria-current="page" className="inline-flex flex-col items-center text-xs font-medium py-3 px-4 text-white flex-grow" href="#">*/}
                    {/*        <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />*/}
                    {/*        </svg>*/}
                    {/*        <span className="sr-only">Home</span>*/}
                    {/*    </a>*/}
                    {/*    <a className="inline-flex flex-col items-center text-xs font-medium text-blue-400 py-3 px-4 flex-grow" href="#">*/}
                    {/*        <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />*/}
                    {/*        </svg>*/}
                    {/*    </a>*/}
                    {/*    <span className="sr-only">Upload</span>*/}
                    {/*    <button className="relative inline-flex flex-col items-center text-xs font-medium text-white py-3 px-6 flex-grow">*/}
                    {/*        <div className="absolute bottom-5 p-3 rounded-full border-4 border-white bg-blue-600">*/}
                    {/*            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*                <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />*/}
                    {/*            </svg>*/}
                    {/*        </div>*/}
                    {/*        <span className="sr-only">Chat</span>*/}
                    {/*    </button>*/}
                    {/*    <a className="inline-flex flex-col items-center text-xs font-medium text-blue-400 py-3 px-4 flex-grow" href="#">*/}
                    {/*        <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />*/}
                    {/*        </svg>*/}
                    {/*        <span className="sr-only">Search</span>*/}
                    {/*    </a>*/}
                    {/*    <a className="inline-flex flex-col items-center text-xs font-medium text-blue-400 py-3 px-4 flex-grow" href="#">*/}
                    {/*        <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />*/}
                    {/*        </svg>*/}
                    {/*        <span className="sr-only">Profile</span>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    <div className={`${isOpen ? classes.leftMenuContainerOpen : classes.leftMenuContainer}`}>
                        <Base className={classes.leftMenu}>
                            <div className={classes.leftMenuTitle}>
                                <h1>MBC Diary</h1>
                                {
                                    store.currentGroup.id &&
                                    <Chip
                                        title={store.currentGroup.name}
                                        link={`/d/groups/${store.currentGroup.alias}`}
                                    />
                                }
                            </div>

                            <div className={classes.leftMenuContent}>
                                <div className={classes.avatarInLeftMenu}>
                                    <Link to={`/u/${store.currentUser.username}`} className={classes.linkToLk}>
                                        <Base className={classes.headerUserCard}>
                                            <img src={store.currentUser.avatar || ''} alt="avatar"/>
                                            <div className={st.userData}>
                                                <div
                                                    className={st.name}>{store.currentUser.lastName} {store?.currentUser?.firstName?.substr(0, 1)}.
                                                </div>
                                                <div className={st.username}> @{store.currentUser.username}</div>
                                            </div>
                                        </Base>
                                    </Link>
                                </div>

                                {/*<div className={classes.leftMenuGroup}>*/}
                                {/*    <h2>Основное</h2>*/}
                                {/*    <ul>*/}
                                {/*        <Link to='#'>Скоро</Link>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}

                                {/*{*/}
                                {/*    store.currentGroup.id &&*/}
                                {/*    <div className={classes.leftMenuGroup}>*/}
                                {/*            <Button className={classes.activeGroup} color={'gray'} size={'sm'} outline={false}*/}
                                {/*                    title={''} width={'full'} isIcon={false}>*/}
                                {/*                <Link to={`/d/groups/${store.currentGroup.alias}`}>*/}
                                {/*                        <CheckIcon className={'w-5 h-5'}/>*/}
                                {/*                    {store.currentGroup.name}*/}
                                {/*                </Link>*/}
                                {/*            </Button>*/}
                                {/*    </div>*/}
                                {/*}*/}

                                <div className={classes.leftMenuGroup}>
                                    <h2>{t('menu.main')}</h2>
                                    <ul>
                                        <Link to={'/dashboard'}>{t('menu.dashboard')}</Link>
                                        <Link to={'/d/groups'}>{t('groups')}</Link>
                                        <Link to={'/d/assignments'}>{t('menu.local-assignments')}</Link>
                                    </ul>
                                </div>

                                {
                                    store.currentGroup.id &&
                                    <div className={classes.leftMenuGroup}>
                                        <h2>{t('menu.useful')}</h2>
                                        <ul>
                                            {
                                                store.currentGroup.university &&
                                                <Link
                                                    to={`/g/${store.currentGroup.alias}/schedule`}>{t('menu.schedule')}</Link>
                                            }
                                            <Link to={`/g/${store.currentGroup.alias}/queues`}>{t('menu.queues')}</Link>
                                            <Link
                                                to={`/g/${store.currentGroup.alias}/assignments`}>{t('menu.assignments')}</Link>
                                            {/*<Link to={'#'}>Диск</Link> /!*to='/disk'*!/*/}
                                            {/*<Link to={'#'}>Карта</Link> /!*to='/maps'*!/*/}
                                        </ul>
                                    </div>
                                }

                                <HavePermission
                                    perm={'ADMIN_VIEW_PANEL'}
                                    group={false}
                                    className={classes.leftMenuGroup}>
                                    <h2>{t('admin.menu.panel')}</h2>
                                    <ul>
                                        {/*<Link to={`/admin`}>Главная</Link>*/}
                                        <Link to={`/admin/users`}>{t('admin.menu.users')}</Link>
                                        {/*<Link to={'#'}>Диск</Link> /!*to='/disk'*!/*/}
                                        {/*<Link to={'#'}>Карта</Link> /!*to='/maps'*!/*/}
                                    </ul>
                                </HavePermission>
                                {
                                    process.env.NODE_ENV !== 'production' &&
                                    <div className={classes.leftMenuGroup}>
                                        <h2>{t('menu.development')}</h2>
                                        <ul>
                                            <Link to={'/home'}>{t('menu.mainPage')}</Link>
                                            <Link to={'/components'}>{t('menu.components')}</Link> {/*to='/schedule'*/}
                                            {/*<Link to={'#'}>Домашнее задание</Link> /!*to='/homeworks'*!/*/}
                                        </ul>
                                    </div>
                                }

                                <div className={'-mt-4'}>
                                    <div className={classes.leftMenuGroup}>
                                        <button onClick={() => setLogoutModal(true)}>
                                            <div className={'flex flex-row justify-between w-full'}>
                                                {t('user.menu.logout')}
                                                <ArrowRightOnRectangleIcon className={'w-5 h-5'}/>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                {/*<div className={classes.leftMenuGroup}>*/}
                                {/*    <h2>Полезное</h2>*/}
                                {/*    <ul>*/}
                                {/*        <Link to='/queue'>Очереди</Link>*/}
                                {/*        <Link to={'#'}>Диск</Link> /!*to='/disk'*!/*/}
                                {/*        <Link to={'#'}>Карта</Link> /!*to='/maps'*!/*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                                {/*<div className={classes.leftMenuGroup}>*/}
                                {/*    <h2>Сервисы</h2>*/}
                                {/*    <ul>*/}
                                {/*        <Link to={'#'}>Боты</Link>*/}
                                {/*        <Link to={'#'}>Новости</Link>*/}
                                {/*        <Link to={'#'}>Тренажёр</Link>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                            </div>
                        </Base>
                    </div>
                    <div className={classes.rightBlock}>
                        {/* Верхнее меню */}
                        <Base className={classes.rightBlockHeader}>
                            <div className={classes.rightBlockHeaderContent}>
                                <div className={classes.headerTitle}>
                                    {title}
                                </div>
                                <UserDropdown action={setLogoutModal}/>
                            </div>
                        </Base>
                        {/* ->Верхнее меню */}

                        {/* Верхнее мобильное меню */}
                        <Base className={classes.rightBlockHeaderMobile}>
                            <div className={classes.rightBlockHeaderContent}>
                                <div className={classes.headerTitle}>
                                    {title}
                                </div>
                                <Hamburger toggled={isOpen} toggle={setOpen} rounded/>
                            </div>
                        </Base>
                        {/* ->Верхнее мобильное меню */}

                        {/* Контент страницы */}
                        <main className={classes.content}>
                            {children}
                        </main>
                        {/* ->Контент страницы */}
                    </div>
                </div>
            </div>
            {
                store.pwa &&
                <BottomMenu/>
            }
        </ThemeProvider>
    );
};

export default observer(Dashboard);