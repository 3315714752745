import React, {useContext} from 'react';
import {useState} from "react";
import classes from './Header.module.css'
import {Link} from "react-router-dom";
import {Divide as Hamburger} from 'hamburger-react'
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import cn from "classnames";
import Button from "../buttons/Button";
import {useTranslation} from "react-i18next";

const Header = () => {
    let [isOpen, setOpen] = useState(false)
    const {t} = useTranslation()
    const {store} = useContext(Context);

    return (
        <div>
            <div className={classes.menuContainer}>
                <div className={classes.menuBlock}>
                    <div className={classes.menuLogo}>
                        <h3>MBC Diary</h3>
                    </div>
                    <ul className={classes.menuDesktop}>
                        <li><Link to={'/'} className={classes.desktopLink}> {t('home')}</Link></li>
                        <li><Link to={'/services'} className={classes.desktopLink}>{t('services')}</Link></li>
                        <li><Link to={'/team'} className={classes.desktopLink}>{t('contacts')}</Link></li>
                        {
                            store.isAuth ?
                                <div className={classes.desktopHighlight}>
                                    <Link to={'/dashboard'}>{t('dashboard')}</Link>
                                </div>
                                :
                                <>
                                    <li><Link to={'/login'} className={classes.desktopLink}>{t('signIn')}</Link></li>
                                    <li>
                                        <div className={classes.desktopHighlight}>
                                            <Link to={"/register"}>{t('signUp')}</Link>
                                        </div>
                                    </li>
                                </>
                        }
                    </ul>
                    <div className={classes.menuMobile}>
                        <Hamburger toggled={isOpen} toggle={setOpen} rounded/>


                        {/*<div className={isOpen ? classes.menuMobileList : classes.menuMobileListHidden}>*/}
                        {/*    <li><Link to={'/'} className={classes.desktopLink}>Главная</Link></li>*/}
                        {/*    <li><Link to={'/services'} className={classes.desktopLink}>Сервисы</Link></li>*/}
                        {/*    <li><Link to={'/team'} className={classes.desktopLink}>Контакты</Link></li>*/}
                        {/*    {*/}
                        {/*        store.isAuth ?*/}
                        {/*            <div>Выйти</div>*/}
                        {/*            :*/}
                        {/*            <>*/}
                        {/*                <li><Link to={'/login'} className={classes.desktopLink}>Войти</Link></li>*/}
                        {/*                <li>*/}
                        {/*                    <div className={classes.desktopHighlight}>*/}
                        {/*                        <Link to={"/register"}>Регистрация</Link>*/}
                        {/*                    </div>*/}
                        {/*                </li>*/}
                        {/*            </>*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={
                    cn(classes.menuMobileList, {
                        [classes.menuMobileListHidden]: !isOpen,
                    })}>
                    <div className={classes.menuMobileNav}>
                        <li><Link to={'/'} className={classes.mobileLink}>{t('home')}</Link></li>
                        <li><Link to={'/services'} className={classes.mobileLink}>{t('services')}</Link></li>
                        <li><Link to={'/team'} className={classes.mobileLink}>{t('contacts')}</Link></li>
                        {
                            store.isAuth ?
                                <li className={classes.mobileCabinet}>
                                    <Link to={'/dashboard'}>
                                        <Button type={'button'} title={t('dashboard')} color={'blue'} width={'full'}/>
                                    </Link>
                                </li>
                                :
                                <>
                                    <li className={classes.loginsMobile}>
                                        <Link to={'/login'}>
                                            <Button
                                                type={'button'}
                                                size={'lg'}
                                                title={t('signIn')}
                                                color={'blue'}
                                                width={'full'}/>
                                        </Link>
                                        <Link to={'/register'}>
                                            <Button
                                                type={'button'}
                                                size={'lg'}
                                                title={t('signUp')}
                                                color={'blue'}
                                                width={'full'}/>
                                        </Link>
                                    </li>
                                </>
                        }
                    </div>
                </div>

            </div>
            {/*<div className={*/}
            {/*    cn(classes.menuMobileBg, {*/}
            {/*        [classes.menuMobileBgHidden]: !isOpen*/}
            {/*    })}></div>*/}
        </div>
    );
};

export default observer(Header);