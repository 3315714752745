import React, {useCallback, useContext, useEffect, useState} from 'react';
import {AssignmentResponse} from "../../../../models/assignment/AssignmentResponse";
import st from './AssignmentsList.module.css';
import AssignmentCard from "../AssignmentCard/AssignmentCard";
import {Context} from "../../../../index";
import Input from "../../../UI/inputs/Input.jsx";
import {Link} from "react-router-dom";
import Button from "../../../UI/buttons/Button";
import CardTemplate from "../../../UI/bases/CardTemplate";
import {useTranslation} from "react-i18next";
import {Tooltip} from "@mui/material";
import cn from "classnames";
import {CircleStackIcon, ShieldCheckIcon} from "@heroicons/react/24/outline";

type Props = {
    group?: string | undefined;
}


const AssignmentsList = (
    {
        group,
    }: Props
) => {
    const {t} = useTranslation();
    const {store} = useContext(Context);

    const [assignments, setAssignments] = useState<[AssignmentResponse]>();
    const [showCompleted, setShowCompleted] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [savedAssignments, setSavedAssignments] = useState<[AssignmentResponse]>();

    const updateValue = useCallback(async () => {
        try {
            let updatedAssignments
            if (group) {
                updatedAssignments = await store.assignment.getForGroup<any[]>(group, showCompleted);
            } else {
                updatedAssignments = await store.assignment.getForUser<any[]>(showCompleted);
            }

            // @ts-ignore
            setSavedAssignments(updatedAssignments);

            if (!updatedAssignments || updatedAssignments.length === 0) return;


            // фильтруем по поиску
            if (searchValue) {
                updatedAssignments = updatedAssignments?.filter((assignment) => {
                    return assignment.title.toLowerCase().includes(searchValue.toLowerCase())
                })
            }

            // Сортируем кастомных фильтром
            updatedAssignments.sort((a, b) => {
                if (a.completedAt && !b.completedAt) {
                    return 1;
                } else if (!a.completedAt && b.completedAt) {
                    return -1;
                } else {
                    if (a.priority < b.priority)
                        return 1;
                    else if (a.priority > b.priority)
                        return -1;
                    else
                        return 0;
                }
            })

            // @ts-ignore
            setAssignments(updatedAssignments);

        } catch (error) {
            console.error("Error updating assignments:", error);
        }
    }, [
        group,
        showCompleted,
        searchValue,
        store.assignment
    ]);

    const handleChangedSearch = useCallback(async () => {
        if (searchValue === '') {
            setAssignments(savedAssignments);
        } else {
            // @ts-ignore
            setAssignments(assignments?.filter((assignment) => {
                return assignment.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                    assignment.content.toLowerCase().includes(searchValue.toLowerCase());
            }))
        }
    }, [
        searchValue,
        savedAssignments,
        assignments
    ]);

    const handleSearchChange = async (value: string) => {
        setSearchValue(value);
    }


    useEffect(() => {
        const up = async () => {
            await updateValue();
        }
        up()
    }, [
        showCompleted,
        updateValue
    ]);

    useEffect(() => {
        const up = async () => {
            await handleChangedSearch();
        }
        up()
    }, [
        searchValue,
        handleChangedSearch
    ]);

    const onInputChange = useCallback(async (e: { target: { value: string; }; }) => {
        await handleSearchChange(e.target.value);
    }, []);

    // @ts-ignore
    return (
        <>
            <CardTemplate className={st.filterContainer}>
                <div className={st.title}>
                    <Input
                        width={'sm-full'}
                        placeholder={t('search')}
                        value={searchValue}
                        onChange={onInputChange}
                    />
                    <div className={st.filter}>
                        <Tooltip title={t('assignments.filter.show_uncompleted')} placement="top" arrow>
                            <div className={cn(st.btn, {
                                [st.active]: showCompleted
                            })}
                                 onClick={() => setShowCompleted(false)}>
                                <ShieldCheckIcon
                                    className={cn(st.icon, st.iconBig,
                                        {
                                            [st.iconActive]: !showCompleted,
                                        })}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title={t('assignments.filter.show_all')} placement="top" arrow>
                            <div className={cn(st.btn, {
                                [st.iconActive]: showCompleted,
                            })}
                                 onClick={() => setShowCompleted(true)}>
                                <CircleStackIcon className={cn(st.icon, st.iconBig, {
                                    [st.iconActive]: showCompleted,
                                })}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className={st.headerBts}>
                    {
                        (
                            (
                                group &&
                                store.havePermission(
                                    'GROUP_ASSIGNMENT_MANAGEMENT',
                                    true,
                                    group)
                            ) || (group === undefined)
                        ) &&
                        <Link to={
                            group ?
                                `/g/${group}/assignments/create` :
                                '/d/assignments/create'
                        }>
                            <Button
                                onClick={() => {
                                }}
                                color={'blue'}
                                width={'sm-full'}
                                size={'base'}
                                outline={false}
                                title={''}
                                className={''}
                                isIcon={false}>{t('assignments.group.filter.create')}</Button>
                        </Link>
                    }
                </div>
            </CardTemplate>
            <div className={st.assignments}>
                {
                    assignments &&
                    assignments?.map((assignment, index) =>
                        <AssignmentCard assignment={assignment} update={updateValue} key={assignment.id}/>
                    )
                }
            </div>
            {
                // @ts-ignore
                assignments?.length === 0 &&
                // @ts-ignore
                savedAssignments?.length !== 0 &&
                searchValue !== '' &&
                <div className={st.empty}>
                    <div className={st.emptyTitle}>
                        {t('assignments.filter.empty')}
                    </div>
                </div>
            }
        </>
    );
};

export default AssignmentsList;