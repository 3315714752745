import React, {useContext} from 'react';
import {HomeIcon} from "@heroicons/react/20/solid";
import st from './BottomMenu.module.css'
import {AcademicCapIcon, QueueListIcon, UserCircleIcon} from "@heroicons/react/24/solid";
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {UserGroupIcon} from "@heroicons/react/24/outline";
import cn from "classnames";

const BottomMenu = () => {
    const {store} = useContext(Context);
    const {t} = useTranslation()
    return (
        <div className={st.menuBox}>
            {/*<div className={'z-[9999] absolute bottom-0 w-full'}>*/}
            <div className={st.menu}>
                <div className={st.menuList}>
                    <div className={st.item}>
                        <Link to={'/d/groups'} className={st.link}>
                            <span className={st.linkBox}>
                                <UserGroupIcon className={cn(st.icon, {
                                    [st.active]: window.location.pathname.includes('/d/groups')
                                })}/>
                                <span className={st.text}>{t('groups')}</span>
                            </span>
                        </Link>
                    </div>
                    {
                        store.currentGroup.id &&
                        <div className={st.item}>
                            <Link to={`/g/${store.currentGroup.alias}/queues`} className={st.link}>
                            <span className={st.linkBox}>
                                <QueueListIcon className={cn(st.icon, {
                                    [st.active]: window.location.pathname.includes('/g/' + store.currentGroup.alias + '/queues')
                                })}/>
                                <span className={st.text}>{t('queues')}</span>
                            </span>
                            </Link>
                        </div>
                    }
                    <div className={st.item}>
                        <Link to={'/dashboard'} className={st.link}>
                            <span className={st.linkBox}>
                                <HomeIcon className={cn(st.icon, {
                                    [st.active]: window.location.pathname.includes('/dashboard')
                                })}/>
                                <span className={st.text}>{t('menu.dashboard')}</span>
                            </span>
                        </Link>
                    </div>
                    {
                        store.currentGroup.id &&
                        <div className={st.item}>
                            <Link to={`/g/${store.currentGroup.alias}/schedule`} className={st.link}>
                            <span className={st.linkBox}>
                                <AcademicCapIcon className={cn(st.icon, {
                                    [st.active]: window.location.pathname.includes('/g/' + store.currentGroup.alias + '/schedule')
                                })}/>
                                <span className={st.text}>{t('schedule')}</span>
                            </span>
                            </Link>
                        </div>
                    }
                    <div className={st.item}>
                        <Link to={`/u/${store.currentUser?.username}`} className={st.link}>
                            <span className={st.linkBox}>
                                <UserCircleIcon className={cn(st.icon, {
                                    [st.active]: window.location.pathname.includes('/u/' + store.currentUser?.username)
                                })}
                                />
                                <span className={st.text}>{t('account')}</span>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BottomMenu;