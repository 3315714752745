import React from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import {useTranslation} from "react-i18next";
import ManageAssignment from "../../../../components/dashboard/assigments/ManageAssignment/ManageAssignment";

const CreateGroupAssignments = () => {
    const {t} = useTranslation()
    return (
        <Dashboard title={t('assignments.manage.create')}>
            <ManageAssignment isNew={true}/>
        </Dashboard>
    );
};

export default CreateGroupAssignments;