import React from 'react';
import PublicHome from "../../../components/templates/PublicHome/PublicHome";
import classes from "./PublicServicesHome.module.css";
import ServiceCard from "../../../components/services/ServiceCard/ServiceCard";

const PublicServicesHome = () => {

    let services = [
        {
            name: 'Расписание занятий',
            description: 'Расписание занятий в удобной и доступной форме, с функцией автоматического обновления с серверов вуза. Доступно с любых устройств и всегда актуально.',
            link: '/services/schedule'
        },
        {
            name: 'Информатика 9-я практика',
            description: 'Практика по информатике 1-й курс, Институт ИТ. Сервис поможет построить таблицу по введённому варианту.',
            link: '/services/informatics9'
        },
        // {
        //     name: 'Сервис',
        //     description: 'Расписание занятий в удобной и доступной форме, с функцией автоматического обновления с серверов вуза. Доступно с любых устройств и всегда актуально.',
        //     link: '/services/informatics9'
        // },
        // {
        //     name: 'Сервис',
        //     description: 'Расписание занятий в удобной и доступной форме, с функцией автоматического обновления с серверов вуза. Доступно с любых устройств и всегда актуально.',
        //     link: '/services/informatics9'
        // },
        // {
        //     name: 'Сервис',
        //     description: 'Расписание занятий в удобной и доступной форме, с функцией автоматического обновления с серверов вуза. Доступно с любых устройств и всегда актуально.',
        //     link: '/services/informatics9'
        // },
        // {
        //     name: 'Сервис',
        //     description: 'Расписание занятий в удобной и доступной форме, с функцией автоматического обновления с серверов вуза. Доступно с любых устройств и всегда актуально.',
        //     link: '/services/informatics9'
        // },
        // {
        //     name: 'Сервис',
        //     description: 'Расписание занятий в удобной и доступной форме, с функцией автоматического обновления с серверов вуза. Доступно с любых устройств и всегда актуально.',
        //     link: '/services/informatics9'
        // },
    ]



    return (
        <PublicHome>
            <div className={classes.pageContainer}>
                <div className={classes.pageContent}>
                    <div className={classes.title}>
                        <h1>Сервисы</h1>
                    </div>
                    <div className={classes.content}>
                        {services.map((service, index) => (
                            <ServiceCard service={service} key={index}/>
                        ))}
                    </div>
                </div>
            </div>
        </PublicHome>
    );
};

export default PublicServicesHome;