import Store from "../store";
import {makeAutoObservable} from "mobx";
import $api from "../../http";
import {IGroup} from "../../models/IGroup";
import {IGroupInfoResponse} from "../../models/group/IGroupInfoResponse";
import DateTimeService from "../../services/DateTimeService";
import {IUserInGroupResponse} from "../../models/user/IUserInGroupResponse";

export default class GroupStore {
    private rootStore: Store;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    /**
     * Выход из группы
     */
    async leaveGroup(groupId: string) {
        try {
            if (this.rootStore.currentGroup.id === groupId) {
                this.rootStore.currentGroup = {} as IGroup;
            }
            await $api.delete(`/groups/${groupId}/users`);
            this.rootStore.utils.notify('Вы вышли из группы', 'success');
            await this.rootStore.getCredentials();
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async deleteGroup(groupId: string) {
        try {
            if (this.rootStore.currentGroup.id === groupId) {
                this.rootStore.currentGroup = {} as IGroup;
                this.rootStore.currentGroupName = '';
            }
            await $api.delete(`/groups/${groupId}`);
            this.rootStore.utils.notify('Группа удалена', 'success');
            await this.rootStore.getCredentials();
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async editGroup(groupId: string, description: string) {
        try {
            const json = JSON.stringify({"description": description});
            await $api.put(`/groups/${groupId}`, json);
            this.rootStore.utils.notify('Описание группы изменено!', 'success');
            await this.rootStore.getCredentials();
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async getGroupInfo(groupId: string) {
        try {
            const response = await $api.get<IGroupInfoResponse>(`/groups/${groupId}/info`);
            response.data.createdAt = DateTimeService.convertBackDateToString(response.data.createdAt);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async getMembers(groupId: string) {
        try {
            const response = await $api.get<IUserInGroupResponse[]>(`/groups/${groupId}/members`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async deleteMember(groupId: string, userId: string) {
        try {
            await $api.delete(`/groups/${groupId}/members/${userId}`);
            this.rootStore.utils.notify('Пользователь удален', 'success');
            await this.rootStore.getCredentials();
        } catch (e) {
            console.log(e)
            this.rootStore.errorHandler(e)
        }
    }
}