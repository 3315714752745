import React from 'react';
import st from "./ChooseTheme.module.css"
import CardTemplate from "../../../UI/bases/CardTemplate";
import {useTranslation} from "react-i18next";
import ThemeMiniCard from "../../office/ThemeMiniCard/ThemeMiniCard";
import StandardTitle from "../../../UI/titles/StandardTitle";

const ChooseTheme = () => {
    const {t} = useTranslation();
    return (
        <CardTemplate className={st.themesContainer}>
            <StandardTitle title={t("office.theme.title")}/>
            <div className={st.themeList}>
                <ThemeMiniCard name='default'>{t('office.theme.default')}</ThemeMiniCard>
                <ThemeMiniCard name='darkness'>{t('office.theme.darkness')}</ThemeMiniCard>
                <ThemeMiniCard name='darkBlue'>{t('office.theme.darkBlue')}</ThemeMiniCard>
                <ThemeMiniCard name='purple'>{t('office.theme.purple')}</ThemeMiniCard>
                <ThemeMiniCard name='grayCalorie'>{t('office.theme.grayCalorie')}</ThemeMiniCard>
                <ThemeMiniCard name='graySomeone'>{t('office.theme.graySomeone')}</ThemeMiniCard>
                <ThemeMiniCard name='graySurfboard'>{t('office.theme.graySurfboard')}</ThemeMiniCard>
                <ThemeMiniCard name='black'>{t('office.theme.black')}</ThemeMiniCard>
                <ThemeMiniCard name='purple2'>{t('office.theme.lightPink')}</ThemeMiniCard>
                <ThemeMiniCard name='rainbowLine'>{t('office.theme.rainbowLine')}</ThemeMiniCard>
                <ThemeMiniCard name='blackRed'>{t('office.theme.MinusD')}</ThemeMiniCard>
                <ThemeMiniCard name='rainbowLight'>{t('office.theme.lightRainbow')}</ThemeMiniCard>
            </div>
        </CardTemplate>
    );
};

export default ChooseTheme;