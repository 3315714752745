export default class UtilsService {
    /**
     * Скрытие емейла
     *
     * @param email - емейл
     * @returns скрытый емейл
     */
    static hideEmail(email: string) {
        const parts = email.split('@');
        const name = parts[0];
        const domain = parts[1];
        const nameLength = name.length;
        const firstLetter = name[0];
        const lastLetter = name[nameLength - 1];
        const hiddenName = nameLength > 2 ? `${firstLetter}${'*'.repeat(nameLength - 2)}${lastLetter}` : "*";
        return `${hiddenName}@${domain}`;
    }
}