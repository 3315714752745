import React from "react";

export const themes = {
    default: 'default',
    purple: 'purple',
    darkness: 'darkness',
    grayCalorie: 'grayCalorie',
    graySomeone: 'graySomeone',
    graySurfboard: 'graySurfboard',
    black: 'black',
    purple2: 'purple2',
    rainbowLine: 'rainbowLine',
    blackRed: 'blackRed',
    rainbowLight: 'rainbowLight',
    darkBlue: 'darkBlue',
}

// https://gradients.shecodes.io/gradients/672

export const ThemeContext = React.createContext({})
