import React, {ReactNode} from 'react';
import st from './Button.module.css';
import cn from 'classnames';

type Props = {
    color?: "green" | "red" | "blue" | "gray" | "orange" | "dark"
    size?: "sm" | "md" | "base" | "lg"
    outline?: boolean
    title?: string | ReactNode
    width?: "full" | "fit" | "auto" | "sm-full"
    className?: string | any | undefined
    isIcon?: boolean
    children?: React.ReactNode,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    type?: "button" | "submit" | "reset" | undefined
} & typeof defaultProps;

const defaultProps = {
    color: "gray",
    size: "",
    outline: false,
    title: "",
    width: "auto",
    isIcon: false,
    className: '',
};

// @ts-ignore
const Button = (
    {
        color,
        outline,
        title,
        size,
        width,
        className,
        isIcon,
        children,
        onClick,
        type,
        disabled,
        ...other
    }: Props) => {

    return (
        <button {...other}
                type={type}
                className={cn(st.btn, {
                    [st.btnGray]: color === "gray" && !outline,
                    [st.btnRed]: color === "red" && !outline,
                    [st.btnBlue]: color === "blue" && !outline,
                    [st.btnGreen]: color === "green" && !outline,
                    [st.btnOrange]: color === "orange" && !outline,
                    [st.btnDark]: color === "dark" && !outline,

                    [st.btnOutlineGray]: color === "gray" && outline,
                    [st.btnOutlineRed]: color === "red" && outline,
                    [st.btnOutlineBlue]: color === "blue" && outline,
                    [st.btnOutlineGreen]: color === "green" && outline,
                    [st.btnOutlineOrange]: color === "orange" && outline,
                    [st.btnOutlineDark]: color === "dark" && outline,

                    [st.btnSm]: size === "sm",
                    [st.btnMd]: size === "md",
                    [st.btnLg]: size === "lg",

                    [st.btnOutline]: outline,
                    [st.wightFull]: width === "full",
                    [st.wightFit]: width === "fit",
                    [st.wightAuto]: width === "auto",
                    [st.wightSmFull]: width === "sm-full",

                    [st.disabled]: disabled,

                    [st.btnIcon]: isIcon,
                }, className)}
                disabled={disabled || false}
                onClick={onClick}
        >
            {title || children}
        </button>
    );
}

export default Button;