import React from 'react';
import CardTemplate from "../../components/UI/bases/CardTemplate";

const ComponentBlock = ({title, children, className}) => {
    return (
        <CardTemplate className={'w-full'}>
            <div className={"text-2xl font-semibold text-white mb-2"}>{title}</div>
            <div className={`flex flex-wrap gap-5 content-center justify-center w-full ${className}`}>{children}</div>
        </CardTemplate>
    );
};

export default ComponentBlock;