import React from 'react';
// @ts-ignore
import UsersListTemplate from "../../../UI/lists/UsersListTemplate";
import {useTranslation} from "react-i18next";
import QueueSendSwapOfferUserCard from "./cards/QueueSendSwapOfferUserCard/QueueSendSwapOfferUserCard";

// type Props = {
//     offers: {
//         toPlace: number,
//     }[];
//     swapAction: Function;
//     userInfo: {
//         placeInQueue: number
//     };
// }

const QueueSendSwapOfferList = (props) => {
    const {t} = useTranslation();
    const {offers, swapAction, getPlaceById} = props
    return (
        <UsersListTemplate title={t('queueViewPage.sentOffers')}>
            {offers.map((offer, index) =>
                <QueueSendSwapOfferUserCard
                    key={offer.id}
                    index={index}
                    swapAction={swapAction}
                    offer={offer}
                    toPlace={getPlaceById(offer.userToSwitch.id)}
                    movingUp={getPlaceById(offer.user.id) > getPlaceById(offer.userToSwitch.id)}
                />
            )}
        </UsersListTemplate>

    );
};

export default QueueSendSwapOfferList;