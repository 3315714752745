import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {IGroup} from "../../../../../models/IGroup";
import CardTemplate from "../../../../UI/bases/CardTemplate";
import {IUserInGroupResponse} from "../../../../../models/user/IUserInGroupResponse";
import {useTranslation} from "react-i18next";
import {Context} from "../../../../../index";
import StandardTitle from "../../../../UI/titles/StandardTitle";
import st from "../../../../UI/table/Tables.module.css";
import {Tooltip} from "@mui/material";
import {useTable} from "react-table";
import cn from "classnames";
import DateTimeService from "../../../../../services/DateTimeService";
import HavePermission from "../../../../common/HavePermission";
import {observer} from "mobx-react-lite";
import Chip from "../../../../UI/badge/Chip/Chip";
import ContactsBlock from "../../../user/contacts/ContactsBlock/ContactsBlock";
import {IdentificationIcon, XCircleIcon} from "@heroicons/react/24/outline";
import ConfirmModal from "../../../../UI/modals/ConfirmModal.jsx";

type GroupUsersInfoProps = {
    group: IGroup;
}

const GroupUsersInfo = ({group}: GroupUsersInfoProps) => {
    const {t} = useTranslation();
    const {store} = useContext(Context);
    const [users, setUsers] = useState<IUserInGroupResponse[]>([]);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [delText, setDelText] = useState('');
    const [delId, setDelId] = useState('');
    useEffect(() => {
        async function fetchUsers() {
            // if (users.length === 0) {
            const response = await store.group.getMembers(group.id);
            if (response) {
                response.sort((a, b) => {
                        if (a.lastName > b.lastName) {
                            return 1;
                        }
                        if (a.lastName < b.lastName) {
                            return -1;
                        }
                        return 0;
                    }
                );
                setUsers(response);
            }
            // }
        }

        fetchUsers();
    }, [group.id, store, group]);

    const deleteUser = useCallback((id: string) => {
        // ищем пользователя в массиве
        const user = users.find((user) => user.id === id);
        if (user) {
            setDelText(t('groupPage.users.deleteHeader', {name: user.username}) + ' ');
            setDelId(id);
            setConfirmDeleteModal(true);
        }
    }, [t, users]);

    const confirmDeleteUser = useCallback(async () => {
            if (delId) {
                await store.group.deleteMember(group.id, delId);
                setUsers(users.filter(user => user.id !== delId));
                setConfirmDeleteModal(false);
            }
        }
        , [delId, store.group, group.id, users]);

    const columns = useMemo(
        () => [
            {
                Header: <div className={st.id}>ID</div>,
                accessor: 'id' as const,
                Cell: ({value}: any) => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <code className={st.id}>{value.slice(-5)}</code>
                        </Tooltip>
                    )
                }
            },
            {
                Header: t('groupPage.users.avatar'),
                accessor: 'avatar' as const,
                Cell: ({value}: any) => {
                    return (
                        <img className={st.avatar} src={value} alt="avatar"/>
                    )
                }
            },
            {
                Header: t('username'),
                accessor: 'username' as const,
                Cell: ({value}: any) => {
                    return (
                        <div className={'w-fit'}>
                            <Chip title={value} link={`/u/${value}`}/>
                        </div>
                    )
                }
            },
            {
                Header: t('lastName'),
                accessor: 'lastName' as const,
            },
            {
                Header: t('firstName'),
                accessor: 'firstName' as const,
            },
            {
                Header: t('patronymic'),
                accessor: 'patronymic' as const,
            },
            {
                Header: t('groupPage.users.joinDate'),
                accessor: 'joinedAt' as const,
                Cell: ({value}: any) => {
                    return (
                        <div>{DateTimeService.convertBackDateToString(value)}</div>
                    )
                }
            },
            {
                Header: t('groupPage.users.roles'),
                accessor: 'roles' as const,
                Cell: ({value}: any) => {
                    return (
                        value.length === 0 ? <div className={'w-fit'}><Chip title={value.length}/></div> :
                            <Tooltip title={
                                <div className={'flex flex-col'}>{
                                    value.map((role: string) =>
                                        <div key={role}>{t(role)}</div>
                                    )
                                }
                                </div>
                            } arrow placement="top">
                                <div className={'w-fit'}><Chip title={value.length}/></div>
                            </Tooltip>
                    )
                }
            },
            {
                Header: t('groupPage.users.permissions'),
                accessor: 'permissions' as const,
                Cell: ({value}: any) => {
                    return (
                        value.length === 0 ? <div className={'w-fit'}><Chip title={value.length}/></div> :
                            <Tooltip title={
                                <div className={'flex flex-col'}>{
                                    value.map((perm: string) =>
                                        <div key={perm}>{t(perm)}</div>
                                    )
                                }
                                </div>
                            } arrow placement="top">
                                <div className={'w-fit'}><Chip title={value.length}/></div>
                            </Tooltip>
                    )
                }
            },
            {
                id: 'contacts',
                Header: t('groupPage.users.contacts'),
                accessor: 'contacts' as const,
                Cell: ({value}: any) => <ContactsBlock data={value}/>
            },
            {
                id: 'actions',
                Header:
                    <HavePermission group={true}
                                    perm={'GROUP_OWNER'}
                                    groupAlias={group.alias}
                                    className={''}>
                        <div>{t('groupPage.users.actions')}</div>
                    </HavePermission>,
                accessor: 'id' as const,
                Cell: ({value}: any) => {
                    return (
                        <div>
                            <HavePermission group={true}
                                            perm={'GROUP_OWNER'}
                                            groupAlias={group.alias}
                                            className={'flex flex-nowrap gap-2 w-full'}>
                                {store.currentUser?.id !== value &&
                                    <>
                                        <Tooltip title={t('soon')} placement={'top'} arrow>
                                            <IdentificationIcon
                                                className={'h-5 w-5 text-purple-500 cursor-pointer hover:scale-105'}/>
                                        </Tooltip>
                                        <Tooltip title={t('groupPage.users.deleteButton')} placement={'top'} arrow>
                                            <XCircleIcon
                                                onClick={() => deleteUser(value)}
                                                className={'h-5 w-5 text-red-500 cursor-pointer hover:scale-105'}/>
                                        </Tooltip>
                                    </>
                                }
                            </HavePermission>
                        </div>
                    )
                }
            }
        ], [t, group.alias, store.currentUser.id, deleteUser]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns, data: users});


    return (
        <CardTemplate>
            <div>
                <StandardTitle title={t('users')}/>
                <table {...getTableProps()} className={st.table}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr className={cn('template__light__base template__card')} {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <ConfirmModal
                open={confirmDeleteModal}
                setOpen={setConfirmDeleteModal}
                header={delText}
                body={t('groupPage.users.deleteDescription')}
                confirmText={t('groupPage.users.deleteButton')}
                action={confirmDeleteUser}
            />
        </CardTemplate>
    );
};

export default observer(GroupUsersInfo);