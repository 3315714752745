import React, {useRef} from 'react';
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {Dialog} from "@headlessui/react";
import st from "./Modals.module.css";
import Input from "../inputs/Input";
import Button from "../buttons/Button";
import ModalTemplate from "../bases/ModalTemplate";
import UserService from "../../../services/UserService";
import {useTranslation} from "react-i18next";

const EditPasswordModal = (props) => {
    const {t} = useTranslation();

    const {open, setOpen} = props

    const {register, watch, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: '',
        },
        mode: "onChange"
    });
    const cancelButtonRef = useRef(null)


    const onSubmit = (data) => {
        // Вызываем UserService.changePassword
        try {

            UserService.changePassword(data.oldPassword, data.newPassword).then(
                (response) => {
                    console.log(response)
                }
            )
        } catch (e) {
            console.log(e)
        }
        // UserService.changePassword(data)
    }

    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={st.mainContent}>
                        <div className={st.mainContentContainer}>
                            <div className="h-full mt-3 w-full sm:mt-0 text-left">
                                <Dialog.Title as="h3"
                                              className={`${st.header} ${st.createGroupTitle}`}>
                                    {t('office.change.title')}
                                </Dialog.Title>
                                <div className={st.body}>
                                    <div className={st.inputBlock}>
                                        <Input
                                            title={t('office.change.oldPassword')}
                                            name={"oldPassword"}
                                            type={"password"}
                                            errors={errors}
                                            register={register}
                                            validationSchema={{
                                                required: {
                                                    value: true,
                                                    message: t('valMessage.requiredField'),
                                                }
                                            }}
                                        />
                                        <Input
                                            title={t('office.change.newPassword')}
                                            name={"newPassword"}
                                            type={"password"}
                                            errors={errors}
                                            register={register}
                                            validationSchema={{
                                                required: {
                                                    value: true,
                                                    message: t('valMessage.requiredField'),
                                                },
                                                minLength: {
                                                    value: 4,
                                                    message: t('valMessage.minSymbols4')
                                                }
                                            }}
                                        />
                                        <Input
                                            title={t('office.change.repeatPassword')}
                                            name={"repeatNewPassword"}
                                            type={"password"}
                                            errors={errors}
                                            register={register}
                                            validationSchema={{
                                                required: {
                                                    value: true,
                                                    message: t('valMessage.requiredField'),
                                                },
                                                validate: (value) => {
                                                    return value === watch('newPassword') || t('valMessage.passwordMatch')
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={st.btnBlock}>
                        <Button
                            color={"blue"}
                            width={"sm-full"}
                            title={t('accept')}
                        >

                        </Button>
                        <Button
                            color={"gray"}
                            outline={true}
                            onClick={() => setOpen(false)}
                            width={"sm-full"}
                            ref={cancelButtonRef}
                            title={t('cancel')}
                        />
                    </div>
                </form>
            </Dialog.Panel>
        </ModalTemplate>
    );
};

EditPasswordModal.defaultProps = {
    open: false,
    setOpen: null,
    action: null,
    group: null
}

EditPasswordModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    action: PropTypes.func,
    group: PropTypes.any
}

export default EditPasswordModal;