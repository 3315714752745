import $api from "../http";
import {CreateInviteDTO} from "../models/group/CreateInviteDTO";

export default class InviteService {

    /**
     * Создание инвайта в группу владельцем
     * @param groupId - id группы
     * @param inviteDTO - данные инвайта
     */
    static async createInvite(groupId: String, inviteDTO: CreateInviteDTO) {
        const json = JSON.stringify(inviteDTO);
        try {
            return await $api.post(`/groups/${groupId}/invite-link`, json);
        } catch (error: any) {
            throw InviteService.handleError(error);
        }

    }

    /**
     * Получение инвайта группы по id группы
     * @param groupId - id группы
     */
    static async getInviteByGroupId(groupId: String) {
        try {
            return await $api.get(`/groups/${groupId}/invite-link`);
        } catch (error: any) {
            throw InviteService.handleError(error);
        }
    }

    /**
     * Деактивация инвайта группы по id группы
     * @param groupId - id группы
     */
    static async deactivateInviteByGroupId(groupId: String) {
        try {
            return await $api.delete(`/groups/${groupId}/invite-link`);
        } catch (error: any) {
            throw InviteService.handleError(error);
        }
    }

    /**
     * Получение инвайте по коду
     * @param code - код инвайта
     */
    static async getInviteByCode(code: String) {
        try {
            return await $api.get(`/invites/${code}`);
        } catch (error: any) {
            throw InviteService.handleError(error);
        }
    }


    /**
     * Вступление в группу по инвайту
     * @param code - код инвайта
     */
    static async joinGroupByInvite(code: String) {
        try {
            return await $api.post(`/invites/${code}`);
        } catch (error: any) {
            throw InviteService.handleError(error);
        }
    }

//     static async createInvite(group: CreateInviteDTO) {
//         const json = JSON.stringify(group);
//         try {
//             return await $api.post<IInvite>('/invites', json);
//         } catch (error: any) {
//             throw InvitesService.handleError(error);
//         }
//     }
//
//     static async fetchInvitesByGroup(groupId: string) {
//         // URL: /invites/group/{groupId}
//         try {
//             return await $api.get<IInvite[]>(`/invites/group/${groupId}`);
//         } catch (error: any) {
//             throw InvitesService.handleError(error);
//         }
//     }
//
//     static async fetchInvitesByUser(userId: string) {
//         // URL: /invites/user/{userId}
//         try {
//             return await $api.get<IInvite[]>(`/invites/user/${userId}`);
//         } catch (error: any) {
//             throw InvitesService.handleError(error);
//         }
//     }
//
//     static async fetchInviteById(id: string) {
//         // URL: /invites/{id}
//         try {
//             return await $api.get<IInvite>(`/invites/${id}`);
//         } catch (error: any) {
//             throw InvitesService.handleError(error);
//         }
//     }
//
//     static async deleteInviteById(id: string) {
//         // URL: /invites/{id}
//         try {
//             return await $api.delete<IInvite>(`/invites/${id}`);
//         } catch (error: any) {
//             throw InvitesService.handleError(error);
//         }
//     }
//
    static handleError(error: any) {
        // console.log(error.response.data.detail);
        // console.log(error.response?.data?.detail);
        // console.log(!!error.response?.data?.detail);
        if (error.response) {
            // Ошибка пришла от сервера (например, неверный пароль)
            return error.response.data;
        } else if (error.request) {
            // Ошибка при выполнении запроса (например, проблемы с сетью)
            return {
                success: false,
                message: 'Ошибка выполнения запроса',
            };
        } else if (error.response?.data?.detail) {
            // Ошибка пришла от сервера (например, неверный пароль)
            return {
                success: false,
                message: error.response.data.detail,
            };
        } else {
            // Непредвиденная о
            return {
                success: false,
                message: 'Непредвиденная ошибка',
            }
        }
    }
}