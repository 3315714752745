import {AxiosError, AxiosResponse} from 'axios';
import $api from "../http";
import {IUser} from "../models/IUser";
import {IChangePassword} from "../models/user/IChangePassword";
import {IUserInTableResponse} from "../models/user/IUserInTableResponse";


export default class UserService {

    static async getCredentials(): Promise<AxiosResponse<IUser>> {
        return $api.post<IUser>('/users/credentials');
    }

    // Смена пароля
    static async changePassword(data: IChangePassword) {
        const json = JSON.stringify(data);
        try {
            await $api.post<IUser>('/users/change-password', json);
        } catch (error: any) {
            throw UserService.handleError(error);
        }
    }

    // Обработчик ошибок
    static handleError(error: AxiosError) {
        if (error.response) {
            // Ошибка пришла от сервера (например, неверный пароль)
            return error.response.data;
        } else if (error.request) {
            // Ошибка при выполнении запроса (например, проблемы с сетью)
            return {
                success: false,
                message: 'Ошибка выполнения запроса',
            };
        } else {
            // Непредвиденная ошибка
            return {
                success: false,
                message: 'Ошибка на стороне сервера',
            };
        }
    }

    // static async register(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    //     const json = JSON.stringify({username: username, password: password});
    //     return $api.post<AuthResponse>('/auth/register', json)
    // }
    //
    // static async authenticate(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    //     const json = JSON.stringify({username: username, password: password});
    //     return $api.post<AuthResponse>('/auth/authenticate', json)
    // }

    /**
     * Получить всех пользователей
     */
    static async getAllUsers() {
        try {
            return await $api.get<IUserInTableResponse[]>('/users');
        } catch (error: any) {
            throw UserService.handleError(error);
        }
    }
}