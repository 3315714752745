import React, {useContext} from 'react'
import {Dialog} from '@headlessui/react'
import st from './Modals.module.css'
import Button from "../buttons/Button";
import PropTypes from "prop-types";
import ModalTemplate from "../bases/ModalTemplate";
// import {useForm} from "react-hook-form";
// import Input from "../inputs/Input";
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Input from "../inputs/Input";


const ConfirmDeleteModal = (props) => {
    const {t} = useTranslation();

    const {store} = useContext(Context);

    const {open, setOpen, group, action} = props

    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        defaultValues: {
            confirm: "",
        }
    });

    async function onSubmit(data) {
        if (data.confirm === group.name) {
            action();
            setOpen(false)
            reset()
        } else {
            store.utils.notify(t('groupPage.deleteGroup.error'), 'error')
        }
    }


    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={st.mainContent}>
                        <div className={st.mainContentContainer}>
                            {/*<div*/}
                            {/*    className={st.iconContainer}>*/}
                            {/*    <ArrowPathRoundedSquareIcon className={st.icon}*/}
                            {/*                             aria-hidden="true"/>*/}
                            {/*</div>*/}
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                <Dialog.Title as="h3"
                                              className={st.header}>
                                    {t('groupPage.deleteGroup.title')}
                                </Dialog.Title>
                                <div className={st.body}>
                                    <p className={'select-none'}>
                                        {t('groupPage.deleteGroup.description')}
                                        <span className={'font-bold'}>{group.name}</span>
                                    </p>
                                </div>
                                <div>
                                    <Input
                                        register={register}
                                        name={"confirm"}
                                        type={"text"}
                                        error={errors.confirm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={st.btnBlock}>

                        <Button
                            type={"submit"}
                            width={'sm-full'}
                            color={"red"}
                        >
                            {t('delete')}
                        </Button>
                        <Button

                            color={"gray"}
                            outline={true}
                            onClick={() => setOpen(false)}
                            width={'sm-full'}
                        >
                            {t('cancel')}
                        </Button>
                    </div>
                </form>
            </Dialog.Panel>
        </ModalTemplate>
    )
}

ConfirmDeleteModal.defaultProps = {
    open: false,
    setOpen: null,
    group: null,
    action: null,
}

ConfirmDeleteModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    group: PropTypes.object,
    action: PropTypes.func,
}

export default ConfirmDeleteModal;