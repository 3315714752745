import React from 'react';
import CardTemplate from "../../../UI/bases/CardTemplate";
import st from "./RecentSessions.module.css"
import StandardTitle from "../../../UI/titles/StandardTitle";
import {useTranslation} from "react-i18next";

const RecentSessions = () => {
    const {t} = useTranslation()
    return (
        <CardTemplate className={st.resentSessions}>
            <StandardTitle title={t('user.security.recentSessions')}/>
            <CardTemplate className={st.placeholder}>
                <div className={st.title}>{t('soon')}</div>
            </CardTemplate>
        </CardTemplate>
    );
};

export default RecentSessions;