import React, {useContext, useState} from 'react';
import st from './RecoveryPassword.module.css'
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import StandardTitle from "../../../../components/UI/titles/StandardTitle";
import Input from "../../../../components/UI/inputs/Input.jsx";
import Button from "../../../../components/UI/buttons/Button";
import {Context} from "../../../../index";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {RecoveryPasswordRequest} from "../../../../models/user/RecoveryPasswordRquest";

const RecoveryPassword = () => {

    const {code} = useParams()
    const navigate = useNavigate();

    const {t} = useTranslation()
    const {store} = useContext(Context)

    const [isButtonActive, setIsButtonActive] = useState(true)

    const [isSuccess, setIsSuccess] = useState(false)

    const {register, handleSubmit, watch, formState: {errors}} = useForm()
    const onSubmit = async (data: any) => {
        setIsButtonActive(false)
        if (await store.user.recoveryPassword({token: code, password: data.password} as RecoveryPasswordRequest)) {
            store.utils.notify('Пароль успешно изменен')
            setIsSuccess(true)
            navigate('/login')
        } else {
            setIsButtonActive(true)
        }
    }

    return (
        <div className={st.BG}>
            <div className={st.content}>
                {
                    !isSuccess ?
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CardTemplate className={st.modal}>
                        <StandardTitle title={t('login.recoverHeader')}/>
                        <div className={st.text}>
                            <p>
                                {t('login.recoverDescription')}
                            </p>
                        </div>

                        <Input
                            title={t('password')}
                            name={"password"}
                            type={"password"}
                            errors={errors}
                            register={register}
                            validationSchema={{
                                required: t('valMessageAuth.password'),
                                minLength: {
                                    value: 8,
                                    message: t('valMessage.minSymbols8')
                                },
                                maxLength: {
                                    value: 100,
                                    message: t('valMessage.maxSymbols')
                                }
                            }}
                        />
                        <Input
                            title={t('confirmPassword')}
                            name={"passwordConfirm"}
                            type={"password"}
                            errors={errors}
                            register={register}
                            validationSchema={{
                                validate: (value: string) => {
                                    return value === watch('password') ? true : t('valMessage.passwordMatch')
                                }
                            }}
                        />

                        <Button
                            type={'submit'}
                            title={t('login.recoverButton')}
                            color={'gray'}
                            size={'base'}
                            outline={false}
                            width={'auto'}
                            className={''}
                            disabled={watch('password') !== watch('passwordConfirm') || !isButtonActive || watch('password')?.length < 8}
                            isIcon={false}/>

                        <div className={st.hiddenText}>
                            <Link to={'/'}>{t('login.goHome')}</Link>
                            <Link to={'/support'}>{t('support')}</Link>
                        </div>
                    </CardTemplate>
                </form> :
                    <CardTemplate className={'flex flex-col gap-2'}>
                        <StandardTitle title={t('login.recoverSuccess')}/>
                        <div className={st.hiddenText}>
                            <Link to={'/'}>{t('login.goHome')}</Link>
                            <Link to={'/support'}>{t('support')}</Link>
                        </div>
                    </CardTemplate>
                }
            </div>
        </div>
    );
};

export default RecoveryPassword;