import React, {useContext, useEffect, useState} from 'react';
import st from "./AssignmentCard.module.css"
import {AssignmentResponse} from "../../../../models/assignment/AssignmentResponse";
import CardTemplate from "../../../UI/bases/CardTemplate";
import {CheckCircleIcon, UserGroupIcon, UserIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import DateTimeService from "../../../../services/DateTimeService";
import cn from "classnames";
import {Context} from "../../../../index";

type Props = {
    assignment: AssignmentResponse;
    update: () => void;
}

const AssignmentCard = ({assignment, update}: Props) => {

    const {store} = useContext(Context);
    const [isDone, setIsDone] = useState(false);
    const [tm, setTm] = useState<any>(null);


    const handleComplete = async () => {
        if (isDone) return;
        setIsDone(true); // Используйте await для уверенности в обновлении значения
        await store.assignment.complete(assignment.id);
    };

    const handleUncomplete = () => {
        if (!isDone) return;
        clearTimeout(tm);
        setIsDone(false); // Используйте await для уверенности в обновлении значения
        store.assignment.uncomplete(assignment.id);
    };

    const handleUncompleteDone = async () => {
        await store.assignment.uncomplete(assignment.id);
        update();
    }

    useEffect(() => {
        const checkIsDone = async () => {
            const timeoutId = setTimeout(() => {
                if (isDone) {
                    update();
                    setIsDone(false);
                }
            }, 2000);

            setTm(timeoutId);
        };
        checkIsDone();
    }, [isDone, update]);

    return (
        <CardTemplate className={st.card}>
            {
                isDone &&
                <div className={st.doneAnimation}>
                    <div className={st.doneAnimationText} onClick={handleUncomplete}>
                        Отмена
                    </div>
                </div>
            }
            {/* Приоритет */}
            <div className={
                cn(
                    st.priority,
                    {
                        [st.priorityHigh]: assignment.priority === 4,
                        [st.priorityMedium]: assignment.priority === 3,
                        [st.priorityLow]: assignment.priority === 2,
                    }
                )
            }>
                {
                    assignment.priority ? Array.from({length: assignment.priority})
                        .map((_, index) =>
                            <span key={`${index}/amount`} style={{
                                opacity: 1 - (index / 4)
                            }}/>) : null
                }
            </div>
            {/* /Приоритет */}

            <div className={cn(st.content,
                {
                    [st.done]: isDone,
                }
            )}>
                <div className={st.badge}>
                    <div className={st.badgeText}>
                        {
                            assignment.completedAt ?
                                <div className={st.completeBadge}
                                     onClick={handleUncompleteDone}>
                                    <CheckCircleIcon className={st.icon}/>
                                    <XCircleIcon className={st.icon}/>
                                </div>
                                :
                                <CheckCircleIcon onClick={handleComplete} className={st.icon}/>
                        }

                    </div>
                </div>
                <div className={st.title}>
                    {assignment.title}
                </div>
            </div>
            <div className={cn(st.footer, {
                [st.done]: isDone,
            })}>
                {
                    assignment.type === 'GROUP' ?
                        <div className={st.groupBadge}>
                            <UserGroupIcon className={st.iconSmall}/>
                            <Link to={`/d/groups/${assignment.group?.alias}`} className={st.groupName}>
                                {assignment.group?.name}
                            </Link>
                        </div>
                        :
                        <div className={st.groupBadge}>
                            <UserIcon className={st.iconSmall}/>
                            <Link to={`/u/${assignment.creator?.username}`} className={st.groupName}>
                                {assignment.creator?.username && assignment.creator?.username.length > 8 ?
                                    assignment.creator?.username.substr(0, 8) + '...' :
                                    assignment.creator?.username}
                            </Link>
                        </div>
                }
                {
                    assignment.deadline &&
                    <div className={st.deadline}>
                        до&nbsp;{DateTimeService.convertDateToShortDate(assignment.deadline)}
                    </div>
                }
                <Link to={`/d/assignments/${assignment.id}`} className={st.link}>
                    Подробнее
                </Link>
            </div>
        </CardTemplate>
    );
};

export default AssignmentCard;