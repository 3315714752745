import React, {useRef} from 'react'
import {Dialog} from '@headlessui/react'
import st from './Modals.module.css'
import Button from "../buttons/Button";
import PropTypes from "prop-types";
import {
    ArrowsUpDownIcon,
} from "@heroicons/react/24/solid";
import ModalTemplate from "../bases/ModalTemplate";
import {useTranslation} from "react-i18next";


const SwapInQueueModal = (props) => {
    const {t} = useTranslation();

    const {open, setOpen, action, selfUser, otherUser} = props

    const cancelButtonRef = useRef(null)

    let toUp = !!(selfUser && otherUser && selfUser.placeInQueue < otherUser.placeInQueue);


    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <div className={st.mainContent}>
                    <div className={st.mainContentContainer}>
                        {/*<div*/}
                        {/*    className={st.iconContainer}>*/}
                        {/*    <ArrowPathRoundedSquareIcon className={st.icon}*/}
                        {/*                             aria-hidden="true"/>*/}
                        {/*</div>*/}
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3"
                                          className={st.header}>
                                {t('queueViewPage.swapOffer.offerTitle')}
                            </Dialog.Title>
                            <div className={st.body}>
                                <p>
                                    {t('queueViewPage.swapOffer.offerDescription')}
                                </p>
                                {
                                    selfUser && otherUser &&
                                    <div className=" mt-2">
                                        <div className={st.swapUsers}>
                                            <div className={st.swapFirstUser}>
                                                <div className={st.swapNumber}>
                                                    {toUp ? selfUser.placeInQueue + 1 : otherUser.placeInQueue + 1}
                                                </div>
                                                <div className={st.swapUserName}>
                                                    {toUp ? selfUser.lastName + " " + selfUser.firstName + " (Я)" : otherUser.lastName + " " + otherUser.firstName}
                                                </div>
                                            </div>
                                            <ArrowsUpDownIcon className={st.swapIcon}/>
                                            <div className={st.swapSecondUser}>
                                                <div className={st.swapNumber}>
                                                    {!toUp ? selfUser.placeInQueue + 1 : otherUser.placeInQueue + 1}
                                                </div>
                                                <div className={st.swapUserName}>
                                                    {!toUp ? selfUser.lastName + " " + selfUser.firstName + " (Я)" : otherUser.lastName + " " + otherUser.firstName}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={st.btnBlock}>
                    <Button
                        color={toUp ? "red" : "green"}
                        onClick={action}
                        width={"sm-full"}
                    >
                        {t('offer')}
                    </Button>
                    <Button
                        color={"gray"}
                        width={"sm-full"}
                        outline={true}
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                    >
                        {t('cancel')}
                    </Button>
                </div>
            </Dialog.Panel>
        </ModalTemplate>
    )
}

SwapInQueueModal.defaultProps = {
    open: false, setOpen: null, action: null, selfUser: null, otherUser: null,
}

SwapInQueueModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    action: PropTypes.func,
    selfUser: PropTypes.object,
    otherUser: PropTypes.object,
}

export default SwapInQueueModal;