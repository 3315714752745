import {IGroup} from "../models/IGroup";
import {CreateGroupDTO} from "../models/group/CreateGroupDTO";
import $api from "../http";

export default class GroupService {

    /**
     * Создание группы
     */
    static async createGroupOwner(group: CreateGroupDTO) {
        const json = JSON.stringify(group);
        try {
            return await $api.post<IGroup>('/groups/owner', json);
        } catch (error: any) {
            throw GroupService.handleError(error);
        }
    }

    /**
     * Получение группы по alias
     */
    static async fetchGroupByAlias(alias: string) {
        // URL: /groups/alias/{alias}
        try {
            return await $api.get<IGroup>(`/groups/alias/${alias}`);
        } catch (error: any) {
            throw GroupService.handleError(error);
        }
    }

    /**
     * Получение группы по id
     */
    static async fetchGroupById(id: string) {
        // URL: /groups/{id}
        try {
            return await $api.get<IGroup>(`/groups/${id}`);
        } catch (error: any) {
            throw GroupService.handleError(error);
        }
    }


    /**
     * Обработчик ошибок
     */
    static handleError(error: any) {
        console.log(error.response?.data?.detail)
        console.log(!!error.response?.data?.detail)
        if (error.response) {
            // Ошибка пришла от сервера (например, неверный пароль)
            return error.response.data;
        } else if (error.request) {
            // Ошибка при выполнении запроса (например, проблемы с сетью)
            return {
                success: false,
                message: 'Ошибка выполнения запроса',
            };
        } else if (error.response?.data?.detail) {
            // Ошибка пришла от сервера (например, неверный пароль)
            return {
                success: false,
                message: error.response.data.detail,
            }
        } else {
            // Непредвиденная ошибка
            return {
                success: false,
                message: 'Ошибка на стороне сервера',
            };
        }
    }

}