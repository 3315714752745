import React from 'react';
import PublicHome from "../../../components/templates/PublicHome/PublicHome";
import Informatics9 from "../../../components/services/Informatics9/Informatics9";
import classes from "./Informatics9Page.module.css";

const Informatics9Page = () => {
    return (
        <PublicHome>
            <div className={classes.pageContainer}>
                <Informatics9/>
            </div>
        </PublicHome>
    );
};

export default Informatics9Page;