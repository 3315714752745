import Store from "../store";
import {makeAutoObservable} from "mobx";
import $api from "../../http";
import {RecoveryPasswordRequest} from "../../models/user/RecoveryPasswordRquest";
import {UsersStats} from "../../models/user/UsersStats";
import {IUserAdminProfileResponse} from "../../models/user/IUserAdminProfileResponse";
import {UserEditCredentialsRequest} from "../../models/user/UserEditCredentialsRequest";
import {UserEditStatusRequest} from "../../models/user/UserEditStatusRequest";
import {UserEditDataRequest} from "../../models/user/UserEditDataRequest";

export default class UserStore {
    private rootStore: Store;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async getProfileInfo(username: string) {
        try {
            const response = await $api.get<any>(`/users/${username}/profile-info`);
            return response.data;
        } catch (e) {
            // this.rootStore.errorHandler(e);
        }
    }

    async confirmEmail(value: string) {
        try {
            const response = await $api.post<any>(`/users/confirm-email/${value}`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async resendConfirmEmail() {
        try {
            const response = await $api.post<boolean>(`/users/resend-confirm-email`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async sendResetPasswordEmail(value: string) {
        try {
            const response = await $api.post<boolean>(`/users/reset-password/${value}`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async recoveryPassword(data: RecoveryPasswordRequest) {
        try {
            const json = JSON.stringify(data);
            const response = await $api.post<boolean>(`/users/recovery-password`, json);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async getUsersStats() {
        try {
            const response = await $api.get<UsersStats>(`/users/statistics`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async getUserById(userId: string) {
        try {
            const response = await $api.get<IUserAdminProfileResponse>(`/users/${userId}`)
            return response.data
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async editUserCredentials(id: string, userEditCredentialsRequest: UserEditCredentialsRequest) {
        try {
            const json = JSON.stringify(userEditCredentialsRequest)
            await $api.put<void>(`/users/${id}/names`, json)
            return true
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
        return false
    }


    async editUserStatus(id: string, userEditStatusRequest: UserEditStatusRequest) {
        try {
            const json = JSON.stringify(userEditStatusRequest)
            await $api.put<void>(`/users/${id}/status`, json)
            return true
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async editUserContacts(id: string, userEditContactsRequest: UserEditDataRequest) {
        try {
            const json = JSON.stringify(userEditContactsRequest)
            await $api.put<void>(`/users/${id}/data`, json)
            return true
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
        return false
    }
}