import {Fragment, useContext} from 'react'
import {Menu, Transition} from '@headlessui/react'
import React from 'react'
import cn from "classnames";
import {Context} from "../../../../index";
import Base from "../../bases/Base";
import st from "./UserDropdown.module.css"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

type Props = {
    action: any
}

export default function UserDropdown({action}: Props) {

    const {store} = useContext(Context);
    const {t} = useTranslation()
    const logout = () => {
        action(true)
    }

    return (
        <Menu as="div" className="flex relative text-left">
            <div>
                <Menu.Button>
                    <Base className={st.headerUserCard}>
                        <img src={store.currentUser.avatar ?? ''} alt="avatar"/>
                        <div className={st.userData}>
                            <div className={st.name}>{store.currentUser.lastName} {store?.currentUser?.firstName?.substr(0, 1)}.</div>
                            <div className={st.username}> @{store.currentUser.username}</div>
                        </div>
                    </Base>

                    {/*<ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute pt-0.5 min-w-full origin-top-right divide-y divide-white ring-0 ">
                    <div className={st.menuBlock}>
                        <div>
                            <Menu.Item>
                                <button className={cn(st.menuItem, st.logout)} onClick={logout}>
                                    <div>
                                        {t('user.menu.logout')}
                                    </div>
                                </button>
                            </Menu.Item>
                        </div>

                        <Menu.Item>
                            <Link className={"text-center w-full h-full block"} to={`/u/${store.currentUser.username}`}>
                                <button className={st.menuItem}>
                                    <div>
                                        {t('user.menu.profile')}
                                    </div>
                                </button>
                            </Link>
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
