import React, {useCallback, useContext, useEffect, useState} from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import {useTranslation} from "react-i18next";
import {Context} from "../../../../index";
import st from './ViewAssignment.module.css'
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AssignmentResponse} from "../../../../models/assignment/AssignmentResponse";
import Button from "../../../../components/UI/buttons/Button";
import StandardTitle from "../../../../components/UI/titles/StandardTitle";
import LightBadge from "../../../../components/UI/badge/LightBadge/LightBadge";
import Chip from "../../../../components/UI/badge/Chip/Chip";
import cn from "classnames";

// @ts-ignore
import {ContentState, convertFromHTML, EditorState} from "draft-js";
// @ts-ignore
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import DateTimeService from "../../../../services/DateTimeService";
import ConfirmModal from "../../../../components/UI/modals/ConfirmModal.jsx";

const ViewAssignment = () => {
    const {assignmentId} = useParams()
    const {store} = useContext(Context);
    const {t} = useTranslation()
    const navigate = useNavigate()

    const [assignment, setAssignment] = useState<AssignmentResponse>();
    const [deleteModal, setDeleteModal] = useState(false);

    const updateData = useCallback(async () => {
        // @ts-ignore
        const updatedAssignment = await store.assignment.getById(assignmentId)
        setAssignment(updatedAssignment)
    }, [
        assignmentId,
        store.assignment
    ]);

    const completeAssignment = async () => {
        // @ts-ignore
        await store.assignment.complete(assignmentId)
        updateData()
    }

    const uncompleteAssignment = async () => {
        // @ts-ignore
        await store.assignment.uncomplete(assignmentId)
        updateData()
    }

    const handleDelete = async () => {
        if (assignment?.type === 'GROUP') {
            await store.assignment.deleteGroup(assignmentId, assignment?.group?.id)
        } else {
            await store.assignment.deletePersonal(assignmentId)
        }
        navigate('/dashboard')
    }


    useEffect(() => {
        updateData();
    }, [
        assignmentId,
        store,
        setAssignment,
        updateData
    ]);

    return (
        <Dashboard title={t('assignments.manage.view')}>
            <ConfirmModal
                open={deleteModal}
                setOpen={setDeleteModal}
                header={t('assignments.manage.deleteHeader')}
                body={t('assignments.manage.deleteBody')}
                confirmText={t('delete')}
                action={handleDelete}
            />
            <div className={st.content}>
                <CardTemplate className={cn(st.mainInfo, st.parameters)}>
                    <StandardTitle title={t('assignments.info.title')}/>
                    <LightBadge title={t('title')}
                                value={assignment?.title}/>
                    <div className={'-mt-3 border-t border-gray-600'}>
                        {
                            assignment?.content &&
                            <Editor
                                readOnly
                                toolbarHidden
                                editorState={EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                        convertFromHTML(assignment?.content)
                                    )
                                )}
                            />
                        }
                    </div>
                </CardTemplate>
                <div className={st.extraInfo}>
                    <CardTemplate className={st.parameters}>
                        <StandardTitle title={t('assignments.info.extraTitle')}/>
                        {
                            assignment?.type === 'GROUP' ?
                                <div className={st.groupTypeInfo}>
                                    <LightBadge title={t('assignments.info.type')}
                                                value={t('assignments.info.groupType')}/>
                                    <LightBadge title={t('assignments.info.group')}
                                                value={
                                                    <Chip
                                                        title={assignment?.group?.name}
                                                        link={`/d/groups/${assignment?.group?.alias}`}
                                                        color={"gray"}
                                                    />
                                                }/>
                                </div>
                                :
                                <LightBadge title={t('assignments.info.type')}
                                            value={t('assignments.info.personalType')}/>
                        }
                        <div className={st.extraInfoBlock}>
                            {
                                assignment?.creator &&
                                <LightBadge title={t('assignments.info.creator')}
                                            value={
                                                <Chip
                                                    title={assignment?.creator?.username}
                                                    link={`/u/${assignment?.creator?.username}`}
                                                    color={"gray"}
                                                />
                                            }/>
                            }
                            {
                                assignment?.createdAt &&
                                <LightBadge title={t('assignments.info.createdAt')}
                                            value={DateTimeService.convertBackDateToString(assignment?.createdAt)}/>
                            }
                            {
                                assignment?.deadline ?
                                    <LightBadge title={t('assignments.info.deadline')}
                                                value={DateTimeService.convertDateToShortDate(assignment?.deadline)}/>
                                    :
                                    <LightBadge title={t('assignments.info.deadline')}
                                                value={t('assignments.info.noDeadline')}/>
                            }
                            <div className={st.hr}/>
                            {
                                assignment?.priority &&
                                <LightBadge title={t('assignments.info.priority')}
                                            value={t(`priority.${assignment?.priority}`)}/>
                            }
                            {
                                assignment?.completedAt ?
                                    <LightBadge title={t('assignments.info.completedAt')}
                                                value={DateTimeService.convertBackDateToString(assignment?.completedAt)}/>
                                    :
                                    <LightBadge title={t('assignments.info.completedAt')}
                                                value={t('assignments.info.notCompleted')}/>
                            }

                        </div>
                    </CardTemplate>
                    {/* Действия */}
                    <CardTemplate className={st.parameters}>
                        <StandardTitle title={t('assignments.info.actions')}/>
                        <div className={st.actionBtns}>
                            {
                                assignment?.completedAt ?
                                    <Button
                                        onClick={uncompleteAssignment}
                                        color={'red'}
                                        size={'base'}
                                        outline={false}
                                        title={t('assignments.manage.uncomplete')}
                                        width={'full'}
                                        className={''}
                                        isIcon={false}/>
                                    :
                                    <Button
                                        onClick={completeAssignment}
                                        color={'green'}
                                        size={'base'}
                                        outline={false}
                                        title={t('assignments.manage.complete')}
                                        width={'full'}
                                        className={''}
                                        isIcon={false}/>
                            }
                            {
                                (
                                    (
                                        assignment?.type === 'GROUP' &&
                                        store.havePermission(
                                            'GROUP_ASSIGNMENT_MANAGEMENT',
                                            true,
                                            assignment?.group.alias)
                                    ) || (assignment?.creator.id === store.currentUser.id)
                                ) && <>
                                    <Link to={`/d/assignments/${assignment?.id}/update`}>
                                        <Button color={'blue'}
                                                size={'base'}
                                                outline={false}
                                                title={t('assignments.manage.edit')}
                                                width={'full'}
                                                className={''}
                                                isIcon={false}/>
                                    </Link>
                                    <div>
                                        <Button
                                            onClick={() => setDeleteModal(true)}
                                            color={'red'}
                                            size={'base'}
                                            outline={false}
                                            title={t('assignments.manage.delete')}
                                            width={'full'}
                                            className={''}
                                            isIcon={false}/>
                                    </div>
                                </>
                            }

                        </div>
                    </CardTemplate>
                </div>
            </div>
        </Dashboard>
    );
};

export default ViewAssignment;