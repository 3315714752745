import React from 'react';
import classes from './OfficeBlock.module.css';
import Base from "../../../UI/bases/Base";

type Props = {
    title: string;
    children: React.ReactNode;
}

const OfficeBlock = ({children, title}: Props) => {
    return (
        <Base className={classes.pageContainer}>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.content}>
                {children}
            </div>
        </Base>
    );
};

export default OfficeBlock;