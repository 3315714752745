import Store from "../store";
import {makeAutoObservable} from "mobx";
import $api from "../../http";
import {CreateQueueDTO} from "../../models/queue/CreateQueueDTO";

export default class QueueStore {
    private rootStore: Store;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }


    async closeQueue(groupId: string, queueId: string) {
        try {
            await $api.patch(`/groups/${groupId}/queues/${queueId}/close`);
            this.rootStore.utils.notify('Очередь закрыта!', 'success');
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async archiveQueue(groupId: string, queueId: string) {
        try {
            await $api.patch(`/groups/${groupId}/queues/${queueId}/archive`);
            this.rootStore.utils.notify('Очередь архивирована!', 'success');
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async editQueue(groupId: string, queueId: string, data: CreateQueueDTO) {
        try {
            const json = JSON.stringify(data);
            await $api.put(`/groups/${groupId}/queues/${queueId}`, json);
            this.rootStore.utils.notify('Очередь изменена!', 'success');
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async getProposalForWidget(groupId: string) {
        try {
            const response = await $api.get(`/groups/${groupId}/queues/switch/widget`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }
}