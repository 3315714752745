import {CreateQueueDTO} from "../models/queue/CreateQueueDTO";
import $api from "../http";
import {AxiosError} from "axios";

export default class QueueService {
    /**
     * Создание очереди
     * @param groupId - id группы
     * @param queueDTO - данные очереди
     */
    static async createQueue(groupId: String, queueDTO: CreateQueueDTO) {
        const json = JSON.stringify(queueDTO);
        try {
            return await $api.post(`/groups/${groupId}/queues`, json);
        } catch (error: any) {
            throw QueueService.handleError(error);
        }
    }

    /**
     * Получение очередей группы
     * @param groupId - id группы
     */
    static async getQueues(groupId: String) {
        try {
            return await $api.get(`/groups/${groupId}/queues`);
        } catch (error: any) {
            throw QueueService.handleError(error);
        }
    }

    /**
     * Получение очереди по id
     * @param groupId
     * @param queueId - id очереди
     */
    static async getQueueById(groupId: String, queueId: String) {
        try {
            return await $api.get(`/groups/${groupId}/queues/${queueId}`);
        } catch (error: any) {
            throw QueueService.handleError(error);
        }
    }

    /**
     * Получние очереди по элиасу и id группы
     * @param groupId - id группы
     * @param alias - элиас очереди
     */
    static async getQueueByAlias(groupId: String, alias: String) {
        try {
            return await $api.get(`/groups/${groupId}/queues/alias/${alias}`);
        } catch (error: any) {
            throw QueueService.handleError(error);
        }
    }

    /**
     * Выход из очереди по id очереди и элиасу группы
     *
     * @param groupId - id группы
     * @param queueId - id очереди
     */
    static async leaveQueueById(groupId: String, queueId: String) {
        try {
            return await $api.delete(`/groups/${groupId}/queues/${queueId}`);
        } catch (error: any) {
            throw QueueService.handleError(error);
        }
    }

    /**
     * Перемешивание очереди
     * @param groupId
     * @param queueId
     */
    static async shuffleQueueById(groupId: String, queueId: string) {
        try {
            return await $api.patch(`/groups/${groupId}/queues/${queueId}/shuffle`);
        } catch (error: any) {
            throw QueueService.handleError(error);
        }
    }

    /**
     * Вступление в очередт по id очереди
     * @param groupId
     * @param queueId - id очереди
     */
    static async joinQueueById(groupId: String, queueId: String) {
        try {
            return await $api.patch(`/groups/${groupId}/queues/${queueId}`);
        } catch (error: any) {
            throw QueueService.handleError(error);
        }
    }

    /**
     * Обработка ошибок
     *
     */
    static handleError(error: AxiosError) {
        if (error.response) {
            // Ошибка пришла от сервера (например, неверный пароль)
            return error.response.data;
        } else if (error.request) {
            // Ошибка при выполнении запроса (например, проблемы с сетью)
            return {
                success: false,
                message: 'Ошибка выполнения запроса',
            };
        } else {
            // Непредвиденная ошибка
            return {
                success: false,
                message: 'Ошибка на стороне сервера',
            };
        }
    }


}