export default class DateTimeService {
    public static convertBackDateToInput(unixTimestamp: number): string {
        const milliseconds = unixTimestamp * 1000;
        const date = new Date(milliseconds);
        return date.toISOString().slice(0, 16);
    }

    public static convertBackDateToDate(unixTimestamp: number): Date {
        const milliseconds = unixTimestamp * 1000;
        return new Date(milliseconds);
    }

    public static convertBackDateToString(unixTimestamp: number): string {
        const milliseconds = unixTimestamp * 1000;
        const localDate = new Date(milliseconds);

        const options = {

            hour: 'numeric',
            minute: 'numeric',
            day: '2-digit',
            month: '2-digit',
            weekday: 'short',
        };

        // @ts-ignore
        const formattedDate = localDate.toLocaleString('ru-RU', options);
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }

    // [year, month, day] -> unix timestamp
    public static convertDateToUnixTimestamp(date: number[]): number {
        const [year, month, day] = date;
        const dateObject = new Date(year, month - 1, day);
        return Math.floor(dateObject.getTime() / 1000);
    }

    // unix timestamp -> [year, month, day]
    public static convertUnixTimestampToDate(unixTimestamp: number): number[] {
        const date = new Date(unixTimestamp * 1000);
        return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
    }

    // unix timestamp -> 11.05.2021
    public static convertUnixTimestampToShortDate(unixTimestamp: number): string {
        const date = new Date(unixTimestamp * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    // [year, month, day] -> 11.05.2021
    public static convertDateToShortDate(date: number[]): string {
        const [year, month, day] = date;
        return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
    }

    // [year, month, day] -> 2021-05-11
    public static convertDateToInput(date: number[]): string {
        const [year, month, day] = date;
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }
}
