import React from 'react';
import st from "./SelectGroupCard.module.css";
import CardTemplate from "../../../UI/bases/CardTemplate";
import Button from "../../../UI/buttons/Button";
import LightBadge from "../../../UI/badge/LightBadge/LightBadge";
import {useTranslation} from "react-i18next";

const SelectGroupCard = ({group, action}) => {
    const {t} = useTranslation();
    return (
        <CardTemplate className={st.cardContainer}>
            <div className={st.title}>{t('groupInfo')}</div>
            <div className={st.info}>
                <div className={st.groupContainer}>
                    <LightBadge
                        title={t('group')}
                        value={group?.name}
                    />
                    <LightBadge title={t('code')} value={group?.alias}/>
                </div>
                {
                    group?.description &&
                    <LightBadge
                        title={t('description')}
                        value={group?.description}
                    />
                }
            </div>
            <Button onClick={() => action(group.id)} title={t('choose')} color={'blue'} className={'mt-4'}/>
        </CardTemplate>
    );
};

export default SelectGroupCard;