import React, {useContext, useState} from 'react';
import st from './UserStatusInfo.module.css'
import CardTemplate from "../../../UI/bases/CardTemplate";
import {IUserAdminProfileResponse} from "../../../../models/user/IUserAdminProfileResponse";
import StandardTitle from "../../../UI/titles/StandardTitle";
import StatusBadge from "../../../UI/badge/StatusBadge/StatusBadge";
import cn from "classnames";
import {CheckCircleIcon, PencilSquareIcon, XCircleIcon} from "@heroicons/react/24/outline";
import ConfirmModal from "../../../UI/modals/ConfirmModal.jsx";
import {Context} from "../../../../index";
import {UserEditStatusRequest} from "../../../../models/user/UserEditStatusRequest";
import Button from "../../../UI/buttons/Button";

type UserStatusInfoProps = {
    user: IUserAdminProfileResponse;
    reload: () => void;
}

const UserStatusInfo = ({user, reload}: UserStatusInfoProps) => {

    const {store} = useContext(Context)

    const [editMode, setEditMode] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [userEditStatusRequest, setUserEditCredentialsRequest] = useState<UserEditStatusRequest>({} as UserEditStatusRequest)

    const onSubmit = async () => {
        setConfirmModal(true)
    }

    const confirmEdit = async () => {
        setConfirmModal(false)
        if (await store.user.editUserStatus(user.id, userEditStatusRequest)) {
            store.utils.notify('Данные успешно изменены', 'success')
            setEditMode(false)
            reload()
        } else {
            store.utils.notify('Ошибка изменения данных', 'error')
        }
    }

    return (
        <CardTemplate className={st.content}>
            <div className={st.title}>
                <StandardTitle title={'Статус'}/>
                {
                    editMode ?
                        <div className={st.actions}>
                            <button type={'submit'} className={cn(st.icon, st.iconOk)}>
                                <CheckCircleIcon
                                    onClick={onSubmit}
                                    className={cn(st.icon, st.iconOk)}/>
                            </button>
                            <XCircleIcon onClick={() => setEditMode(!editMode)} className={st.icon}/>
                        </div>
                        :
                        <PencilSquareIcon onClick={() => setEditMode(!editMode)} className={st.icon}/>
                }
            </div>
            <div
                className={st.attrList}>
                <div className={st.currentStatus}>
                    <span>Текущий статус: </span>
                    <StatusBadge status={user.status}/>
                </div>
                {
                    editMode ?
                        <div className={st.editButtons}>
                            <Button
                                type={'button'}
                                title={'Ожидает'}
                                disabled={user.status === 'WAITING'}
                                onClick={() => setUserEditCredentialsRequest({status: 'WAITING'})}
                                color={'orange'}
                                size={'base'}
                                className={''}
                                outline={userEditStatusRequest.status !== 'WAITING'}
                                width={'auto'}
                                isIcon={false}
                            />
                            <Button
                                type={'button'}
                                title={'Активен'}
                                disabled={user.status === 'ACTIVE'}
                                onClick={() => setUserEditCredentialsRequest({status: 'ACTIVE'})}
                                className={''}
                                color={'green'}
                                size={'base'}
                                outline={userEditStatusRequest.status !== 'ACTIVE'}
                                width={'auto'}
                                isIcon={false}
                            />
                            <Button
                                type={'button'}
                                title={'Забанен'}
                                disabled={user.status === 'BANNED'}
                                onClick={() => setUserEditCredentialsRequest({status: 'BANNED'})}
                                className={''}
                                color={'gray'}
                                size={'base'}
                                outline={userEditStatusRequest.status !== 'BANNED'}
                                width={'auto'}
                                isIcon={false}
                            />
                            <Button
                                type={'button'}
                                title={'Удален'}
                                disabled={user.status === 'DELETED'}
                                onClick={() => setUserEditCredentialsRequest({status: 'DELETED'})}
                                className={''}
                                color={'red'}
                                size={'base'}
                                outline={userEditStatusRequest.status !== 'DELETED'}
                                width={'auto'}
                                isIcon={false}
                            />
                        </div>
                        :
                        <>
                        </>
                }
            </div>

            <ConfirmModal
                open={confirmModal}
                setOpen={setConfirmModal}
                header={'Изменение статуса'}
                body={'Вы действительно хотите изменить статус пользователя?'}
                confirmText={'Изменить'}
                action={confirmEdit}
            />
        </CardTemplate>
    );
};

export default UserStatusInfo;