import React from 'react';
import st from "./StaticProfileInfo.module.css"
import CardTemplate from "../../../UI/bases/CardTemplate";
import Label from "../../../UI/label/Label/Label";
import {CheckCircleIcon, ClockIcon, EnvelopeIcon, UserIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import Chip from "../../../UI/badge/Chip/Chip";
import {IUserProfileInfoResponse} from "../../../../models/user/IUserProfileInfoResponse";
import UtilsService from "../../../../services/UtilsService";
import StatusBadge from "../../../UI/badge/StatusBadge/StatusBadge";
import {StarIcon} from "@heroicons/react/20/solid";

interface StaticProfileInfoProps {
    user: IUserProfileInfoResponse
}

const StaticProfileInfo = ({user}: StaticProfileInfoProps) => {
    const {t} = useTranslation();
    return (
        <CardTemplate className={st.card}>
            <div className={st.title}>
                <h2>{t('user.data.about')}</h2>
            </div>
            <div className={st.attrList}>
                <Label svg={<UserIcon/>} title={t('lastName')} value={user.lastName}/>
                <Label svg={null} title={t('firstName')} value={user.firstName}/>
                <Label svg={null} title={t('patronymic')} value={user.patronymic}/>
                {
                    user.status === 'ACTIVE' &&
                    <Label svg={<CheckCircleIcon/>} title={t('status')}
                           value={<StatusBadge status={user.status}/>}/>
                }
                {
                    user.status === 'WAITING' &&
                    <Label svg={<ClockIcon/>} title={t('status')}
                           value={<StatusBadge status={user.status}/>}/>
                }
                {
                    (user.status === "BANNED" || user.status === "DELETED") &&
                    <Label svg={<XCircleIcon/>} title={t('status')}
                           value={<StatusBadge status={user.status}/>}/>
                }
            </div>
            <div className={st.title}>
                <h2>{t('user.data.contacts')}</h2>
            </div>
            <div className={st.attrList}>
                {
                    user.contacts?.email &&
                    <Label svg={<EnvelopeIcon/>} title={t('user.data.email')}
                           value={<Chip title={UtilsService.hideEmail(user.contacts.email)}/>}/>
                }
                {
                    user.contacts?.telegram &&
                    <Label svg={null} title={t('Telegram')}
                           value={<Chip title={'@MinusD'} link={`https://${user.contacts.telegram}.t.me`}/>}/>
                }
                {
                    user.contacts?.vk &&
                    <Label svg={null} title={t('Vk')}
                           value={<Chip title={'@MinusD'} link={`https://vk.com/${user.contacts.vk}`}/>}/>
                }
            </div>
            <div className={st.title}>
                <h2>{t('user.data.extra')}</h2>
            </div>
            <div className={st.attrList}>
                {
                    user?.luckLevel && user?.luckLevel < 30 ?
                    <Label svg={<StarIcon/>} title={t('user.data.luckLevel')}
                           value={<Chip color={"gray"} title={user?.luckLevel}/>}/>
                        : null
                }
                {
                    user?.luckLevel && user?.luckLevel >= 30 && user?.luckLevel < 60 ?
                        <Label svg={<StarIcon/>} title={t('user.data.luckLevel')}
                               value={<Chip color={"green"} title={user?.luckLevel}/>}/>
                        : null

                }
                {
                    user?.luckLevel && user?.luckLevel >= 60 && user?.luckLevel < 90 ?
                        <Label svg={<StarIcon/>} title={t('user.data.luckLevel')}
                               value={<Chip color={"orange"} title={user?.luckLevel}/>}/>
                        : null
                }
                {
                    user?.luckLevel && user?.luckLevel >= 90 ?
                        <Label svg={<StarIcon/>} title={t('user.data.luckLevel')}
                               value={<Chip color={"red"} title={user?.luckLevel}/>}/>
                        : null
                }
            </div>
        </CardTemplate>
    );
};

export default StaticProfileInfo;