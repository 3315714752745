import React from 'react';
import classes from './ServiceCard.module.css';
import {Link} from "react-router-dom";
import CardTemplate from "../../UI/bases/CardTemplate";

const ServiceCard = ({service}) => {
    return (
        <div className={classes.cardContainer}>
            <CardTemplate hovered={true}>
                <Link to={service.link} className={classes.card}>
                    <div className={classes.title}>
                        <h3>{service.name}</h3>
                    </div>
                    <div className={classes.content}>
                        <p>{service.description}</p>
                    </div>
                </Link>
            </CardTemplate>
        </div>
    );
};

export default ServiceCard;