import React, {useContext, useRef} from 'react'
import {Dialog} from '@headlessui/react'
import st from './Modals.module.css'
import Button from "../buttons/Button";
import PropTypes from "prop-types";
import ModalTemplate from "../bases/ModalTemplate";
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";

const ChangeLanguageModal = (props) => {
    const {t} = useTranslation()
    const {store} = useContext(Context);

    const {open, setOpen} = props

    const cancelButtonRef = useRef(null)

    const langs = [
        {
            name: 'Русский',
            code: 'ru',
            flag: 'ru'
        },
        {
            name: 'English',
            code: 'en',
            flag: 'us'
        },
        {
            name: 'Français',
            code: 'fr',
            flag: 'fr'
        },
        {
            name: '中文',
            code: 'zh',
            flag: 'cn'
        },
        {
            name: ':3',
            code: 'ct',
            flag: 'tn'
        }
    ]

    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <div className={st.mainContent}>
                    <div className={st.mainContentContainer}>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                            <Dialog.Title as="h3"
                                          className={st.header}>
                                {t('changeLanguage')}
                            </Dialog.Title>
                            <div className={st.body}>
                                <div className={st.langContainer}>
                                    {
                                        langs.map((lang, index) =>
                                            <Button
                                                color={store.langKey === lang.code ? 'blue' : 'gray'}
                                                key={index}
                                                onClick={() => {
                                                    store.setLangKey(lang.code)
                                                }}
                                                className={'cursor-pointer'}
                                                hovered={true}
                                                width={'full'}
                                            >
                                                <div className={st.langBtn}>
                                                    <img
                                                        src={`https://flagcdn.com/${lang.flag}.svg`}
                                                        width="30"
                                                        alt="flag"/>
                                                    <div>{lang.name}</div>
                                                </div>
                                            </Button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={st.btnBlock}>
                    <Button
                        color={"gray"}
                        outline={true}
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                        width={'sm-full'}
                    >
                        {t('cancel')}
                    </Button>
                </div>
            </Dialog.Panel>
        </ModalTemplate>
    )
}

ChangeLanguageModal.defaultProps = {
    open: false,
    setOpen: null,
}

ChangeLanguageModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default ChangeLanguageModal;