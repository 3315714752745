import React from 'react';
import Dashboard from "../../../components/templates/Dashboard/Dashboard";
import {useTranslation} from "react-i18next";
import AdminUsersTable from "../../../components/UI/table/AdminUsersTable";
import UsersTableStats from "../../../components/admin/users/UsersTableStats/UsersTableStats";

const UsersTable = () => {


    const {t} = useTranslation();
    // const {store}: any = useContext(Context);

    return (
        <Dashboard title={t('admin.users.title')}>
            <UsersTableStats/>
            <AdminUsersTable/>
        </Dashboard>
    );
};

export default UsersTable;