import React, {useContext, useEffect, useState} from 'react';
import CardTemplate from "../bases/CardTemplate";
import {Context} from "../../../index";
import {useTable} from "react-table";
import st from "./Tables.module.css"
import cn from "classnames";
import {Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import Chip from "../badge/Chip/Chip";
import DateTimeService from "../../../services/DateTimeService";
import {IUserInTableResponse} from "../../../models/user/IUserInTableResponse";
import StatusBadge from "../badge/StatusBadge/StatusBadge";
import {IdentificationIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";

const AdminUsersTable = () => {

    const {store} = useContext(Context);
    const {t} = useTranslation();
    const [users, setUsers] = useState<IUserInTableResponse[]>([]);


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await store.getAllUsers();
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, [store]);

    // use CALLBACK
    // const handleAdministrateModal = useCallback(
    //     (userId: string) => {
    //         setUser(users.find(u => u.id === userId) as IUserInTableResponse)
    //         setAdministrateModal(true)
    //         console.log('s')
    //     }, [users, setAdministrateModal, setUser])

    //     (userId: string) => {
    //     setUser(users.find(u => u.id === userId) as IUserInTableResponse)
    //     setAdministrateModal(true)
    // }


    const columns = React.useMemo(
        () => [
            {
                id: 'id',
                Header: <div className={st.id}>ID</div>,
                accessor: 'id' as const,
                Cell: ({value}: any) => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <code className={st.id}>{value.slice(-5)}</code>
                        </Tooltip>
                    )
                }
            },
            {
                Header: t('admin.users.data.avatar'),
                accessor: 'avatar' as const,
                Cell: ({value}: any) => {
                    return (
                        <img className={st.avatar} src={value} alt="avatar"/>
                    )
                }
            },
            {
                Header: t('username'),
                accessor: 'username' as const,
                Cell: ({value}: any) => {
                    return (
                        <Chip title={value}
                              color={'blue'}
                              link={`/u/${value}`}/>
                    )
                }
            },
            {
                Header: t('lastName'),
                accessor: 'lastName' as const,
            },
            {
                Header: t('firstName'),
                accessor: 'firstName' as const,
            },
            {
                Header: t('patronymic'),
                accessor: 'patronymic' as const,
            },
            {
                Header: t('user.data.email'),
                accessor: 'email' as const,
                Cell: ({value}: any) => {
                    return (
                        <Chip title={value} color={'blue'} link={`mailto:${value}`}/>
                    )
                }
            },
            {
                id: 'status',
                Header: t('user.data.status'),
                accessor: 'status' as const,
                Cell: ({value}: any) => {
                    return (
                        <>
                            <StatusBadge status={value}/>
                        </>
                    )
                }
            },
            {
                Header: t('user.data.createdAt'),
                accessor: 'createdAt' as const,
                Cell: ({value}: any) => {
                    return (
                        <>
                            {DateTimeService.convertBackDateToString(value)}
                        </>
                    )
                }
            },
            {
                id: 'actions',
                Header: t('actions'),
                accessor: 'id' as const,
                Cell: ({value}: any) => {
                    return (
                        <div className={st.actions}>
                            <Tooltip title={t('admin.users.data.administration')} placement={'top'} arrow>
                                <Link
                                    to={`/admin/users/${value}`}>
                                    <IdentificationIcon
                                        className={'h-5 w-5 text-purple-500 cursor-pointer hover:scale-105'}/>
                                </Link>
                            </Tooltip>
                            {/*<Tooltip title={t('admin.users.data.profile')} placement={'top'} arrow>*/}
                            {/*    <UserCircleIcon*/}
                            {/*        className={'h-5 w-5 text-green-500 cursor-pointer hover:scale-105'}/>*/}
                            {/*</Tooltip>*/}
                            {/*<Tooltip title={t('admin.users.data.editProfile')} placement={'top'} arrow>*/}
                            {/*    <PencilSquareIcon*/}
                            {/*        className={'h-5 w-5 text-blue-500 cursor-pointer hover:scale-105'}/>*/}
                            {/*</Tooltip>*/}
                        </div>
                    )
                }
            }
        ],
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns, data: users});

    return (
        <>
            <CardTemplate>
                <table {...getTableProps()} className={st.table}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr className={cn(st.trClass)} {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </CardTemplate>
        </>
    );
};

export default AdminUsersTable;