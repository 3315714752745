import React, {useContext} from 'react';
// @ts-ignore
import Input from "../../UI/inputs/Input";
import {ClipboardDocumentIcon} from "@heroicons/react/24/outline";
import st from './CopyInput.module.css'
import {Context} from "../../../index";


type CopyInputProps = {
    title?: string | undefined | null;
    value: string;
    forView?: boolean;
    blurred?: boolean;
}

const CopyInput = ({title, value, forView, blurred = false}: CopyInputProps) => {

    const {store} = useContext(Context)

    const copy = () => {
        store.utils.copyToClipboard(value || '')
    }

    return (
        <div className={st.elementContainer}>
            <div className={st.inputComponent}>
                <Input
                    title={title || ''}
                    small={true}
                    widthFull={true}
                    value={value}
                    forView={forView || false}
                    disabled={blurred}
                />
                <div className={st.copyBlock}>
                    <ClipboardDocumentIcon onClick={copy} className={st.copyIcon}/>
                </div>
            </div>
        </div>
    );
};

export default CopyInput;