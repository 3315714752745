import { action, observable, runInAction } from 'mobx';

interface CachedItem<T> {
    data: T;
    timestamp: number;
}

class CachedDataStore<T> {
    @observable cachedData: { [key: string]: CachedItem<T> } = {};

    constructor() {
        this.loadCache();
    }

    // Метод для получения данных с возможным кешированием
    @action
    async fetchData(key: string, fetchDataCallback: () => Promise<T>): Promise<T> {
        const cachedItem = this.cachedData[key];
        if (cachedItem) {
            const { data, timestamp } = cachedItem;
            if (Date.now() - timestamp < 3600000) {
                return data; // Возвращаем закешированные данные, если они не устарели
            }
        }
        // Если данных в кеше нет или они устарели, то делаем запрос
        return await this.updateData(key, fetchDataCallback);
    }

    // Метод для обновления данных и кеширования
    @action
    private async updateData(key: string, fetchDataCallback: () => Promise<T>): Promise<T> {
        const newData = await fetchDataCallback();

        if (newData === null) {
            return new Promise(() => this.cachedData[key]);
        }

        // Обновляем кеш
        runInAction(() => {
            this.cachedData[key] = {
                data: newData,
                timestamp: Date.now()
            };
        });

        this.saveCache(); // Сохраняем обновленные данные в localStorage

        return newData; // Возвращаем новые данные
    }

    // Метод для загрузки кеша из localStorage
    @action
    loadCache() {
        const cachedData = localStorage.getItem('cachedData');
        if (cachedData) {
            this.cachedData = JSON.parse(cachedData);
        }
    }

    // Метод для сохранения кеша в localStorage
    @action
    saveCache() {
        localStorage.setItem('cachedData', JSON.stringify(this.cachedData));
    }

    // Метод для очистки кеша
    @action
    clearCache() {
        this.cachedData = {};
        localStorage.removeItem('cachedData');
    }
}

const dataStore = new CachedDataStore<any>(); // Лучше заменить any на конкретный тип данных

export default dataStore;
