import React, {useContext, useState} from 'react';
import st from './UserContactsInfo.module.css'
import CardTemplate from "../../../UI/bases/CardTemplate";
import StandardTitle from "../../../UI/titles/StandardTitle";
import CopyInput from "../../../utils/CopyInput/CopyInput";
import {IUserAdminProfileResponse} from "../../../../models/user/IUserAdminProfileResponse";
import {Context} from "../../../../index";
import {useForm} from "react-hook-form";
import cn from "classnames";
import {CheckCircleIcon, PencilSquareIcon, XCircleIcon} from "@heroicons/react/24/outline";
import Input from "../../../UI/inputs/Input.jsx";
import {useTranslation} from "react-i18next";
import ConfirmModal from "../../../UI/modals/ConfirmModal.jsx";
import {UserEditDataRequest} from "../../../../models/user/UserEditDataRequest";

type UserContactsInfoProps = {
    user: IUserAdminProfileResponse;
    reload: () => void;
}

const UserContactsInfo = ({user, reload}: UserContactsInfoProps) => {
    const {store} = useContext(Context)
    const {t} = useTranslation()
    const [editMode, setEditMode] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [userEditContactsRequest, setUserEditContactsRequest] = useState<UserEditDataRequest>({} as UserEditDataRequest)
    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onChange",
        defaultValues: {
            email: user.contacts.email,
        }
    })


    const onSubmit = async (data: any) => {
        setConfirmModal(true)
        setUserEditContactsRequest(data as UserEditDataRequest)
    }

    const confirmEdit = async () => {
        setConfirmModal(false)
        if (await store.user.editUserContacts(user.id, userEditContactsRequest)) {
            store.utils.notify('Данные успешно изменены', 'success')
            setEditMode(false)
            reload()
        } else {
            store.utils.notify('Ошибка изменения данных', 'error')
        }
    }

    return (
        <CardTemplate>
            <form onSubmit={handleSubmit(onSubmit)} className={st.content}>
                <div className={st.title}>
                    <StandardTitle title={'Контакты'}/>
                    {
                        editMode ?
                            <div className={st.actions}>
                                <button type={'submit'} className={cn(st.icon, st.iconOk)}>
                                    <CheckCircleIcon
                                        className={cn(st.icon, st.iconOk)}/>
                                </button>
                                <XCircleIcon onClick={() => setEditMode(!editMode)} className={st.icon}/>
                            </div>
                            :
                            <PencilSquareIcon onClick={() => setEditMode(!editMode)} className={st.icon}/>
                    }
                </div>
                <div className={st.attrList}>
                    {
                        editMode ?
                            <Input
                                title={"Email"}
                                name={"email"}
                                type={"email"}
                                errors={errors}
                                register={register}
                                validationSchema={{
                                    required: t('valMessageAuth.email'),
                                    minLength: {
                                        value: 4,
                                        message: t('valMessage.minSymbols4')
                                    }
                                }}
                            />
                            :
                            <>
                                <CopyInput value={user.contacts.email} title={'Email'}/>
                            </>
                    }

                    <CopyInput value={user.contacts.vk || ''} title={'Vk'}/>
                    <CopyInput value={user.contacts.telegram || ''} title={'Telegram'}/>
                </div>
            </form>
            <ConfirmModal
                open={confirmModal}
                setOpen={setConfirmModal}
                header={'Изменение данных'}
                body={'Вы действительно хотите изменить данные пользователя?'}
                confirmText={'Изменить'}
                action={confirmEdit}
            />
        </CardTemplate>
    );
};

export default UserContactsInfo;