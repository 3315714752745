import React from 'react';


interface Props {
    title?: String | any
    children?: any
}

const StandardTitle = ({title, children}: Props) => {
    return (
        <div className={'text-xl font-semibold'}>
            {title || children}
        </div>
    );
};

export default StandardTitle;