import React, {useContext, useEffect, useState} from 'react';
import WidgetPattern from "../WidgetPattern/WidgetPattern";
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";
import QueueSwitchProposalWidgetCard
    from "../../dashboard/queue/queueLists/cards/QueueSwitchProposalWidgetCard/QueueSwitchProposalWidgetCard";

const WidgetProposals = () => {
    const {store} = useContext(Context)
    const {t} = useTranslation()
    const [proposals, setProposals] = useState([] as any[])

    useEffect(() => {
        store.queue.getProposalForWidget(store.currentGroup?.id).then(data => {
            setProposals(data)
        })
    }, [
        store.currentGroup?.id,
        store.queue,
    ])

    return (
        <WidgetPattern title={t('widget.proposals.title')}>
            {
                proposals.length > 0 ?
                    <div className={'flex flex-col gap-2'}>
                        {proposals.map((proposal, index) =>
                            <QueueSwitchProposalWidgetCard data={proposal} groupAlias={store.currentGroup?.alias}/>
                        )}
                    </div>
                    :
                    <div className={'italic text-gray-500'}>{t('widget.proposals.noProposals')}</div>
            }
        </WidgetPattern>
    );
};

export default WidgetProposals;