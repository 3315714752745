import {Route, Routes, useNavigate, useSearchParams} from "react-router-dom";
import Home from "./pages/home/Home";
import Queues from "./pages/dashboard/queue/Queues/Queues";
import UserOffice from "./pages/dashboard/Office/UserOffice";
import Login from "./pages/auth/Login/Login";
// import Register from "./pages/Auth/Register/Register";
import QueueView from "./pages/dashboard/queue/QueueView/QueueView";
import Contact from "./pages/home/Contact";
import PublicServicesHome from "./pages/home/Services/PublicServicesHome";
import Informatics9Page from "./pages/home/Services/Informatics9Page";
import ComponentList from "./pages/development/ComponentList";
import React, {useContext, useEffect, useState} from "react";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import DashboardPage from "./pages/dashboard/DashboardPage/DashboardPage";
// import TmpRegister from "./pages/Auth/Register/TmpRegister";
import Groups from "./pages/dashboard/group/Groups/Groups";
import GroupInvite from "./pages/dashboard/group/GroupInvite/GroupInvite";
import {ToastContainer} from "react-toastify";
import SelectGroup from "./pages/dashboard/helpers/SelectGroup/SelectGroup";
import GroupView from "./pages/dashboard/group/GroupView/GroupView";
import HelloPage from "./pages/dashboard/helpers/HelloPage/HelloPage";
import "./i18n"
import Profile from "./pages/dashboard/user/Profile/Profile";
import UsersTable from "./pages/admin/UsersTable/UsersTable";
import 'regenerator-runtime'
import GroupSchedule from "./pages/dashboard/schedule/GroupSchedule/GroupSchedule";
import ConfirmEmail from "./pages/dashboard/helpers/ConfirmEmail/ConfirmEmail";
import NewRegister from "./pages/auth/Register/NewRegister";
import ForgotPassword from "./pages/dashboard/helpers/ForgotPassword/ForgotPassword";
import RecoveryPassword from "./pages/dashboard/helpers/RecoveryPassword/RecoveryPassword";
import GuestSchedule from "./pages/home/Services/GuestSchedule/GuestSchedule";
import UserAdminProfile from "./pages/admin/UserAdminProfile/UserAdminProfile";
import GroupAssignments from "./pages/dashboard/assigments/GroupAssignments/GroupAssignments";
import CreateGroupAssignments from "./pages/dashboard/assigments/creating/CreateGroupAssignments";
import UpdateAssignment from "./pages/dashboard/assigments/UpdateAssignment/UpdateAssignment";
import CreatePersonalAssignment from "./pages/dashboard/assigments/creating/CreatePersonalAssignment";
import ViewAssignment from "./pages/dashboard/assigments/ViewAssignment/ViewAssignment";
import PersonalAssignments from "./pages/dashboard/assigments/PersonalAssignments/PersonalAssignments";

// import TmpRegister from "./pages/Auth/Register/TmpRegister";

function App() {
    const {store} = useContext(Context);

    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();

    const [pwaCheck, setPwaCheck] = useState(false);

    useEffect(() => {
        const checkAuth = () => {
            try {
                // Получаем токен из localStorage
                const token = localStorage.getItem('token');
                // Если токен есть, проверяем авторизацию на сервере
                if (token) {
                    store.checkAuth();
                    store.setCheck(true);
                }
            } catch (error) {
                // Обрабатываем ошибку
                console.error(error);
            }
        };
        checkAuth();

        if (search.get('utm_source')) {
            if (search.get('utm_source') === 'homescreen') {
                setSearch('')
                store.setPwa(true);
                navigate('/dashboard');
            }
        }
        setPwaCheck(true);

        store.setCheck(true);
    }, [store, search, setSearch, navigate]);


    // const {store} = useContext(Context);
    // useEffect(() => {
    //     if (localStorage.getItem('token') !== null) {
    //         // console.log(localStorage.getItem('token'))
    //         store.checkAuth().then();
    //     }
    // }, [store, store.checkAuth]);

    // if (store.isAuth && store.isLoading) {
    //     return <div>Загрузка</div>
    // }


    return (
        <div>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                theme={'dark'}
            />
            <Routes>
                {
                    pwaCheck &&
                    <Route path={'/'} element={<Home/>}/>
                }
                <Route path={'/home'} element={<Home/>}/>
                <Route path={'/team'} element={<Contact/>}/>
                <Route path={'/login'} element={<Login/>}/>
                <Route path={'/register'} element={<NewRegister/>}/>
                <Route path={'/forgot-password'} element={<ForgotPassword/>}/>
                <Route path={'/recovery-password/:code'} element={<RecoveryPassword/>}/>
                {
                    store.isCheck &&
                    <Route element={<ProtectedRoute redirectPath={'/login'} isAllowed={store.isAuth} children={''}/>}>
                        <Route path={'/select-group'} element={<SelectGroup/>}/>
                        <Route path={'/hello-page'} element={<HelloPage/>}/>
                        <Route path={'/confirm-email'} element={<ConfirmEmail/>}/>

                        <Route path={'/dashboard'} element={<DashboardPage/>}/>
                        <Route path={'/d/groups'} element={<Groups/>}/>
                        <Route path={'/d/groups/:alias'} element={<GroupView/>}/>

                        <Route path={'/g/:group/dashboard'} element={<DashboardPage/>}/>
                        <Route path={'/g/:groupAlias/queues/:queueAlias'} element={<QueueView/>}/>
                        <Route path={'/g/:group/queues'} element={<Queues/>}/>
                        <Route path={'/g/:group/schedule'} element={<GroupSchedule/>}/>
                        <Route path={'/g/:group/assignments'} element={<GroupAssignments/>}/>
                        <Route path={'/g/:group/assignments/create'} element={<CreateGroupAssignments/>}/>

                        <Route path={'/d/assignments'} element={<PersonalAssignments/>}/>
                        <Route path={'/d/assignments/create'} element={<CreatePersonalAssignment/>}/>
                        <Route path={'/d/assignments/:assignmentId'} element={<ViewAssignment/>}/>
                        <Route path={'/d/assignments/:assignmentId/update'} element={<UpdateAssignment/>}/>
                        <Route path={'/d/invites/:code'} element={<GroupInvite/>}/>
                        <Route path={'/u/:username'} element={<Profile/>}/>

                        {/* DEPRECATED */}
                        <Route path={'/lk'} element={<UserOffice/>}/>
                        {/* /DEPRECATED */}


                        <Route path={'/admin'} element={<ProtectedRoute redirectPath={'/dashboard'}
                                                                        isAllowed={store.havePermission('ADMIN_VIEW_PANEL', false, '')}
                                                                        children={''}/>}>
                            <Route path={'/admin/users'} element={<UsersTable/>}/>
                            <Route path={'/admin/users/:userId'} element={<UserAdminProfile/>}/>

                        </Route>
                    </Route>
                }


                <Route path={'/services/informatics9'} element={<Informatics9Page/>}/>
                <Route path={'/services/schedule'} element={<GuestSchedule/>}/>
                <Route path={'/services'} element={<PublicServicesHome/>}/>

                <Route path={'/components'} element={<ComponentList/>}/>

            </Routes>

        </div>
    );
}

export default observer(App);
