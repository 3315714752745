import React from 'react';
import Base from "./Base";

type Props = {
    hovered?: boolean
    children: React.ReactNode
    className?: any
}

const CardTemplate = ({children, className, hovered, ...other}: Props) => {
    return (
        <Base hovered={hovered} className={`template__card ${className}`} {...other}>
            {children}
        </Base>
    );
};

export default CardTemplate;