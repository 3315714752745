import React from 'react';
// @ts-ignore
import QueueSwapOfferUserCard from "./cards/QueueSwapOfferUserCard/QueueSwapOfferUserCard";
import UsersListTemplate from "../../../UI/lists/UsersListTemplate";
import {useTranslation} from "react-i18next";

// type Props = {
//     offers: {
//         toPlace: number,
//     }[];
//     swapAction: Function;
//     userInfo: {
//         placeInQueue: number
//     };
// }

const QueueSwapOfferList = (props) => {
    const {t} = useTranslation();

    const {offers, swapAction, getPlaceById} = props
    return (
        <UsersListTemplate title={t('queueViewPage.getOffers')}>
            {offers.map((offer, index) =>
                <QueueSwapOfferUserCard
                    key={index}
                    offer={offer}
                    index={index}
                    swapAction={swapAction}
                    toPlace={getPlaceById(offer.user.id)}
                    movingUp={getPlaceById(offer.user.id) < getPlaceById(offer.userToSwitch.id)}
                />
            )}
        </UsersListTemplate>

    );
};

export default QueueSwapOfferList;