import React from 'react';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import ManageAssignment from "../../../../components/dashboard/assigments/ManageAssignment/ManageAssignment";

const UpdateAssignment = () => {
    const {assignmentId} = useParams();
    const {t} = useTranslation()
    return (
        <Dashboard title={t('assignments.manage.updateTitle')}>
            <ManageAssignment isNew={false} assignmentId={assignmentId}/>
        </Dashboard>
    );
};

export default UpdateAssignment;