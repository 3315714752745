import React from 'react';
import {UserInGroup} from "../../../../models/UserInGroup";
import CardLightTemplate from "../../../UI/bases/CardLightTemplate";
import st from './UserInGroupList.module.css';

type Props = {
  index: number;
  user: UserInGroup;
};

const UserInGroupList = ({index, user}: Props) => {
    return (
        <CardLightTemplate className={st.mainContainer}>
            <div className={st.numberContainer}>
                <div className={st.number}>{index + 1}</div>
            </div>
            <div className={st.userInfo}>
                <img src={user.avatar ? user.avatar : 'https://placehold.it/100x100'}
                     className={st.avatar}
                     alt={'avatar'}/>
                <div className={st.info}>
                    <div className={st.name}>{user.lastName} {user.firstName}</div>
                    <div className={st.role}>
                        {user.username ? 'aka ' + user.username : ''}
                    </div>
                </div>
            </div>
        </CardLightTemplate>
    );
};

export default UserInGroupList;