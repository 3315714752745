import React, {useContext, useEffect, useState} from 'react';
import st from './UsersTableStats.module.css'
import CardTemplate from "../../../UI/bases/CardTemplate";
import LightBadge from "../../../UI/badge/LightBadge/LightBadge";
import {Context} from "../../../../index";
import {UsersStats} from "../../../../models/user/UsersStats";

const UsersTableStats = () => {

    const {store} = useContext(Context)

    const [stats, setStats] = useState<UsersStats>()

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const data = await store.user.getUsersStats()
                setStats(data)
            } catch (error) {
                console.error('Error fetching users stats:', error)
            }
        }
        fetchStats()

    }, [setStats, store]);

    return (
        <div className={st.statBlock}>
            <CardTemplate className={st.stat}>
                <LightBadge title={'Всего пользователей'} value={stats?.count}/>
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                    <g clipPath="url(#clip0_224_459)">
                        <g clipPath="url(#clip1_224_459)">
                            <path
                                d="M13.375 27.4375V25.5625C13.375 24.5679 13.7701 23.6141 14.4733 22.9109C15.1766 22.2076 16.1304 21.8125 17.125 21.8125H20.875C21.8696 21.8125 22.8234 22.2076 23.5266 22.9109C24.2299 23.6141 24.625 24.5679 24.625 25.5625V27.4375M15.25 14.3125C15.25 15.3071 15.6451 16.2609 16.3483 16.9642C17.0516 17.6674 18.0054 18.0625 19 18.0625C19.9946 18.0625 20.9484 17.6674 21.6517 16.9642C22.3549 16.2609 22.75 15.3071 22.75 14.3125C22.75 13.3179 22.3549 12.3641 21.6517 11.6608C20.9484 10.9576 19.9946 10.5625 19 10.5625C18.0054 10.5625 17.0516 10.9576 16.3483 11.6608C15.6451 12.3641 15.25 13.3179 15.25 14.3125Z"
                                stroke="#7367F0" strokeWidth="1.40625" strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </g>
                        <rect opacity="0.16" width="38" height="38" rx="6" fill="#7367F0"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_224_459">
                            <rect width="38" height="38" rx="6" fill="white"/>
                        </clipPath>
                        <clipPath id="clip1_224_459">
                            <rect width="22.5" height="22.5" fill="white" transform="translate(7.75 7.75)"/>
                        </clipPath>
                    </defs>
                </svg>
            </CardTemplate>
            <CardTemplate className={st.stat}>
                <LightBadge title={'Новых пользователей'} value={stats?.newCount}/>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    fill="none"
                    viewBox="0 0 38 38"
                >
                    <g clipPath="url(#clip0_224_467)">
                        <g clipPath="url(#clip1_224_467)">
                            <path
                                stroke="#EA5455"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.406"
                                d="M22.75 25.563h5.625m-2.813-2.813v5.625m-12.187-.938v-1.875a3.75 3.75 0 013.75-3.75h3.75m-5.625-7.5a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0z"
                            ></path>
                        </g>
                        <rect
                            width="38"
                            height="38"
                            fill="#EA5455"
                            opacity="0.16"
                            rx="6"
                        ></rect>
                    </g>
                    <defs>
                        <clipPath id="clip0_224_467">
                            <rect width="38" height="38" fill="#fff" rx="6"></rect>
                        </clipPath>
                        <clipPath id="clip1_224_467">
                            <path
                                fill="#fff"
                                d="M0 0H22.5V22.5H0z"
                                transform="translate(7.75 7.75)"
                            ></path>
                        </clipPath>
                    </defs>
                </svg>
            </CardTemplate>
            <CardTemplate className={st.stat}>
                <LightBadge title={'Активных пользователей'} value={stats?.activeCount}/>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    fill="none"
                    viewBox="0 0 38 38"
                >
                    <g clipPath="url(#clip0_224_477)">
                        <g clipPath="url(#clip1_224_477)">
                            <path
                                stroke="#28C76F"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.406"
                                d="M13.375 27.438v-1.875a3.75 3.75 0 013.75-3.75h3.75m.938 3.75l1.875 1.875 3.75-3.75M15.25 14.313a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0z"
                            ></path>
                        </g>
                        <rect
                            width="38"
                            height="38"
                            fill="#28C76F"
                            opacity="0.16"
                            rx="6"
                        ></rect>
                    </g>
                    <defs>
                        <clipPath id="clip0_224_477">
                            <rect width="38" height="38" fill="#fff" rx="6"></rect>
                        </clipPath>
                        <clipPath id="clip1_224_477">
                            <path
                                fill="#fff"
                                d="M0 0H22.5V22.5H0z"
                                transform="translate(7.75 7.75)"
                            ></path>
                        </clipPath>
                    </defs>
                </svg>
            </CardTemplate>
            <CardTemplate className={st.stat}>
                <LightBadge title={'Ожидают верификации'} value={stats?.pendingCount}/>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    fill="none"
                    viewBox="0 0 38 38"
                >
                    <g clipPath="url(#clip0_224_487)">
                        <g clipPath="url(#clip1_224_487)">
                            <path
                                stroke="#FF9F43"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.406"
                                d="M13.375 27.438v-1.875a3.75 3.75 0 013.75-3.75h3.75c.326 0 .643.042.945.12m3.742.817v2.813m0 2.812v.01M15.25 14.311a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0z"
                            ></path>
                        </g>
                        <rect
                            width="38"
                            height="38"
                            fill="#FF9F43"
                            opacity="0.16"
                            rx="6"
                        ></rect>
                    </g>
                    <defs>
                        <clipPath id="clip0_224_487">
                            <rect width="38" height="38" fill="#fff" rx="6"></rect>
                        </clipPath>
                        <clipPath id="clip1_224_487">
                            <path
                                fill="#fff"
                                d="M0 0H22.5V22.5H0z"
                                transform="translate(7.75 7.75)"
                            ></path>
                        </clipPath>
                    </defs>
                </svg>
            </CardTemplate>
        </div>
    );
};

export default UsersTableStats;