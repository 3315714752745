import Store from "../store";
import {action, makeAutoObservable} from "mobx";
import dataStore from "./CachedDataStore";

export default class ScheduleStore {
    private rootStore: Store;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async getCurrentWeek() {
        try {
            // return 30
            const week = await dataStore.fetchData(`schedule_week`, async () => {
                const response = await fetch('https://mirea.xyz/api/v1.3/time/week')
                return response.json()
            })

            return await week;
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }


    @action
    async getSchedule(group: string) {
        // Вызываем метод fetchData, передавая ключ в качестве параметра и функцию для выполнения запроса
        const schedule = await dataStore.fetchData(`schedule_${group}`, async () => {
            const response = await fetch(`https://mirea.xyz/api/v1.3/groups/certain?name=${group}`);
            // dataStore.saveCache()
            return response.json();
        });
        return schedule;

    }

    // async getSchedule(group: string) {
    //     try {
    //         const response = await fetch(`https://mirea.xyz/api/v1.3/groups/certain?name=${group}`)
    //         return await response.json()
    //     } catch (e) {
    //         this.rootStore.errorHandler(e)
    //     }
    // }
}