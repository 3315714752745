import React, {useState} from 'react';
import Button from "../buttons/Button";
import st from './Tabs.module.css';

type TabProps = {
    title: string;
    children: React.ReactNode;
};

type TabsProps = {
    children: React.ReactElement<TabProps>[];
};

const Tabs: React.FC<TabsProps> = ({children}) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    return (
        <div className="tabs">
            <div className={st.tabsList}>
                {children.map((child, index) => (
                    <Button
                        type={'button'}
                        title={child.props.title}
                        key={index}
                        onClick={() => handleTabClick(index)}
                        color={activeTab === index ? 'blue' : 'gray'}
                        size={'md'}
                        outline={false}
                        width={'auto'}
                        className={'flex-grow'}
                        isIcon={false} disabled={false}/>
                ))}
            </div>
            <div className={st.tabContent}>
                {children[activeTab]}
            </div>
        </div>
    );
};

const Tab: React.FC<TabProps> = ({children}) => {
    return <>{children}</>;
};

export {Tabs, Tab};
