import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationEN from './locales/en.json';
import translationRU from './locales/ru.json';
import translationZH from './locales/zh.json';
import translationFR from './locales/fr.json';
import translationCT from './locales/ct.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // Подключаем переводы из файлов в папке locales
        resources: {
            en: {
                translation: translationEN,
            },
            ru: {
                translation: translationRU,
            },
            zh: {
                translation: translationZH,
            },
            fr: {
                translation: translationFR,
            },
            ct: {
                translation: translationCT,
            }
        },

        lng: "ru", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });