import React from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import {useTranslation} from "react-i18next";
import AssignmentsList from "../../../../components/dashboard/assigments/AssignmentsList/AssignmentsList";

const PersonalAssignments = () => {
    const {t} = useTranslation()

    return (
        <Dashboard title={t('menu.local-assignments')}>
            <AssignmentsList/>
        </Dashboard>
    );
};

export default PersonalAssignments;