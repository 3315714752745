import Store from "../store";
import {makeAutoObservable} from "mobx";
import $api from "../../http";
import {UniversityResponse} from "../../models/university/UniversityResponse";

export default class UniversityStore {
    private rootStore: Store;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async getAll() {
        try {
            const response = await $api.get<[UniversityResponse]>('/institutions')
            return response.data;
        } catch (e) {
            // ...
        }
    }
}