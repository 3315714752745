import {useContext, useEffect, useRef, useState} from 'react'
import {Dialog} from '@headlessui/react'
import st from './Modals.module.css'
import Button from "../buttons/Button";
import PropTypes from "prop-types";
import ModalTemplate from "../bases/ModalTemplate";
import Input from "../inputs/Input";
import Textarea from "../inputs/Textarea";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import Dropdown from "../dropdown/Dropdown/Dropdown";
import {Context} from "../../../index";


const CreateGroupModal = (props) => {
    const {t} = useTranslation();
    const {store} = useContext(Context);

    const {open, setOpen, action} = props

    const cancelButtonRef = useRef(null)

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [universities, setUniversities] = useState([])

    const [selectedUniversity, setSelectedUniversity] = useState(null)

    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        defaultValues: {
            university: "",
            name: "",
            description: "",
        },
        mode: "onBlur"
    });


    const clearForm = () => {
        reset({
            university: "",
            name: "",
            description: ""
        });
    };

    const onSubmit = async (data) => {
        try {
            setIsSubmitting(true);
            await action(data);
            clearForm();
        } catch (error) {
            console.log(error);
        } finally {
            setIsSubmitting(false);
        }
    };


    useEffect(() => {
        const fetchInstitutions = async () => {
            store.university.getAll().then((value) => {
                setUniversities(value || [])
            })
        }
        fetchInstitutions()
    }, [store, setUniversities])

    const handleDropdownChange = (selectedValue) => {
        if (selectedValue === 'free') {
            setSelectedUniversity({
                groupsMask: ".*",
            })
            return
        }
        setSelectedUniversity(
            universities
                .find((university) => university.id === selectedValue))
    };


    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={st.mainContent}>
                        <div className={st.mainContentContainer}>
                            <div className="h-full mt-3 w-full text-center sm:mt-0 sm:text-left sm:w-3/5">
                                <Dialog.Title as="h3"
                                              className={`${st.header} ${st.createGroupTitle}`}>
                                    {t('groupPage.createGroup.valMessageCreateGroup.title')}
                                </Dialog.Title>
                                <div className={st.body}>
                                    <div className={st.inputBlock}>
                                        {/*<Input*/}
                                        {/*    title={t('groupPage.createGroup.title')}*/}
                                        {/*    value={'РТУ МИРЭА'}*/}
                                        {/*    disabled={true}/>*/}

                                        <Dropdown
                                            title={t('university')}
                                            name={'university'}
                                            onChange={handleDropdownChange}
                                            items={
                                                [
                                                    {
                                                        value: 'free',
                                                        label: t('groupPage.createGroup.withoutUniversity')
                                                    },
                                                    ...universities.map(
                                                        (university) => {
                                                            return {
                                                                value: university.id,
                                                                label: university.shortName
                                                            }
                                                        })]
                                            }
                                            register={register}/>

                                        <Input
                                            title={t('group')}
                                            small={t('groupPage.createGroup.example')}
                                            name={"name"}
                                            type={"text"}
                                            errors={errors}
                                            disabled={selectedUniversity === null}
                                            register={register}
                                            validationSchema={{
                                                required: t('groupPage.createGroup.valMessageCreateGroup.format'),
                                                minLength: {
                                                    value: 2,
                                                    message: t('valMessage.minSymbols2'),
                                                },
                                                pattern: {
                                                    value: new RegExp(selectedUniversity?.groupsMask || '.*'),
                                                    // value:  /^[а-яА-ЯёЁa-zA-Z]{4}-\d{2}-\d{2}$/,
                                                    // value: new RegExp("^[а-яА-ЯёЁa-zA-Z]{4}-[0-9]{2}-[0-9]{2}$"),
                                                    message: t('groupPage.createGroup.valMessageCreateGroup.format'),
                                                },
                                            }}
                                            required={true}
                                        />
                                        <Textarea
                                            title={t('description')}
                                            name={"description"}
                                            type={"text"}
                                            resize={false}
                                            errors={errors}
                                            disabled={selectedUniversity === null}
                                            register={register}
                                            validationSchema={{
                                                maxLength: {
                                                    value: 100,
                                                    message: t('valMessage.maxSymbols'),
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={st.infoBlock}>
                                <div className="pb-4">
                                    <p className={st.tittleInfo}>{t('groupPage.createGroup.notion')}</p>
                                    <p className={st.info}>{t('groupPage.createGroup.notionText')}</p>
                                </div>
                                <div>
                                    <p className={st.tittleInfo}>{t('rules')}</p>
                                    <p className={st.info}> {t('groupPage.createGroup.rulesText')}
                                        <p className={st.moreInfo}>{t('info')}</p></p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={st.btnBlock}>
                        <Button
                            color={"blue"}
                            width={"sm-full"}
                            disabled={isSubmitting}
                        >
                            {t('create')}
                        </Button>
                        <Button
                            color={"gray"}
                            outline={true}
                            onClick={() => setOpen(false)}
                            width={"sm-full"}
                            ref={cancelButtonRef}
                        >
                            {t('cancel')}
                        </Button>
                    </div>
                </form>
            </Dialog.Panel>
        </ModalTemplate>
    )
}

CreateGroupModal.defaultProps = {
    open: false,
    setOpen: null,
    action: null,
}

CreateGroupModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    action: PropTypes.func,
}


export default CreateGroupModal;