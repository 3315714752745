import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Context} from "../../../../index";
import CardTemplate from "../../../UI/bases/CardTemplate";
import st from './ChangePassword.module.css'
import StandardTitle from "../../../UI/titles/StandardTitle";
import {useForm} from "react-hook-form";
import Input from "../../../UI/inputs/Input.jsx"
import Button from "../../../UI/buttons/Button";

const ChangePassword = () => {
    const {t} = useTranslation()
    const {store} = useContext(Context)

    const [submitting, setSubmitting] = useState(false)

    const {register, watch, handleSubmit, formState: {errors}, reset} = useForm({
        defaultValues: {
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: '',
        },
        mode: "onChange"
    });

    const clearForm = () => {
        reset({
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: '',
        })
    }

    const onSubmit = (data: { oldPassword: string; newPassword: string; repeatNewPassword: string }) => {
        try {
            setSubmitting(true)
            // @ts-ignore
            delete data.repeatNewPassword
            store.changePassword(data).then(() => {
                    setSubmitting(false)
                    clearForm()
                }
            )
        } catch (e) {
            console.log(e)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <CardTemplate className={st.changePasswordContainer}>
            <StandardTitle title={t('office.changePassword')}/>
            <form className={st.inputs} onSubmit={handleSubmit(onSubmit)}>
                <div className={st.oldPassword}>
                    <Input
                        title={t('office.change.oldPassword')}
                        name={"oldPassword"}
                        type={"password"}
                        errors={errors}
                        register={register}
                        validationSchema={{
                            required: {
                                value: true,
                                message: t('valMessage.requiredField'),
                            }
                        }}
                    />
                </div>
                <div className={st.newPasswords}>
                    <Input
                        title={t('office.change.newPassword')}
                        name={"newPassword"}
                        type={"password"}
                        errors={errors}
                        register={register}
                        validationSchema={{
                            required: {
                                value: true,
                                message: t('valMessage.requiredField'),
                            },
                            minLength: {
                                value: 4,
                                message: t('valMessage.minSymbols4')
                            }
                        }}
                    />
                    <Input
                        title={t('office.change.repeatPassword')}
                        name={"repeatNewPassword"}
                        type={"password"}
                        errors={errors}
                        register={register}
                        validationSchema={{
                            required: {
                                value: true,
                                message: t('valMessage.requiredField'),
                            },
                            validate: (value: string) => {
                                return value === watch('newPassword') || t('valMessage.passwordMatch')
                            }
                        }}
                    />
                </div>
                <div>
                    {/* // @ts-ignore */}
                    <Button
                        type={'submit'}
                        color={'blue'}
                        size={'base'}
                        outline={false}
                        title={t('user.security.changePassword')}
                        width={'auto'}
                        className={''}
                        disabled={submitting}
                        isIcon={false}/>
                </div>
            </form>

        </CardTemplate>
    );
};

export default ChangePassword;