import React from 'react';
import st from './Input.module.css'
import PropTypes from "prop-types";
import cn from 'classnames';

const Textarea = (props) => {
    const {
        outline,
        name,
        register,
        errors,
        required,
        widthFull,
        title,
        validationSchema,
        resize,
        value,
        disabled,
        initialvalue
    } = props
    return (
        <div className={st.inputBlock}>
            <span className={cn(st.inputBlockTittle, {
                [st.inputBlockTittleDisabled]: disabled
            })}>{title}{required && "*"}</span>
            {
                register ?
                    <textarea
                        id={name}
                        name={name}
                        {...register(name, validationSchema)}
                        {...props}
                        defaultValue={initialvalue}
                        value={value}
                        className={cn(st.input, {
                            [st.resizeOff]: !resize,
                            [st.inputOutLine]: outline,
                            [st.wightFull]: widthFull
                        })}
                    />
                    :
                    <textarea
                        id={name}
                        name={name}
                        {...props}
                        className={cn(st.input, {
                            [st.resizeOff]: !resize,
                            [st.inputOutLine]: outline,
                            [st.wightFull]: widthFull
                        })}
                    />
            }
            {
                errors && errors[name] && (
                    <div className="error">{errors[name]?.message}</div>
                )
            }
        </div>
    );
}

Textarea.defaultProps = {
    outline: true,
    size: "",
    widthFull: true,
    statement: null,
    title: null,
    children: null,
    resize: "",
    initialvalue: "",
    disabled: false
};

Textarea.propsType = {
    outline: PropTypes.bool,
    size: PropTypes.oneOf(["sm", "base", "lg"]),
    widthFull: PropTypes.bool,
    statement: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.elementType,
    resize: PropTypes.string,
    initialvalue: PropTypes.string,
    disabled: PropTypes.bool
};

export default Textarea;