import {IUser} from "../models/IUser";
import {makeAutoObservable, runInAction} from "mobx";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import {IUserRegister} from "../models/response/IUserRegister";
import {GroupInUser} from "../models/GroupInUser";
import {toast} from "react-toastify";
import {CreateGroupDTO} from "../models/group/CreateGroupDTO";
import GroupService from "../services/GroupService";
import InviteService from "../services/InviteService";
import {CreateInviteDTO} from "../models/group/CreateInviteDTO";
import QueueService from "../services/QueueService";
import {CreateQueueDTO} from "../models/queue/CreateQueueDTO";
import {IQueue} from "../models/IQueue";
import DateTimeService from "../services/DateTimeService";
import i18n from "i18next";
import SwitchProposalService from "../services/SwitchProposalService";
import {IChangePassword} from "../models/user/IChangePassword";
import UtilsStore from "./modules/UtilsStore";
import GroupStore from "./modules/GroupStore";
import {IGroup} from "../models/IGroup";
import QueueStore from "./modules/QueueStore";
import UserStore from "./modules/UserStore";
import UniversityStore from "./modules/UniversityStore";
import ScheduleStore from "./modules/ScheduleStore";
import AssignmentStore from "./modules/AssignmentStore";

export default class Store {
    currentUser = {} as IUser;

    currentGroup = {} as GroupInUser;

    currentGroupName = '' as string;

    isAuth = false;

    isLoading = false;

    isCheck = false;

    langKey = 'ru' as string;

    pwa = false;

    // stores

    utils = new UtilsStore(this);

    group = new GroupStore(this);

    queue = new QueueStore(this);

    user = new UserStore(this);

    university = new UniversityStore(this);

    schedule = new ScheduleStore(this);

    assignment = new AssignmentStore(this);

    constructor() {
        makeAutoObservable(this, {
                utils: false,
                group: false,
                queue: false,
                user: false,
                university: false,
                schedule: false,
                assignment: false,
            },
            {
                deep: true
            });

        let currentGroupName = localStorage.getItem('currentGroupName');
        if (currentGroupName !== null) {
            this.currentGroupName = currentGroupName;
        }
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setUser(user: IUser) {
        this.currentUser = user;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setCheck(bool: boolean) {
        this.isCheck = bool;
    }

    setPwa(bool: boolean) {
        this.pwa = bool;
        localStorage.setItem('pwa', bool.toString());
    }

    setLangKey(key: string) {
        this.langKey = key
        i18n.changeLanguage(this.langKey)
        localStorage.setItem('langKey', this.langKey)
    }

    setCurrentGroupById(id: string) {
        let group = this.currentUser.groups?.find(g => g.id === id)
        if (group !== undefined) {
            this.setCurrentGroup(group);
        }
    }

    setCurrentGroup(currentGroup: GroupInUser) {
        this.currentGroup = currentGroup;
        this.currentGroupName = currentGroup.name
        localStorage.setItem('currentGroupId', currentGroup.id);
        localStorage.setItem('currentGroupName', currentGroup.name);
    }

    // обработчик ошибок http запросов
    errorHandler(e: any) {
        // Если запрос не прошел (например, нет доступа к интернету), то выводим ошибку, например 'Нет доступа к серверу'
        if (!e.response) {
            toast.error('Нет доступа к серверу');
        } else if (e.response.status === 500) {
            toast.error('Ошибка сервера');
        } else if (e.response.data.detail) {
            toast.error(e.response.data.detail)
        } else if (e.response.data.message) {
            toast.error(e.response.data.message)
        } else if (e.response.status === 404) {
            toast.error('Не найдено');
        }
    }

    /**
     * Получение группы по alias
     * @param alias
     */
    getUserGroupByAlias(alias: string): GroupInUser | undefined {
        const group = this.currentUser.groups?.find(g => g.alias === alias);
        if (group === undefined) {
            this.notification('Ошибка', 'Группа не найдена', 'error')
        }
        return group;
    }

    havePermission(
        perm: string,
        group: boolean | undefined,
        currentAlias?: string | undefined
    ): boolean {
        // Если переда group = true, то проверяется наличие права в текущей группе
        // Если переда group = false, то проверяется наличие глобальных прав
        // Если переда group = true и или groupId != undefined, то проверяется наличие права в группе с id = groupId
        if (group) {
            if (currentAlias !== undefined) {
                let group = this.currentUser.groups?.find(g => g.alias === currentAlias)
                if (group !== undefined) {
                    return group.permissions?.map(p => p.name).includes(perm) ?? false;
                }
            } else {
                return this.currentGroup.permissions?.map(p => p.name).includes(perm) ?? false;
            }
        } else {
            // Перебираем пермишанные в adminPermissions
            return this.currentUser.adminPermissions?.map(p => p.name).includes(perm) ?? false;
        }

        return false;
    }

    async authenticate(username: string, password: string) {
        try {
            const response = await AuthService.authenticate(username, password);
            localStorage.setItem('token', response.data.tokenValue);
            this.setAuth(true);
            await this.getCredentials();
        } catch (error: any) {
            if (error.status === 401) {
                this.utils.notify('Неверный логин или пароль', 'error')
            } else {
                // @ts-ignore
                toast.error(AuthService.handleError(error).message, {
                    theme: "dark",
                });
            }
        }
    }


    async register(user: IUserRegister) {
        try {
            const response = await AuthService.register(user);
            localStorage.setItem('token', response.data.tokenValue)
            this.setAuth(true);
            await this.getCredentials()
        } catch (error: any) {
            if (error.status === 400) {
                if (error.title === 'Constraint Violation') {
                    if (error?.violations?.length > 0) {
                        this.utils.notify(error?.violations[0].message, 'error')
                    }
                } else {
                    this.utils.notify(error.detail, 'error')
                }

            }
            // // @ts-ignore
            // toast.error(AuthService.handleError(error).message, {
            //     theme: "dark",
            // });
        }
    }

    async logout() {
        try {
            // const response = await AuthService.register(username, password)
            localStorage.removeItem('token')
            this.setAuth(false);
            this.setUser({} as IUser);
            this.setCurrentGroup({} as GroupInUser);
            localStorage.removeItem('user')
            localStorage.removeItem('currentGroupId')
            localStorage.removeItem('currentGroupData')

            // Переадресовывем на страницу авторизации
            window.location.href = '/login'

        } catch (e: any) {
            console.log(e.response?.data?.message)
        }
    }

    async getCredentials() {
        this.setLoading(true);
        try {
            const response = await UserService.getCredentials()
            // Сохраняем данные пользователя в localStorage
            localStorage.setItem('user', JSON.stringify(response.data))

            let user = response.data as IUser
            runInAction(() => {
                this.currentUser = user
            })
            let currentGroup = localStorage.getItem('currentGroupId');
            if (currentGroup !== null) {
                this.setCurrentGroupById(currentGroup)
            }
            // Если группа одна, выбираем её
            if (this.currentUser.groups?.length === 1) {
                this.setCurrentGroup(this.currentUser.groups[0])
            }

            if (this.currentUser.status === 'WAITING') {
                this.utils.notify('Подтвердите почту', 'info')
            }

            // Добавить проверку статуса
        } catch (e: any) {
            await this.logout()
            console.log(e.response?.data?.message)
        }
        this.setLoading(false)
    }

    async changePassword(data: IChangePassword) {
        try {
            await UserService.changePassword(data)
            this.notification('Успешно', 'Пароль успешно изменен', 'success')
        } catch (e: any) {
            this.notification('Ошибка', Store.handleError(e).title, 'error')
        }
    }

    async checkAuth() {
        this.setLoading(true);
        try {
            runInAction(
                () => {
                    // Забираем данные из localStorage
                    let lankKey = localStorage.getItem('langKey')
                    if (lankKey !== null) {
                        this.setLangKey(lankKey)
                    }
                }
            )
            runInAction(
                () => {
                    // Забираем данные из localStorage
                    let pwa = localStorage.getItem('pwa')
                    if (pwa !== null) {
                        this.setPwa(pwa === 'true')
                    }
                }
            )
            runInAction(
                () => {
                    // Забираем данные пользователя из localStorage
                    let user = localStorage.getItem('user')
                    if (user !== null) {
                        this.currentUser = JSON.parse(user);
                    }
                }
            )

            let token = localStorage.getItem('token')
            if (token !== null) {
                this.setAuth(true)
                await this.getCredentials()
            }
            // const response = await axios.get<AuthResponse>();
        } catch (e: any) {
            console.log(e.response?.data?.message)
        }
        this.setLoading(false);
    }

    /* Уведомления */
    notification(title: string, message: string, type: "success" | "error" | "info" | "warning" = "success") {
        // @ts-ignore
        toast[type](message, {
            theme: "dark",
        });
    }


    /**
     * =================
     *      Группы
     * =================
     */

    async createGroupOwner(group: CreateGroupDTO): Promise<boolean> {
        try {
            const response = await GroupService.createGroupOwner(group)
            await this.getCredentials()
            // Сообщение об успешном создании группы
            this.notification('Успешно', 'Группа ' + response.data.name + ' успешно создана', 'success')
            return true;
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', GroupService.handleError(error).message, 'error')
            return false;
        }
    }

    /**
     * Получение ссылки приглашения в группу для текущей группы
     */
    async getInviteLink(): Promise<string | undefined> {
        try {
            const response = await InviteService.getInviteByGroupId(this.currentGroup.id);
            return response.data
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', GroupService.handleError(error).message, 'error')
            return undefined;
        }
    }


    /**
     * Получение группы по alias
     */
    async getGroupByAlias(alias: string): Promise<IGroup | undefined> {
        try {
            const response = await GroupService.fetchGroupByAlias(alias);
            return response.data
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', GroupService.handleError(error).message, 'error')
            return undefined;
        }
    }

    /**
     * =================
     *    Приглашения
     * =================
     */

    /**
     * Получение ссылки приглашения в группу по id группы
     * @param groupId
     */
    async getInvite(
        groupId: string,
    ) {
        try {
            const response = await InviteService.getInviteByGroupId(groupId);
            return response.data
        } catch (error: any) {
            // @ts-ignore
            // this.notification('Ошибка', GroupService.handleError(error).message, 'error')
            return undefined;
        }
    }


    /**
     * Создание приглашения в группу
     * @param groupId
     * @param invite
     */
    async createInvite(
        groupId: string,
        invite: CreateInviteDTO
    ) {
        try {
            const response = await InviteService.createInvite(groupId, invite);
            return response.data
        } catch (error: any) {
            // @ts-ignore
            // this.notification('Ошибка', GroupService.handleError(error).message, 'error')
            return undefined;
        }
    }

    /**
     * Деактивация приглашения в группу
     * @param groupId
     */
    async deactivateInviteByGroupId(
        groupId: string,
    ) {
        try {
            const response = await InviteService.deactivateInviteByGroupId(groupId);
            return response.data
        } catch (error: any) {
            // @ts-ignore
            // this.notification('Ошибка', GroupService.handleError(error).message, 'error')
            return undefined;
        }
    }

    /**
     * Получение приглашения в группу по коду
     *
     * @param code - код приглашения
     */
    async getInviteByCode(
        code: string,
    ) {
        try {
            const response = await InviteService.getInviteByCode(code);
            return response.data
        } catch (error: any) {
            // @ts-ignore
            // this.notification('Ошибка', GroupService.handleError(error).message, 'error')
            return undefined;
        }
    }

    /**
     * Вступление в группу по приглашению
     *
     * @param code - код приглашения
     */
    async joinGroupByInvite(
        code: string,
    ) {
        try {
            await InviteService.joinGroupByInvite(code);
            this.getCredentials().then(
                () => {
                    this.notification('Успешно', 'Вы вступили в группу!', 'success')
                }
            )
            return true;
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', GroupService.handleError(error).message, 'error')
            return false;
        }
    }

    /**
     * =================
     *      Очереди
     * =================
     */

    /**
     * Получение очереди по id
     * @param queueId
     */
    async getQueueById(queueId: string) {
        try {
            const response = await QueueService.getQueueById(this.currentGroup.id, queueId);
            return response.data
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', QueueService.handleError(error).message, 'error')
            return undefined;
        }
    }

    /**
     * Получение очередей группы
     */
    async getQueues() {
        try {
            const response = await QueueService.getQueues(this.currentGroup.id);
            return response.data
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', QueueService.handleError(error).message, 'error')
            return undefined;
        }
    }


    /**
     * Получение очередей группы по alias
     * @param alias
     */
    async getQueuesByGroupAlias(alias: string) {
        try {

            // Ищем группу по alias в списке групп пользователя
            const groupInUser = this.getUserGroupByAlias(alias);
            if (!groupInUser) {
                return undefined;
            } else {
                const response = await QueueService.getQueues(groupInUser.id);
                // Преобразовываем даты в объекты Date
                response.data.forEach((queue: IQueue) => {
                    if (queue.openTime) {
                        queue.openTime = DateTimeService.convertBackDateToString(queue.openTime)
                    }
                    if (queue.closeTime) {
                        queue.closeTime = DateTimeService.convertBackDateToString(queue.closeTime)
                    }
                })
                return response.data
            }
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', QueueService.handleError(error).message, 'error')
            return undefined;
        }
    }

    /**
     * Создание очереди
     *
     * @param groupAlias
     * @param queueDTO
     */
    async createQueue(groupAlias: string, queueDTO: CreateQueueDTO) {
        try {
            const group = this.getUserGroupByAlias(groupAlias);
            if (group) {
                const response = await QueueService.createQueue(group.id, queueDTO);
                return response.data
            }
        } catch (error: any) {
            this.utils.notify(error.detail, 'error')
        }
        return undefined;
    }

    /**
     * Получение очереди по alias группы и id очереди
     *
     * @param groupAlias
     * @param queueId
     */
    async getQueuesByAliasAndGroupAlias(groupAlias: string, queueId: string) {
        try {
            const group = this.getUserGroupByAlias(groupAlias);
            if (group) {
                const response = await QueueService.getQueueByAlias(group.id, queueId);
                return response.data
            }
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', QueueService.handleError(error).message, 'error')
        }
        return undefined;
    }


    /**
     * Вступление в очередь
     * @param groupAlias
     * @param queueId
     */
    async joinQueue(groupAlias: string, queueId: string) {
        try {
            const group = this.getUserGroupByAlias(groupAlias);
            if (group) {
                const response = await QueueService.joinQueueById(group.id, queueId);
                return response.data
            }
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', QueueService.handleError(error).message, 'error')
        }
        return undefined;
    }

    /**
     * Выход из очереди
     * @param groupAlias
     * @param queueId
     */
    async leaveQueue(groupAlias: string, queueId: string) {
        try {
            const group = this.getUserGroupByAlias(groupAlias);
            if (group) {
                const response = await QueueService.leaveQueueById(group.id, queueId);
                return response.data
            }
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', QueueService.handleError(error).message, 'error')
        }
        return undefined;
    }

    /**
     * Перемешивание очереди
     * @param groupAlias
     * @param queueId
     */
    async shuffleQueue(groupAlias: string, queueId: string) {
        try {
            const group = this.getUserGroupByAlias(groupAlias);
            if (group) {
                const response = await QueueService.shuffleQueueById(group.id, queueId);
                return response.data
            }
        } catch (error: any) {
            // @ts-ignore
            this.notification('Ошибка', QueueService.handleError(error).message, 'error')
        }
        return undefined;
    }

    /**
     * =================
     *  Смена в очереди
     * =================
     */


    /**
     * Создание предложения на смену
     * @param queueId
     * @param userToSwitchId
     */
    async createSwitchProposal(queueId: string, userToSwitchId: string) {
        try {
            await SwitchProposalService.createSwitchProposal(
                this.currentGroup.id, queueId, userToSwitchId);
            return true;
        } catch (error: any) {
            this.notification('Ошибка', error.detail, 'error')
        }
        return false;
    }


    /**
     * Отмена предложения на смену
     * @param proposalId
     */
    async cancelSwitchProposal(proposalId: string) {
        try {
            await SwitchProposalService.cancelSwitchProposal(
                this.currentGroup.id, proposalId);
            return true;
        } catch (error: any) {
            this.notification('Ошибка', error.detail, 'error')
        }
        return false;
    }

    /**
     * Отклонение предложения на смену
     * @param proposalId - id предложения
     */
    async denySwitchProposal(proposalId: string) {
        try {
            await SwitchProposalService.denySwitchProposal(
                this.currentGroup.id, proposalId);
            return true;
        } catch (error: any) {
            this.notification('Ошибка', error.detail, 'error')
        }
        return false;
    }

    /**
     * Принятие предложения на смену
     * @param proposalId - id предложения
     */
    async acceptSwitchProposal(proposalId: string) {
        try {
            await SwitchProposalService.acceptSwitchProposal(
                this.currentGroup.id, proposalId);
            return true;
        } catch (error: any) {
            this.notification('Ошибка', error.detail, 'error')
        }
        return false;
    }

    /**
     * Обработчик ошибок
     */
    static handleError(error: any) {
        console.log(error);
        // {title: 'Пароль не верный', status: 400, detail: 'Пароль пользователя неверный'}

        if (error?.detail) {
            return {
                success: false,
                title: error.title,
                message: error.detail,
            }
        } else {
            return {
                success: false,
                title: 'Ошибка',
                message: 'Ошибка на стороне сервера'
            }
        }

        // if (error.response) {
        //     // Ошибка пришла от сервера (например, неверный пароль)
        //     return error.response.data;
        // } else if (error.request) {
        //     // Ошибка при выполнении запроса (например, проблемы с сетью)
        //     return {
        //         success: false,
        //         message: 'Ошибка выполнения запроса',
        //     };
        // } else if (error.response?.data?.detail) {
        //     // Ошибка пришла от сервера (например, неверный пароль)
        //     return {
        //         success: false,
        //         message: error.response.data.detail,
        //     }
        // } else {
        //     // Непредвиденная ошибка
        //     return {
        //         success: false,
        //         message: 'Ошибка на стороне сервера',
        //     };
        // }
    }


    /**
     * ====================
     *  Администрирование
     * ====================
     */

    /**
     * Получение списка всех пользователей
     *
     * @return {Promise<IUserResponse[]>}
     */
    async getAllUsers() {
        try {
            const response = await UserService.getAllUsers();
            return response.data;
        } catch (error: any) {
            this.notification('Ошибка', Store.handleError(error).message, 'error')
        }
        return [];
    }
}

