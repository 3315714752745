import React, {useState} from 'react';
import st from './Input.module.css'
import PropTypes from "prop-types";
import cn from 'classnames';
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import CardTemplate from "../bases/CardTemplate";

const Checkbox = (props) => {
    const {outline, widthFull, title, small, name, register, setValue, required, checked} = props

    const [isChecked, setIsChecked] = useState(checked)


    const swapped = () => {
        setIsChecked(!isChecked)
        setValue(name, !isChecked)
    }

    return (
        <CardTemplate className={st.inputCheckboxBlock} onClick={swapped}>
            <label className={st.inputBlockTittle}>{title}{required && "*"}<small> {small}</small>
            </label>
            {
                register ?
                    <input
                        id={name}
                        name={name}
                        type="checkbox"
                        checked={isChecked}
                        {...props}
                        className={cn(st.inputCheckbox, {
                            [st.inputOutLine]: outline,
                            [st.wightFull]: widthFull
                        })}
                    />
                    : <input
                        id={name}
                        name={name}
                        type="checkbox"
                        checked={isChecked}
                        {...props}
                        className={cn(st.inputCheckbox, {
                            [st.inputOutLine]: outline,
                            [st.wightFull]: widthFull
                        })}
                    />
            }
            <div>
                {isChecked ? <CheckCircleIcon className={st.iconEnabled}/> : <XCircleIcon className={st.iconDisabled}/>}
            </div>
        </CardTemplate>
    );
}

Checkbox.defaultProps = {
    outline: true,
    widthFull: true,
    title: null,
    small: null,
};

Checkbox.propsType = {
    outline: PropTypes.bool,
    widthFull: PropTypes.bool,
    title: PropTypes.string,
    small: PropTypes.string,
    checked: PropTypes.bool.isRequired,
};

export default Checkbox;
