import React from 'react';
import st from './LightBadge.module.css';
import cn from "classnames";
import CardTemplate from "../../bases/CardTemplate";

type Props = {
    title?: string | undefined | null;
    value?: string | React.ReactNode;
    isLink?: boolean;
    loading?: boolean
}

const LightBadge = ({title, value, isLink, loading}: Props) => {
    return (
        <div className={st.content}>
            {
                title && <div className={st.title}>{title}</div>
            }
            {
                loading !== false ? (
                    value && <div className={
                        cn(st.value, {
                            [st.link]: isLink
                        })
                    }>{value}</div>
                ) : <CardTemplate className={st.loader} children={''}/>
            }
        </div>
    );
};

export default LightBadge;