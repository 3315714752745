import React from 'react';
import st from "./Chip.module.css"
import Base from "../../bases/Base";
import cn from "classnames";
import {Link} from "react-router-dom";

interface Props {
    title: string | null | undefined | any
    link?: string | null | undefined
    color?: "gray" | "green" | "blue" | "orange" | "red"
}

const Chip = ({title, link, color}: Props) => {
    return (
        <Base className={
            cn(st.main, {
                [st.green]: color === 'green',
                [st.blue]: color === 'blue',
                [st.orange]: color === 'orange',
                [st.red]: color === 'red',
            })}
        >
            {
                link ?
                    <Link to={link} rel="noreferrer">{title}</Link>
                    :
                    <span>{title}</span>
            }
        </Base>
    );
};

export default Chip;