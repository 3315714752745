import React, {useEffect, useState} from 'react';
import st from './GuestSchedule.module.css';
import Schedule from "../../../../components/dashboard/schedule/Schedule";
import cn from "classnames";
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import {Link, useSearchParams} from "react-router-dom";
import {CheckCircleIcon, PencilSquareIcon} from "@heroicons/react/24/outline";
import Input from "../../../../components/UI/inputs/Input.jsx";

const GuestSchedule = () => {

    const [searchParams, setSearchPatams] = useSearchParams()

    const [group, setGroup] = React.useState<string>()
    // Используем pathQuery для получения параметров из url

    const [changingGroup, setChangingGroup] = React.useState<boolean>(false)

    const [isLoaded, setIsLoaded] = useState(false)

    const [tmpGroup, setTmpGroup] = useState<string>('')

    useEffect(() => {

            if (searchParams.get('g')) {
                setGroup(searchParams.get('g') || '')
                localStorage.setItem('publicScheduleGroup', searchParams.get('g') || '')
            } else if (localStorage.getItem('publicScheduleGroup')) {
                setGroup(localStorage.getItem('publicScheduleGroup') || '')
                setSearchPatams({g: localStorage.getItem('publicScheduleGroup') || ''})
            } else {
                setSearchPatams({g: ''})
            }
            setIsLoaded(true)
        },
        [searchParams, setSearchPatams, setGroup, setIsLoaded]
    )
    ;

    const setNewGroup = () => {
        if (!tmpGroup) {
            setChangingGroup(false)
            return
        }
        setTmpGroup(tmpGroup.trim().toUpperCase())
        setSearchPatams({g: tmpGroup})
        setGroup(tmpGroup)

        // Сохраняем в localstorage
        localStorage.setItem('publicScheduleGroup', tmpGroup)
        setChangingGroup(false)
    }


    return (
        <div className={cn('BG')}>
            <div className={st.content}>
                <CardTemplate>
                    <div className={st.header}>
                        <Link to={'/'} className={st.menuLogo}>
                            <h3>MBC Diary</h3>
                            <small>На главную</small>
                        </Link>
                        <div className={st.title}>
                            Расписание группы
                            {
                                changingGroup ?
                                    <>
                                        <Input
                                            defaultValue={group}
                                            className={st.input}
                                            defaultvalue={group}
                                            onChange={
                                                (e: { target: { value: React.SetStateAction<string>; }; }) => {
                                                    if (typeof e.target.value === "string") {
                                                        setTmpGroup(e.target.value.trim().toUpperCase())
                                                    }
                                                }
                                            }
                                        />
                                        <CheckCircleIcon onClick={setNewGroup} className={st.icon}/>
                                    </>
                                    :
                                    <>
                                        <div>
                                            {group}
                                        </div>
                                        <div>
                                            <PencilSquareIcon onClick={() => setChangingGroup(true)}
                                                              className={st.icon}/>
                                        </div>
                                    </>
                            }
                        </div>

                    </div>
                </CardTemplate>
                {
                    isLoaded ?

                        <Schedule group={group} university={null}/>
                        :
                        <div className={st.loading}>
                            <div className={st.loadingText}>Загрузка...</div>
                        </div>

                }
            </div>
        </div>
    );
};

export default GuestSchedule;