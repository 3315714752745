import React from 'react';
import classes from './Informatics9.module.css';
import {useForm} from "react-hook-form";
import Button from "../../UI/buttons/Button";
// import InputPrimary from "../UI/inputs/InputPrimary";
// import st from "../../pages/Auth/Login/OldLogin.module.css";

const Informatics9 = () => {

    // Динамическая переменная таблицы
    const [table, setTable] = React.useState([]);
    const [tableColor, setTableColor] = React.useState([]);


    let randomColors = ['#ED0CD2', '#0C21F7', '#17E0B4', '#85F70C', '#F0BA32',

        '#F04142', '#836EFA', '#16E0B5', '#CDF70C', '#F09432',

        '#66ff00', '#8400ff', '#22ff00', '#d900ff', '#0044ff',

        '#ff0004', '#0037ff', '#0066ff', '#ff0048', '#ff007b',];

    // Перемешать массив
    const shuffle = (array) => {
        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;

    }

    shuffle(randomColors);


    // Данные формы
    const {register, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            var1: "", var2: "", var3: "", var4: "",
        }, mode: "onBlur"
    })


    const onSubmit = async (data) => {
        // Заполнение таблицы
        let table = [];
        for (let i = 0; i < 16; i++) {
            table.push([
                i,
                Math.floor(i / 8),
                Math.floor(i % 8 / 4),
                Math.floor(i % 4 / 2),
                i % 2,
                hexToBin(data.var1)[i],
                hexToBin(data.var2)[i],
                hexToBin(data.var3)[i],
                hexToBin(data.var4)[i],
                binToDec(hexToBin(data.var1)[i] + hexToBin(data.var2)[i] + hexToBin(data.var3)[i] + hexToBin(data.var4)[i]),])
        }

        let tableColor = [];
        for (let i = 0; i < 16; i++) {
            tableColor.push('');
        }


        for (let i = 0; i < 16; i++) {
            for (let j = 0; j < 16; j++) {
                if (table[i][9] === table[j][9] && i !== j && tableColor[j] === '') {
                    tableColor[i] = randomColors[i];
                    tableColor[j] = randomColors[i];
                }
            }
        }

        setTableColor(tableColor);
        setTable(table);
    }


    // Преобразование 16-ичной строки в 2-ичное число и разбитое на массив
    const hexToBin = (hex) => {
        let bin = parseInt(hex, 16).toString(2)
        let arr = bin.split("")
        // Дополняем нулями до 16 бит
        while (arr.length < 16) {
            arr.unshift("0")
        }
        return arr
    }

    const binToDec = (bin) => {
        return parseInt(bin, 2)
    }

    return (<div className={classes.mainContainer}>
        <div className={`${classes.dataBlock} glass-block-200`}>
            <h1 className={classes.title}>Введите переменные <small>Практическая&nbsp;9</small></h1>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.inputs}>
                <label className="glass-input">
                    <span>Переменная 1</span>
                    <input maxLength={4} type={"text"} id={"var1"}
                           {...register("var1", {
                               required: "Введите переменную", maxLength: {
                                   value: 4, message: "Длина переменной должна быть 4 символа"
                               }, minLength: {
                                   value: 4, message: "Длина переменной должна быть 4 символа"
                               }, pattern: {
                                   value: /^[a-fA-F0-9]{4}$/, message: "Допустимы только 16-и ричное числа"
                               }
                           })}/>
                    <div>
                        {errors?.var1 && <p>{errors?.var1?.message}</p>}
                    </div>
                </label>
                <label className="glass-input">
                    <span>Переменная 2</span>
                    <input maxLength={4} type={"text"} id={"var2"}
                           {...register("var2", {
                               required: "Введите переменную", maxLength: {
                                   value: 4, message: "Длина переменной должна быть 4 символа"
                               }, minLength: {
                                   value: 4, message: "Длина переменной должна быть 4 символа"
                               }, pattern: {
                                   value: /^[a-fA-F0-9]{4}$/, message: "Допустимы только 16-и ричное числа"
                               }
                           })}/>
                    <div>
                        {errors?.var2 && <p>{errors?.var2?.message}</p>}
                    </div>
                </label>
                <label className="glass-input">
                    <span>Переменная 3</span>
                    <input maxLength={4} type={"text"} id={"var3"}
                           {...register("var3", {
                               required: "Введите переменную", maxLength: {
                                   value: 4, message: "Длина переменной должна быть 4 символа"
                               }, minLength: {
                                   value: 4, message: "Длина переменной должна быть 4 символа"
                               }, pattern: {
                                   value: /^[a-fA-F0-9]{4}$/, message: "Допустимы только 16-и ричное числа"
                               }
                           })}/>
                    <div>
                        {errors?.var3 && <p>{errors?.var3?.message}</p>}
                    </div>
                </label>
                <label className="glass-input">
                    <span>Переменная 4</span>
                    <input maxLength={4} type={"text"} id={"var4"}
                           {...register("var4", {
                               required: "Введите переменную", maxLength: {
                                   value: 4, message: "Длина переменной должна быть 4 символа"
                               }, minLength: {
                                   value: 4, message: "Длина переменной должна быть 4 символа"
                               }, pattern: {
                                   value: /^[a-fA-F0-9]{4}$/, message: "Допустимы только 16-и ричное числа"
                               }
                           })}/>
                    <div>
                        {errors?.var4 && <p>{errors?.var4?.message}</p>}
                    </div>
                </label>
                <div className={classes.buttons}>
                    <Button outline={"submit"}>Построить</Button>
                </div>
            </form>
        </div>
        <div className={`${classes.tableBlock} glass-block-200`}>
            <table className={classes.table}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>a</th>
                    <th>b</th>
                    <th>c</th>
                    <th>d</th>
                    <th>f1</th>
                    <th>f2</th>
                    <th>f3</th>
                    <th>f4</th>
                    <th>n</th>
                </tr>
                </thead>
                <tbody>
                {table.map((item, index) => <tr key={index}>
                    {item.map((i, index2) => // Если это первая ячейка, то выводим номер строки
                            index2 >= 5 ? <td style={{backgroundColor: tableColor[index]}} key={index2}>{i}</td> :
                                <td key={index2}>{i}</td>
                        // <td key={index2}>{i}</td>
                    )}
                </tr>)}

                </tbody>
            </table>
        </div>
    </div>);
};

export default Informatics9;