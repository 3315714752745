import React, {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import st from '../modals/Modals.module.css'

// TODO: Проверить типы
type Props = {
    open: any,
    setOpen: any,
    children: React.ReactNode,
};
const ModalTemplate = (props: Props) => {
    const {open, setOpen, children} = props

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className={st.dialog} initialFocus={cancelButtonRef}
                    onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter={st.bgEnter}
                    enterFrom={st.bgEnterFrom}
                    enterTo={st.bgEnterTo}
                    leave={st.bgLeave}
                    leaveFrom={st.bgLeaveFrom}
                    leaveTo={st.bgLeaveTo}
                >
                    <div className={st.dialogBG}/>
                </Transition.Child>

                <div className={st.dialogContainer}>
                    <div className={st.dialogContent}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {children}
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}



export default ModalTemplate;