import React from 'react';
import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";

const PublicHome = ({children}) => {
    return (
        <div className='BG'>
            <Header/>
            {children}
            <Footer/>
        </div>
    );
};

export default PublicHome;