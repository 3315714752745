import React from 'react';
import CardTemplate from "../../../UI/bases/CardTemplate";
import st from "./ScheduleLesson.module.css";
import Base from "../../../UI/bases/Base";
import cn from "classnames";
import {Tooltip} from "@mui/material";

type Props = {
    number: number,
    lesson: any[],
    lessonsTime: any[],
    showEmptyLessons: boolean,
    week: number
};

const ScheduleLesson = (
    {number, lesson, lessonsTime, showEmptyLessons, week}: Props
) => {
    // Обрезка времени 12:40 – 14:10 -> ['12:40', '14:10']
    const parseTime = (time: string) => {
        return time.split(' – ')
    }

    // Перебираем занятия, если

    lesson = lesson.filter((lesson: any) => lesson.weeks === null || lesson.weeks.includes(week))

    if (lesson.length === 0 && !showEmptyLessons) return null

    return (
        <CardTemplate className={st.lesson}>
            <div className={st.number}>
                {parseTime(lessonsTime[number]).map((time, index) => <p key={index}>{time}</p>)}
            </div>
            <div className={st.somethingLessons}>
                {
                    lesson.map((lesson, index) => {

                        return (
                            <div key={index} className={st.miniLesson}>
                                <div className={st.name}>
                                    <p>{lesson.name}</p>
                                </div>
                                <div className={st.teacher}>
                                    {
                                        lesson.tutor ?
                                            <p>{lesson.tutor}</p>
                                            : '-'
                                    }
                                </div>
                                {
                                    index === 0 ?
                                        <Base className={cn(st.room, {
                                            [st.lessonTypeLecture]: lesson.type?.toLowerCase() === 'лк',
                                            [st.lessonTypePractice]: lesson.type?.toLowerCase() === 'пр',
                                            [st.lessonTypeLab]: lesson.type?.toLowerCase() === 'лаб',
                                        })}>
                                            {
                                                lesson?.place?.length > 20 ?
                                                    <Tooltip title={lesson.place}>
                                                        <div
                                                            className={'inline cursor-pointer'}>{lesson.place.slice(0, 15)}...
                                                        </div>
                                                    </Tooltip>
                                                    :
                                                    <>
                                                        {lesson.place}
                                                    </>
                                            }
                                            {
                                                lesson.type ? ' | ' + lesson.type : ''
                                            }
                                        </Base>
                                        :
                                        <Base className={cn(st.room, st.room2)}>
                                            {lesson.place}
                                        </Base>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </CardTemplate>
    );
};

export default ScheduleLesson;