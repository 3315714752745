import React from 'react';
import CardTemplate from "../../bases/CardTemplate";
import st from './Badge.module.css';

type Props = {
    title?: string | undefined | null;
    value?: string | null;
    children?: React.ReactNode | React.ReactNode[] | string | number;
}

const Badge = ({title, value, children}: Props) => {
    return (
        <div className={st.badge}>
            {
                title && <h3 className={st.title}>{title}</h3>
            }
            <CardTemplate className={st.value}>
                {title && <span className={st.value}>{value}</span>}
                {!title && children}
            </CardTemplate>
        </div>
    );
};

export default Badge;