import React from 'react';
import classes from "./QueueUserCard.module.css";
import Button from "../../../../../UI/buttons/Button";

const QueueUserCard = ({user, index, swapAction, current, canSwitch}) => {
    return (
        <tr className={classes.mainContainer}>
            <td className={classes.numberContainer}>
                <div className={classes.number}>
                    {index + 1}
                </div>
            </td>
            <td>
                <div className={classes.userInfo}>
                    <img src={user.avatar ? user.avatar : 'https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg'}
                         className={classes.avatar}
                         alt={'avatar'}/>
                    <div className={classes.info}>
                        <div className={classes.name}>{user.lastName} {user.firstName}</div>
                        <div className={classes.role}>
                            {user.username ? 'aka ' + user.username : ''}
                        </div>
                    </div>
                </div>
            </td>
            {/*<td>*/}
            {/*    /!*info*!/*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    /!*info2*!/*/}
            {/*</td>*/}

            <td className={'w-full'}>
                {
                    !current && canSwitch &&
                    <div className={classes.btnBlock}>
                        <Button
                            onClick={() => swapAction(index)}
                            widthFull={false}
                            size={"sm"}
                            color={"blue"}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth={1.5}
                                 stroke="currentColor" className="w-5 h-5 md:mr-2 md:-ml-1">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"/>
                            </svg>
                            <span className="hidden md:block">
                            Поменяться
                        </span>
                        </Button>
                    </div>
                }
            </td>

            {/*<td className="py-4 px-6">*/}
            {/*    <div className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit user</div>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}*/}
            {/*         stroke="currentColor" className="w-6 h-6">*/}
            {/*        <path strokeLinecap="round" strokeLinejoin="round"*/}
            {/*              d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"/>*/}
            {/*    </svg>*/}
            {/*</td>*/}
        </tr>
    );
};

export default QueueUserCard;