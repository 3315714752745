import React, {useContext, useEffect, useRef, useState} from 'react'
import {Dialog} from '@headlessui/react'
import st from './Modals.module.css'
import Button from "../buttons/Button";
import PropTypes from "prop-types";
import ModalTemplate from "../bases/ModalTemplate";
// import {useForm} from "react-hook-form";
// import Input from "../inputs/Input";
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";
import CopyInput from "../../utils/CopyInput/CopyInput";


const CreateInviteLinkModal = (props) => {
    const {t} = useTranslation();

    const {store} = useContext(Context);

    const {open, setOpen, group} = props

    const cancelButtonRef = useRef(null)

    const [link, setLink] = useState({code: "123456"});


    // Получаем данные из store.getInviteLink
    useEffect(() => {
        const asyncFunc = async () => {
            setLink(await store.getInvite(group.id));
            // console.log(link)
        }
        asyncFunc();
    }, [group, store])


    /**
     * Отправка запроса на создание ссылки
     */
    const createInviteLink = async () => {
        const response = await store.createInvite(group.id, {usage: 30});
        setLink(response);
    }

    /**
     * Отправка запроса на удаление ссылки
     */
    const deleteInviteLink = async () => {
        const response = await store.deactivateInviteByGroupId(group.id);
        setLink(response);
    }


    // const {register, handleSubmit, formState: {errors}} = useForm({
    //     defaultValues: {
    //         usage: 30,
    //     }
    // });

    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <div className={st.mainContent}>
                    <div className={st.mainContentContainer}>
                        {/*<div*/}
                        {/*    className={st.iconContainer}>*/}
                        {/*    <ArrowPathRoundedSquareIcon className={st.icon}*/}
                        {/*                             aria-hidden="true"/>*/}
                        {/*</div>*/}
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                            <Dialog.Title as="h3"
                                          className={st.header}>
                                {t('groupPage.inviteLink.title')}
                            </Dialog.Title>
                            <div className={st.body}>
                                <p>
                                    Ссылка имеет 30 использований, по их истечению, она будет автоматически отозвана. Вы
                                    так же можете отозвать ссылку раньше её исчерпания.
                                </p>
                                <div className="flex flex-col gap-4 mt-2 ">
                                    {
                                        link?.code &&
                                        <CopyInput value={process.env.REACT_APP_URL + '/d/invites/' + link?.code}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={st.btnBlock}>
                    {
                        link?.code ?
                            <Button
                                onClick={deleteInviteLink}
                                width={'sm-full'}
                                color={"red"}
                            >
                                {t('recall')}
                            </Button>
                            :
                            <Button
                                onClick={createInviteLink}
                                width={'sm-full'}
                                color={"green"}
                            >
                                {t('generate')}
                            </Button>
                    }
                    <Button
                        color={"gray"}
                        outline={true}
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                        width={'sm-full'}
                    >
                        {t('cancel')}
                    </Button>
                </div>
            </Dialog.Panel>
        </ModalTemplate>
    )
}

CreateInviteLinkModal.defaultProps = {
    open: false,
    setOpen: null,
    group: null,
}

CreateInviteLinkModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    group: PropTypes.object,
}

export default CreateInviteLinkModal;