import React, {useContext} from 'react';
import Button from "../../../UI/buttons/Button";
import CardTemplate from "../../../UI/bases/CardTemplate";
import cl from "./CurrentGroupCard.module.css"
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import {Link} from "react-router-dom";
import LightBadge from "../../../UI/badge/LightBadge/LightBadge";
import {useTranslation} from "react-i18next";

const CurrentGroupCard = () => {
    const {t} = useTranslation();

    const {store} = useContext(Context);

    return (
        <>
            {
                store.currentGroup.id &&
                <CardTemplate className={cl.currentGroupCard}>
                    <div className={cl.generalBlock}>
                        <div className={cl.groupBlock}>
                            <div className={cl.title}>{t('groupPage.current')}</div>
                            <div className={cl.miniGeneralBlock}>
                                {
                                    store.currentGroup.university &&
                                    <LightBadge title={t('university')} value={store.currentGroup.university.shortName}/>
                                }
                                <LightBadge title={t('group')} value={store.currentGroup.name}/>
                                <LightBadge title={t('code')} value={store.currentGroup.alias}/>
                                {
                                    store.currentGroup.description &&
                                    <LightBadge title={t('description')} value={store.currentGroup.description}/>
                                }
                            </div>
                        </div>
                        <div className={cl.buttonBlock}>
                            <Link to={`/d/groups/${store.currentGroup.alias}`}>
                                <Button
                                    color={"gray"}
                                    outline={true}
                                    title={t('detail')}
                                    width={"sm-full"}/>
                            </Link>
                            {/*<Button color={"red"} width={"sm-full"}>Покинуть группу</Button>*/}
                        </div>
                    </div>
                </CardTemplate>
            }
        </>
    );
};

export default observer(CurrentGroupCard);