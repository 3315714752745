import React, {useEffect} from 'react';
import Header from '../../components/UI/Header/Header';
import classes from './Home.module.css';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Footer from "../../components/UI/Footer/Footer";
import {useTranslation} from "react-i18next";
// import Footer from "./UI/Footer";


const Home = () => {
    const {t} = useTranslation();

    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (search.get('utm_source')) {
            if (search.get('utm_source') === 'homescreen') {
                setSearch('')
                navigate('/dashboard');
            }
        }
    }, [search, setSearch, navigate]);

    return (
        <div className={classes.pageContainer}>
            {/* Задниый фон */}
            <div className="h-0">
                <div className={classes.stars}></div>
                <div className={classes.stars2}></div>
                <div className={classes.stars3}></div>
            </div>

            <Header/>
            <div className={classes.contentContainer}>
                <div className={classes.content}>
                    <div className={classes.topContent}>
                        <p className={classes.topTitle}>{t('mainPage.subTitle')}</p>
                        <h1 className={classes.topHeader}>{t('mainPage.title')}</h1>
                        <p className={classes.topMainText}>{t('mainPage.description')}</p>
                        <Link to='/login' className={classes.topBtn}>
                            {t('start')}
                        </Link>
                    </div>
                    <div className={classes.topImgDiv}>
                        <img className={classes.topImg} src="https://www.tailwindtoolbox.com/templates/hero.png"
                             alt=""/>
                    </div>
                </div>
            </div>

            <section className={classes.sectionBlock}>
                <div className={classes.sectionContainer}>
                    <h1 className={classes.sectionTitle}>
                        {t('services')}
                    </h1>
                    <div className={classes.sectionContent}>
                        <div className={classes.sectionCardContainer}>

                            <div className={classes.sectionCard}>
                                <span></span>
                                <div className={classes.sectionCardContent}>
                                    <div className={classes.sectionCardTitle}>
                                        {t('schedule')}
                                    </div>
                                    <div className={classes.sectionCardText}>
                                        {t('mainPage.scheduleText')}
                                    </div>
                                    <a href={() => false} className={classes.sectionCardBtn}>
                                        {t('more')}
                                    </a>
                                </div>
                            </div>
                            <div className={classes.sectionCard}>
                                <span></span>
                                <div className={classes.sectionCardContent}>
                                    <div className={classes.sectionCardTitle}>
                                        {t('diary')}
                                    </div>
                                    <div className={classes.sectionCardText}>
                                        {t('mainPage.diaryText')}
                                    </div>
                                    <a href={() => false} className={classes.sectionCardBtn}>
                                        {t('more')}
                                    </a>
                                </div>
                            </div>

                            <div className={classes.sectionCard}>
                                <span></span>
                                <div className={classes.sectionCardContent}>
                                    <div className={classes.sectionCardTitle}>
                                        {t('queue')}
                                    </div>
                                    <div className={classes.sectionCardText}>
                                        {t('mainPage.queueText')}
                                    </div>
                                    <a href={() => false} className={classes.sectionCardBtn}>
                                        {t('more')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
};

export default Home;