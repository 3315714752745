import React from 'react';
import cn from "classnames";

type Props = {
    hovered?: boolean
    children: React.ReactNode
    className?: any
    onClick?: any
}

const Base = ({children, className, hovered, onClick, ...other}: Props) => {
    return (
        <div
            onClick={onClick}
            className={cn(
            'template__base',
            {'template__hovered': hovered},
            className
        )} {...other}>
            {children}
        </div>
    );
};

export default Base;