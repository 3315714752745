import React from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import st from './HelloPage.module.css';
import {Link} from "react-router-dom";
import Button from "../../../../components/UI/buttons/Button";
import {useTranslation} from "react-i18next";

const HelloPage = () => {
    const {t} = useTranslation();

    return (
        <Dashboard title={t('helloPage.mainTitle')}>
            <CardTemplate className={st.pageContainer}>
                <h1 className={st.title}>{t('helloPage.title')}</h1>
                <div className={st.block}>
                    <h2 className={st.subtitle}>{t('helloPage.preview')}</h2>
                    <p className={st.text}>
                        {t('helloPage.description')}
                    </p>
                </div>

                <Link to={'/dashboard'}>
                    <Button
                        title={t('helloPage.enter')}/>
                </Link>
            </CardTemplate>
        </Dashboard>
    );
};

export default HelloPage;