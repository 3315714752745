import React from 'react';
import classes from './TeamCard.module.css'
import cn from 'classnames';
import Base from "../../UI/bases/Base";

const TeamCard = ({member}) => {
    return (
        <div className={member.priority ? classes.memberPriority : classes.member}>
            <Base className={`${cn({
                [classes.cardContainerPriority]: member.priority,
                [classes.cardContainer]: !member.priority,
            })} glass-300`}>
                <img src={require(`../../../media/images/${member.photo}`)} alt="logo" className={classes.cardImg}/>
                <div className={classes.cardContent}>
                    <p className={classes.cardName}>{member.name}</p>
                    <p className={classes.cardSpecialities}>
                        {member.speciality.join(' / ')}</p>
                </div>
            </Base>
        </div>
    );
};

export default TeamCard;

