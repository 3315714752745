import React, {ReactNode} from 'react';
import CardTemplate from "../../UI/bases/CardTemplate";
import StandardTitle from "../../UI/titles/StandardTitle";
import st from "./WidgetPattern.module.css"

type Props = {
    title: string
    children: any
    actionBlock?: ReactNode
}

const WidgetPattern = ({children, title, actionBlock}: Props) => {
    return (
        <CardTemplate>
            <div className={st.titleContainer}>
                <StandardTitle title={title}/>
                {actionBlock}
            </div>
            {children}
        </CardTemplate>
    );
};

export default WidgetPattern;