import React, {useContext, useState} from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import {Context} from "../../../../index";
import {useParams} from "react-router-dom";
import st from './GroupAssignments.module.css';
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import {useTranslation} from "react-i18next";
import StandardTitle from "../../../../components/UI/titles/StandardTitle";
import ConfirmModal from "../../../../components/UI/modals/ConfirmModal.jsx";
import Button from "../../../../components/UI/buttons/Button";
import AssignmentsList from "../../../../components/dashboard/assigments/AssignmentsList/AssignmentsList";

const GroupAssignments = () => {
    const {group} = useParams();

    const {store} = useContext(Context);
    const {t} = useTranslation();
    const [activateModal, setActivateModal] = useState(false);

    const activate = () => {
        store.assignment.activateForGroup(group).then(
            () => setActivateModal(false)
        )
    }

    if (store.havePermission('GROUP_OWNER', true, group) &&
        !store.havePermission('GROUP_ASSIGNMENT_MANAGEMENT', true, group)) {
        return (
            <Dashboard title={t('menu.assignments')}>
                <div className={st.activateContainer}>
                    <ConfirmModal
                        open={activateModal}
                        setOpen={setActivateModal}
                        header={t('assignments.activate.modal.header')}
                        body={t('assignments.activate.modal.body')}
                        confirmText={t('assignments.activate.modal.confirm')}
                        action={activate}
                    />

                    <CardTemplate className={st.card}>
                        <StandardTitle title={t('assignments.activate.title')}/>
                        <div className={st.text}>
                            {t('assignments.activate.text')}
                        </div>

                        <Button onClick={() => setActivateModal(true)}
                                className={st.activateButton}
                                color={'green'}
                                size={'base'}
                                outline={false}
                                title={''}
                                width={'full'}
                                isIcon={false}>
                            {t('assignments.activate.button')}
                        </Button>
                    </CardTemplate>

                </div>
            </Dashboard>
        );
    }

    return (
        <Dashboard title={t('menu.assignments')}>
            <AssignmentsList group={group}/>
        </Dashboard>
    );
};

export default GroupAssignments;