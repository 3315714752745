import React, {useContext, useEffect, useState} from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {Context} from "../../../../index";
import {useParams} from "react-router-dom";
import Base from "../../../../components/UI/bases/Base";
import st from "./Profile.module.css"
import Button from "../../../../components/UI/buttons/Button";
import {Tab, Tabs} from "../../../../components/UI/tabs/Tabs";
import {EllipsisVerticalIcon} from "@heroicons/react/24/outline";
import StaticProfileInfo from "../../../../components/dashboard/user/StaticInfo/StaticProfileInfo";
import ChooseTheme from "../../../../components/dashboard/user/ChooseTheme/ChooseTheme";
import ChooseLanguage from "../../../../components/dashboard/user/ChooseLanguage/ChooseLanguage";
import ChangePassword from "../../../../components/dashboard/user/ChangePassword/ChangePassword";
import RecentSessions from "../../../../components/dashboard/user/RecentSessions/RecentSessions";
import UserGroupList from "../../../../components/dashboard/user/UserGroupList/UserGroupList";
// import UserActivityTimeline from "../../../../components/dashboard/user/UserActivityTimeline/UserActivityTimeline";
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import StandardTitle from "../../../../components/UI/titles/StandardTitle";
import {IUserProfileInfoResponse} from "../../../../models/user/IUserProfileInfoResponse";

const Profile = () => {
    const {store}: any = useContext(Context);
    const {t} = useTranslation();

    let {username} = useParams();

    const [user, setUser] = useState({} as IUserProfileInfoResponse);

    const [isSelf, setIsSelf] = useState(false)

    const [userNotFound, setUserNotFound] = useState(false)

    useEffect(() => {

        const getUser = async () => {
            if (username === undefined) return
            const response = await store.user.getProfileInfo(username)
            if (response === undefined) {
                setUserNotFound(true);
            }
            setUser(response)
        }

        if (store.currentUser.username === username) {
            setIsSelf(true)
        }

        getUser()

    }, [store.currentUser, username, store.user])

    if (userNotFound) {
        return (
            <Dashboard title={username}>
                <CardTemplate className={'flex flex-row w-full'}>
                    <StandardTitle title={t('error.userNotFound')}/>
                </CardTemplate>
            </Dashboard>
        )
    }

    return (
        <Dashboard title={username}>
            <Base className={st.header}>
                <div className={st.imgContainer}>
                    <div className={st.box}>
                        <div className={st.boxInner}></div>
                    </div>
                    {/*<img*/}
                    {/*    src={'https://catherineasquithgallery.com/uploads/posts/2021-02/thumbs/1613311113_63-p-sinii-3d-fon-81.jpg'}*/}
                    {/*    alt={'bg-gradient'}*/}
                    {/*/>*/}
                </div>
                <div className={st.headerData}>
                    <Base className={st.avatar}>
                        <img src={user?.avatar ? user.avatar : ''} alt={'avatar'}/>
                    </Base>
                    <div className={st.headerMobileData}>
                        <div className={st.headerName}>
                            {user.lastName} {user.firstName}
                        </div>
                        <Button
                            type={'button'}
                            title={''}
                            className={st.headerButton}
                            color={'gray'}
                            size={'base'}
                            outline={false}
                            width={'auto'}
                            isIcon={true}
                            children={<EllipsisVerticalIcon className={'w-6 h-6'}/>} disabled={false}/>
                    </div>
                </div>
            </Base>

            <div className={st.content}>
                <div className={st.staticInfo}>
                    <StaticProfileInfo user={user}/>
                </div>
                <div className={st.barContainer}>
                    {
                        isSelf ?
                            <Tabs>
                                <Tab title={t('account')}>
                                    <UserGroupList groups={user.groups}/>
                                    {/*<UserActivityTimeline/>*/}
                                </Tab>
                                <Tab title={t('security')}>
                                    <ChangePassword/>
                                    <RecentSessions/>
                                </Tab>
                                <Tab title={t('appearance')}>
                                    <ChooseTheme/>
                                    <ChooseLanguage/>
                                </Tab>
                                {/*<Tab title={t('notifications')}>*/}
                                {/*    <CardTemplate>*/}
                                {/*        Уведомления*/}
                                {/*    </CardTemplate>*/}
                                {/*</Tab>*/}
                                {/*<Tab title={t('connections')}>*/}
                                {/*    <CardTemplate>*/}
                                {/*        Привязки*/}
                                {/*    </CardTemplate>*/}
                                {/*</Tab>*/}

                            </Tabs> :
                            <UserGroupList groups={user.groups}/>
                    }
                </div>
            </div>
        </Dashboard>
    );
};

export default observer(Profile);