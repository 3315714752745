import React from 'react';
import CardTemplate from "../../../UI/bases/CardTemplate";
import StandardTitle from "../../../UI/titles/StandardTitle";
import {IUserAdminProfileResponse} from "../../../../models/user/IUserAdminProfileResponse";
import st from "./UserBansInfo.module.css"

type UserBansInfoProps = {
    user: IUserAdminProfileResponse;
}

const UserBansInfo = ({user}: UserBansInfoProps) => {
    return (
        <CardTemplate className={st.content}>
            <StandardTitle title={'Блокировки пользователя'}/>
            {
                user.status === 'BANNED' ?
                    <div className={st.banned}>
                        Пользователь заблокирован
                    </div>
                    :
                    <div className={st.active}>
                        Пользователь не заблокирован
                    </div>
            }

        </CardTemplate>
    );
};

export default UserBansInfo;