import React, {useContext} from 'react';
import QueueUserCard from "./cards/QueueUserCard/QueueUserCard";
import UsersListTemplate from "../../../UI/lists/UsersListTemplate";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";

const QueueUsersList = ({users, swapAction, canSwitch}) => {
    const {t} = useTranslation();
    const {store} = useContext(Context);
    return (
        <UsersListTemplate title={t('participants')}>
            {
                !users.length ?
                    <div className={'text-center text-gray-500'}>
                        {t('noParticipants')}
                    </div>
                    :
                    users?.map((user, index) =>
                        <QueueUserCard canSwitch={canSwitch} current={store.currentUser.id === user.id} key={index} user={user} index={index} swapAction={swapAction}/>
                    )
            }
        </UsersListTemplate>
    );
};

export default observer(QueueUsersList);