import Store from "../store";
import {makeAutoObservable} from "mobx";
import $api from "../../http";
import {IGroup} from "../../models/IGroup";

export default class AssignmentStore {
    private rootStore: Store;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    /**
     * Выход из группы
     */
    async leaveGroup(groupId: string) {
        try {
            if (this.rootStore.currentGroup.id === groupId) {
                this.rootStore.currentGroup = {} as IGroup;
            }
            await $api.delete(`/groups/${groupId}/users`);
            this.rootStore.utils.notify('Вы вышли из группы', 'success');
            await this.rootStore.getCredentials();
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    /**
     * Активация заданий для группы
     * @param groupAlias
     */
    async activateForGroup(groupAlias: string | undefined) {
        // Получаем id группы по её alias
        try {
            if (!groupAlias) {
                this.rootStore.utils.notify('Не указана группа', 'error');
                return;
            }
            const group = this.rootStore.getUserGroupByAlias(groupAlias);
            if (!group) {
                this.rootStore.utils.notify('Группа не найдена', 'error');
                return;
            }
            await $api.post(`/assignments/activate/groups/${group.id}`);
            this.rootStore.utils.notify('Задания активированы', 'success');
            await this.rootStore.getCredentials();
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async create(groupId: any, data: any) {
        try {
            const json = JSON.stringify(data);
            if (groupId)
                await $api.post(`/assignments/groups/${groupId}`, json);
            else
                await $api.post(`/assignments`, json);
            this.rootStore.utils.notify('Задание создано', 'success');
            return true;
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
        return false;

    }

    async getForGroup<AssignmentResponse>(group: string | undefined, showDone: boolean) {
        try {
            if (!group) {
                this.rootStore.utils.notify('Не указана группа', 'error');
                return;
            }
            let g = this.rootStore.getUserGroupByAlias(group);
            if (!g) {
                this.rootStore.utils.notify('Группа не найдена', 'error');
                return;
            }
            if (showDone) {
                const response = await $api.get<AssignmentResponse>(`/assignments/groups/${g.id}`);
                return response.data;
            } else {
                const response = await $api.get<AssignmentResponse>(`/assignments/groups/${g.id}/uncomplete`);
                return response.data;
            }
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async complete(id: string) {
        try {
            if (!await $api.post(`/assignments/${id}/complete`)) {
                this.rootStore.utils.notify('Что-то пошло не так', 'error');
            }
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async uncomplete(id: string) {
        try {
            if (!await $api.post(`/assignments/${id}/uncomplete`)) {
                this.rootStore.utils.notify('Что-то пошло не так', 'error');
            }
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async getById(assignmentId: string) {
        try {
            const response = await $api.get(`/assignments/${assignmentId}`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async update(assignmentId: string | undefined, data: any) {
        try {
            const json = JSON.stringify(data);
            await $api.put(`/assignments/${assignmentId}`, json);
            this.rootStore.utils.notify('Задание обновлено', 'success');
            return true;
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async getForUser<T>(showCompleted: boolean) {
        try {
            if (showCompleted) {
                const response = await $api.get<T>('/assignments');
                return response.data;
            } else {
                const response = await $api.get<T>('/assignments/uncomplete');
                return response.data;
            }
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }

    async deletePersonal(assignmentId: string | undefined) {
        try {
            await $api.delete(`/assignments/${assignmentId}`);
            this.rootStore.utils.notify('Задание удалено', 'success');
            return true;
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }
    async deleteGroup(assignmentId: string | undefined, groupId: string | undefined) {
        try {
            await $api.delete(`/assignments/groups/${groupId}/${assignmentId}`);
            this.rootStore.utils.notify('Задание удалено', 'success');
            return true;
        } catch (e) {
            this.rootStore.errorHandler(e)
        }
    }
}