import {Dialog} from '@headlessui/react'
import st from './Modals.module.css'
import Button from "../buttons/Button";
import PropTypes from "prop-types";
import ModalTemplate from "../bases/ModalTemplate";
import Input from "../inputs/Input";
import {useForm} from "react-hook-form";
import Checkbox from "../inputs/Checkbox";
import CardLightTemplate from "../bases/CardLightTemplate";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import DateTimeService from "../../../services/DateTimeService";


const EditQueueModal = (props) => {
    const {t} = useTranslation();

    const {open, setOpen, queue, action} = props

    const {register, handleSubmit, watch, setValue, formState: {errors}} = useForm({
        defaultValues: {
            title: queue.title,
            openByTime: queue?.openTime?.length && queue.openTime.length > 0,
            openByTimeDate: queue?.openTime?.length > 0 && DateTimeService.convertBackDateToInput(queue?.openTime),
            closeByTime: queue?.closeTime?.length && queue.closeTime.length > 0,
            closeByTimeDate: queue?.closeTime?.length > 0 && DateTimeService.convertBackDateToInput(queue?.closeTime),

            canShuffle: queue.willBeShuffled === 'YES',
            canJoinAfterShuffle: queue.canJoinAfterShuffle,
        },
        mode: "onBlur"
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    // const clearForm = () => {
    //     reset({
    //         title: '',
    //         openByTime: false,
    //         openByTimeDate: '',
    //         closeByTime: false,
    //         closeByTimeDate: '',
    //
    //         canShuffle: true,
    //         canJoinAfterShuffle: false,
    //     });
    // }

    const onSubmit = async (data) => {
        try {
            setIsSubmitting(true);
            await action(data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={st.mainContent}>
                        <div className={st.mainContentContainer}>
                            <div className="h-full mt-3 w-full text-center sm:mt-0 sm:text-left">
                                <Dialog.Title as="h3"
                                              className={`${st.header} ${st.createGroupTitle}`}>
                                    {t('queueViewPage.createQueue.title')}
                                </Dialog.Title>
                                <div className={st.body}>
                                    <div className={st.createQueueBlock}>
                                        <Input
                                            title={t('subject')}
                                            name={"title"}
                                            register={register}
                                            errors={errors}
                                            validationSchema={{
                                                required: {
                                                    value: true,
                                                    message: t('valMessage.requiredField'),
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: t('valMessage.minSymbols3'),
                                                },
                                                maxLength: {
                                                    value: 60,
                                                    message: t('valMessage.maxSymbols60'),
                                                },
                                            }}
                                            required
                                        />

                                        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 hidden">
                                            <filedset className={st.fieldset}>
                                                <Checkbox
                                                    title={t('queueViewPage.open')}
                                                    name={"openByTime"}
                                                    register={register}
                                                    setValue={setValue}
                                                    checked={watch('openByTime')}
                                                />

                                                <Input
                                                    title={t('queueViewPage.openTime')}
                                                    name={"openByTimeDate"}
                                                    type={"datetime-local"}
                                                    errors={errors}
                                                    register={register}
                                                    disabled={!watch('openByTime')}
                                                    validationSchema={{
                                                        required: {
                                                            value: watch('openByTime'),
                                                            message: t('valMessage.requiredField'),
                                                        }
                                                    }}
                                                />
                                            </filedset>
                                            <filedset className={st.fieldset}>
                                                <Checkbox
                                                    title={t('queueViewPage.close')}
                                                    name={"closeByTime"}
                                                    register={register}
                                                    setValue={setValue}
                                                    checked={watch('closeByTime')}
                                                />

                                                <Input
                                                    title={t('queueViewPage.closeTime')}
                                                    name={"closeByTimeDate"}
                                                    type={"datetime-local"}
                                                    errors={errors}
                                                    register={register}
                                                    disabled={!watch('closeByTime')}
                                                    validationSchema={{
                                                        required: {
                                                            value: watch('closeByTime'),
                                                            message: t('valMessage.requiredField'),
                                                        }
                                                    }}
                                                />
                                            </filedset>

                                        </div>
                                        {
                                            queue.willBeShuffled !== 'SHUFFLED' &&
                                            <div className="grid grid-cols-1">

                                                <Checkbox
                                                    title={t('queueViewPage.createQueue.shuffle')}
                                                    name={"canShuffle"}
                                                    register={register}
                                                    setValue={setValue}
                                                    checked={watch('canShuffle')}
                                                />
                                                {/*<Checkbox*/}
                                                {/*    title={"Можно ли встать после перемешивания?"}*/}
                                                {/*    name={"canJoinAfterShuffle"}*/}
                                                {/*    register={register}*/}
                                                {/*    setValue={setValue}*/}
                                                {/*/>*/}
                                            </div>
                                        }

                                        <CardLightTemplate className={st.miniInfo}>
                                            <InformationCircleIcon className={"w-16 mr-2"}/>
                                            <p className={'text-sm'}>
                                                {t('queueViewPage.createQueue.information')}
                                            </p>
                                        </CardLightTemplate>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={st.btnBlock}>
                        <Button
                            color={"blue"}
                            width={"sm-full"}
                            disabled={isSubmitting}
                        >
                            {t('edit')}
                        </Button>
                        <Button
                            type={"button"}
                            color={"gray"}
                            outline={true}
                            onClick={() => setOpen(false)}
                            width={"sm-full"}
                        >
                            {t('cancel')}
                        </Button>
                    </div>
                </form>
            </Dialog.Panel>
        </ModalTemplate>
    )
}

EditQueueModal.defaultProps = {
    open: false,
    setOpen: null,
    queue: null,
    action: null,
}

EditQueueModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    queue: PropTypes.object,
    action: PropTypes.func,
}


export default EditQueueModal;