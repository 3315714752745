import {Dialog} from '@headlessui/react'
import st from './Modals.module.css'
import Button from "../buttons/Button";
import PropTypes from "prop-types";
import ModalTemplate from "../bases/ModalTemplate";
import Input from "../inputs/Input";
import {useForm} from "react-hook-form";
import Checkbox from "../inputs/Checkbox";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {parseISO} from "date-fns";
import CardTemplate from "../bases/CardTemplate";


const CreateQueueModal = (props) => {
    const {t} = useTranslation();

    const {open, setOpen, action} = props

    /*
        У очереди есть:
        - Предмет
        - Открытие по времени (checkbox), если true, то появляется поле с датой и временем
        - Автоматическое перемешивание (checkbox), если true, то появляется поле с временем
        - Будет ли очередь перемешана? (checkbox)
        - Можно ли встать после перемешивания? (checkbox)
     */

    // useForm
    const {register, handleSubmit, watch, setValue, formState: {errors}, reset} = useForm({
        defaultValues: {
            subject: '',
            openByTime: false,
            openByTimeDate: '',
            closeByTime: false,
            closeByTimeDate: '',

            canShuffle: true,
            canJoinAfterShuffle: false,

        },
        mode: "onBlur"
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const clearForm = () => {
        reset({
            subject: '',
            openByTime: false,
            openByTimeDate: '',
            closeByTime: false,
            closeByTimeDate: '',

            canShuffle: true,
            canJoinAfterShuffle: false,
        });
    }

    const onSubmit = async (data) => {
        try {
            setIsSubmitting(true);

            if (data.openByTimeDate) {
                data.openByTimeDate = parseISO(data.openByTimeDate);
            }
            if (data.closeByTimeDate) {
                data.closeByTimeDate = parseISO(data.closeByTimeDate);
            }

            if (await action(data))
                clearForm();
        } catch (error) {
            console.log(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={st.mainContent}>
                        <div className={st.mainContentContainer}>
                            <div className="h-full mt-3 w-full text-center sm:mt-0 sm:text-left">
                                <Dialog.Title as="h3"
                                              className={`${st.header} ${st.createGroupTitle}`}>
                                    {t('queueViewPage.createQueue.title')}
                                </Dialog.Title>
                                <div className={st.body}>
                                    <div className={st.createQueueBlock}>
                                        <Input
                                            title={t('subject')}
                                            name={"subject"}
                                            register={register}
                                            errors={errors}
                                            validationSchema={{
                                                required: {
                                                    value: true,
                                                    message: t('valMessage.requiredField'),
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: t('valMessage.minSymbols3'),
                                                },
                                                maxLength: {
                                                    value: 60,
                                                    message: t('valMessage.maxSymbols60'),
                                                },
                                            }}
                                            required
                                        />

                                        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                                            <filedset className={st.fieldset}>
                                                <Checkbox
                                                    title={t('queueViewPage.open')}
                                                    name={"openByTime"}
                                                    register={register}
                                                    setValue={setValue}
                                                />

                                                <Input
                                                    title={t('queueViewPage.openTime')}
                                                    name={"openByTimeDate"}
                                                    type={"datetime-local"}
                                                    errors={errors}
                                                    register={register}
                                                    disabled={!watch('openByTime')}
                                                    validationSchema={{
                                                        required: {
                                                            value: watch('openByTime'),
                                                            message: t('valMessage.requiredField'),
                                                        }
                                                    }}
                                                />
                                            </filedset>
                                            <filedset className={st.fieldset}>
                                                <Checkbox
                                                    title={t('queueViewPage.close')}
                                                    name={"closeByTime"}
                                                    register={register}
                                                    setValue={setValue}
                                                    initialvalue={watch('closeByTime')}
                                                />

                                                <Input
                                                    title={t('queueViewPage.closeTime')}
                                                    name={"closeByTimeDate"}
                                                    type={"datetime-local"}
                                                    errors={errors}
                                                    register={register}
                                                    disabled={!watch('closeByTime')}
                                                    validationSchema={{
                                                        required: {
                                                            value: watch('closeByTime'),
                                                            message: t('valMessage.requiredField'),
                                                        }
                                                    }}
                                                />
                                            </filedset>

                                        </div>
                                        <div className="grid grid-cols-1">
                                            <Checkbox
                                                title={t('queueViewPage.createQueue.shuffle')}
                                                name={"canShuffle"}
                                                register={register}
                                                setValue={setValue}
                                                checked={watch('canShuffle')}
                                            />
                                            {/*<Checkbox*/}
                                            {/*    title={"Можно ли встать после перемешивания?"}*/}
                                            {/*    name={"canJoinAfterShuffle"}*/}
                                            {/*    register={register}*/}
                                            {/*    setValue={setValue}*/}
                                            {/*/>*/}
                                        </div>

                                        <CardTemplate className={st.miniInfo}>
                                            <InformationCircleIcon className={"w-16 mr-3"}/>
                                            <p>
                                                {t('queueViewPage.createQueue.information')}
                                            </p>
                                        </CardTemplate>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={st.btnBlock}>
                        <Button
                            color={"blue"}
                            width={"sm-full"}
                            disabled={isSubmitting}
                        >
                            {t('create')}
                        </Button>
                        <Button
                            color={"gray"}
                            outline={true}
                            onClick={() => setOpen(false)}
                            width={"sm-full"}
                        >
                            {t('cancel')}
                        </Button>
                    </div>
                </form>
            </Dialog.Panel>
        </ModalTemplate>
    )
}

CreateQueueModal.defaultProps = {
    open: false,
    setOpen: null,
    action: null,
}

CreateQueueModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    action: PropTypes.func,
}


export default CreateQueueModal;