import React from 'react';
import CardTemplate from "../../../../../UI/bases/CardTemplate";
import SmallTitle from "../../../../../UI/titles/SmallTitle";
import st from './QueueSwitchProposalWidgetCard.module.css'
import {Link} from "react-router-dom";
import cn from "classnames";
import {ArrowRightIcon} from "@heroicons/react/24/solid";
import {Tooltip} from "@mui/material";

type Props = {
    data: any,
    groupAlias: string
}

const QueueSwitchProposalWidgetCard = ({data, groupAlias}: Props) => {
    return (
        <Link
            to={`/g/${groupAlias}/queues/${data?.queue?.alias}`}>
            <CardTemplate className={st.card}>
                <div className={st.info}>
                    <SmallTitle title={data?.queue?.title}/>
                    <Tooltip title={<div className={'text-sm flex-nowrap gap-1 w-auto hidden md:flex'}>
                        <div className={st.smNumber}>
                            {data?.userToSwitchPosition + 1}
                        </div>
                        <ArrowRightIcon className={st.arrow}/>
                        <div className={st.smNumber}>
                            {data?.userPosition + 1}
                        </div>
                    </div>} placement={'top'} arrow>
                        <div className={cn(st.number, {
                            [st.movingUpNumber]: data?.userToSwitchPosition - data?.userPosition > 0,
                            [st.movingDownNumber]: data?.userToSwitchPosition - data?.userPosition < 0
                        })}>
                            {
                                data?.userToSwitchPosition - data?.userPosition > 0 ?
                                    `+${data?.userToSwitchPosition - data?.userPosition}` :
                                    data?.userToSwitchPosition - data?.userPosition
                            }
                        </div>
                    </Tooltip>

                </div>
                <div className={st.hr}/>
                <div className={st.info}>
                    <div className={st.mute}>
                        <div className={'text-sm'}>{data?.user?.firstName} {data?.user?.lastName}</div>
                    </div>
                    <div className={'text-sm flex flex-nowrap gap-1 w-auto md:hidden'}>
                        <div className={st.smNumber}>
                            {data?.userToSwitchPosition + 1}
                        </div>
                        <ArrowRightIcon className={st.arrow}/>
                        <div className={st.smNumber}>
                            {data?.userPosition + 1}
                        </div>
                    </div>
                </div>
            </CardTemplate>
        </Link>
    );
};

export default QueueSwitchProposalWidgetCard;