import React from 'react';
import classes from './ThemeMiniCard.module.css';
// @ts-ignore
import {ThemeContext, themes} from "../../../../contexts/ThemeContext";
import Base from "../../../UI/bases/Base";

interface Props {
    name: String,
    children: any
}

const  ThemeMiniCard = ({children, name}: Props) => {

    // @ts-ignore
    const tmp = classes[name];

    return (
        <ThemeContext.Consumer>
            {/*// @ts-ignore*/}
            {({theme, setTheme}) => (
                <div className={classes.pageContainer} >
                    <div onClick={() => setTheme(themes[name])}>
                        <Base className={`${classes.card} ${theme === name ? classes.cardActive : ''}`}>
                            <span>{children}</span>
                            <div className={`${classes.colorBox} ${tmp}`}/>
                        </Base>
                    </div>
                </div>
            )}
        </ThemeContext.Consumer>
    );
};

export default ThemeMiniCard;