import React, {useContext, useEffect, useState} from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import classes from "./QueueView.module.css";
import QueueUsersList from "../../../../components/dashboard/queue/queueLists/QueueUsersList";
import {useParams} from "react-router-dom";
import ConfirmModal from "../../../../components/UI/modals/ConfirmModal";
import Button from "../../../../components/UI/buttons/Button";
import QueueSwapOfferList from "../../../../components/dashboard/queue/queueLists/QueueSwapOfferList";
import SwapInQueueModal from "../../../../components/UI/modals/SwapInQueueModal";
import cn from "classnames";
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import QueueSendSwapOfferList from "../../../../components/dashboard/queue/queueLists/QueueSendSwapOfferList";
import AcceptSwapInQueueModal from "../../../../components/UI/modals/AcceptSwapInQueueModal";
import {Context} from "../../../../index";
import {useTranslation} from "react-i18next";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import LightBadge from "../../../../components/UI/badge/LightBadge/LightBadge";
import BaseTitle from "../../../../components/UI/titles/BaseTitle";
import EditQueueModal from "../../../../components/UI/modals/EditQueueModal";
import DateTimeService from "../../../../services/DateTimeService";
import {Tooltip} from "@mui/material";


const QueueView = () => {
    const {store} = useContext(Context);
    const {t} = useTranslation();
    const {groupAlias, queueAlias} = useParams()

    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)

    const [queue, setQueue] = useState(null)

    const [placeInQueue, setPlaceInQueue] = useState(null)

    const [userInfoToSwitch, setUserInfoToSwitch] = useState(null);
    const [userInfoToSwitch2, setUserInfoToSwitch2] = useState(null);


    const [acceptDenyIndex, setAcceptDenyIndex] = useState(0);


    const [leaveQueueModal, setLeaveQueueModal] = useState(false);
    const [swapInQueueModal, setSwapInQueueModal] = useState(false);
    const [acceptOfferModal, setAcceptOfferModal] = useState(false)
    const [denyOfferModal, setDenyOfferModal] = useState(false)

    const [editQueueModal, setEditQueueModal] = useState(false)
    const [closeQueueModal, setCloseQueueModal] = useState(false)
    const [archiveQueueModal, setArchiveQueueModal] = useState(false)

    useEffect(() => {
        const fetchData = () => {
            store.getQueuesByAliasAndGroupAlias(groupAlias, queueAlias).then(
                (response) => {
                    setQueue(response)
                    const userIndex = response.users.findIndex((user) => user.id === store.currentUser.id);
                    if (userIndex !== -1) {
                        setPlaceInQueue(userIndex);
                    } else {
                        setPlaceInQueue(-1);
                    }
                    setLoading(false);
                }
            )
        };
        fetchData();
    }, [store, groupAlias, queueAlias, placeInQueue]);


    const updateQueue = async () => {
        setUpdating(true);
        // setLoading(true)
        const response = await store.getQueuesByAliasAndGroupAlias(groupAlias, queueAlias);
        setQueue(response);

        const userIndex = response.users.findIndex((user) => user.id === store.currentUser.id);
        if (userIndex !== -1) {
            setPlaceInQueue(userIndex);
        } else {
            setPlaceInQueue(-1);
        }

        setLoading(false);
        setUpdating(false);
    }

    useEffect(() => {

        // Установка интервала для обновления каждые 15 секунд
        const intervalId = setInterval(async () => {
            setUpdating(true);
            // setLoading(true)
            const response = await store.getQueuesByAliasAndGroupAlias(groupAlias, queueAlias);
            setQueue(response);

            const userIndex = response.users.findIndex((user) => user.id === store.currentUser.id);
            if (userIndex !== -1) {
                setPlaceInQueue(userIndex);
            } else {
                setPlaceInQueue(-1);
            }

            setLoading(false);
            setUpdating(false);
        }, 15000);

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, [
        store,
        groupAlias,
        queueAlias,
        placeInQueue,
    ]);

    const getUserPlaceInQueue = (userId) => {
        return queue.users.findIndex((u) => u.id === userId)
    }

    const joinToQueue = () => {
        store.joinQueue(
            groupAlias,
            queue.id
        ).then(updateQueue)
    }


    // Подтверждение выхода из очереди
    const leaveFromQueue = () => {
        setLeaveQueueModal(false)
        setPlaceInQueue(null);
        store.leaveQueue(groupAlias, queue.id).then(updateQueue)
    }

    // Перемешивание очереди
    const shuffleQueue = () => {
        store.shuffleQueue(groupAlias, queue.id).then(updateQueue)
    }

    const editQueue = async (data) => {
        console.log(data)
        store.queue.editQueue(
            queue.groupId,
            queue.id,
            {
                title: data.title,
                status: 'ACTIVE',
                openTime: data.openByTime ? data.openByTimeDate : null,
                closeTime: data.closeByTime ? data.closeByTimeDate : null,
                willBeShuffled: data.canShuffle ? 'YES' : 'NO',
            }
        ).then(() => {
            setEditQueueModal(false)
            updateQueue()
        })
    }


    // Открыть окно подтверждения отправки запросу на смену в очереди
    const switchProposalModalOpen = (index) => {
        // Ищём наш индекс в очереди
        const selfIndex = getUserPlaceInQueue(store.currentUser.id);
        setUserInfoToSwitch({placeInQueue: selfIndex, ...queue.users[selfIndex]});
        setUserInfoToSwitch2({placeInQueue: index, ...queue.users[index]});
        setSwapInQueueModal(true);
    };

    // Подтвердить отправку предложения поменяться
    const swapOfferSendConfirm = () => {
        store.createSwitchProposal(
            queue.id,
            userInfoToSwitch2.id,
        ).then(updateQueue)
        setSwapInQueueModal(false);
    };

    // Отменить отправку предложения поменяться
    const cancelSwitchProposal = (index) => {
        store.cancelSwitchProposal(
            queue.sendProposals[index].id
        ).then(updateQueue)
    }

    // Открытие окна подтверждения или отказа предложения поменяться
    const swapOfferAcceptOpenModal = (index, status) => {
        setAcceptDenyIndex(index)
        if (status) {
            const selfIndex = getUserPlaceInQueue(store.currentUser.id);
            const otherIndex = getUserPlaceInQueue(queue.receivedProposals[index].user.id);
            setUserInfoToSwitch({placeInQueue: selfIndex, ...queue.users[selfIndex]});
            setUserInfoToSwitch2(
                {placeInQueue: otherIndex, ...queue.users[otherIndex]}
            );
            setAcceptOfferModal(true);
        } else {
            setDenyOfferModal(true);
        }
    }

    // Подтверждение отказа предложения поменяться
    const swapOfferDenyConfirm = () => {
        store.denySwitchProposal(
            queue.receivedProposals[acceptDenyIndex].id
        ).then(updateQueue)
        setDenyOfferModal(false);
    }

    // Подтверждение принятие предложения поменяться
    const swapOfferAcceptConfirm = () => {
        store.acceptSwitchProposal(
            queue.receivedProposals[acceptDenyIndex].id
        ).then(updateQueue);

        setAcceptOfferModal(false);
    };

    const closeQueue = () => {
        store.queue.closeQueue(
            queue.groupId,
            queue.id
        ).then(updateQueue)
        setCloseQueueModal(false)
    }

    const archiveQueue = () => {
        store.queue.archiveQueue(
            queue.groupId,
            queue.id
        ).then(updateQueue)
        setArchiveQueueModal(false)
    }

    return (
        <Dashboard title={t('queueViewPage.title')}>

            <ConfirmModal
                open={leaveQueueModal}
                setOpen={setLeaveQueueModal}
                action={leaveFromQueue}
                header={t('queueViewPage.valMessageQueue.leave')}
                body={t('queueViewPage.leave')}
                confirmText={t('exit')}
            />

            <ConfirmModal
                open={denyOfferModal}
                setOpen={setDenyOfferModal}
                action={swapOfferDenyConfirm}
                header={t('queueViewPage.valMessageQueue.swap')}
                body={t('queueViewPage.swap')}
                confirmText={t('queueViewPage.decline')}
            />

            <SwapInQueueModal
                open={swapInQueueModal}
                setOpen={setSwapInQueueModal}
                action={swapOfferSendConfirm}
                selfUser={userInfoToSwitch}
                otherUser={userInfoToSwitch2}
            />

            <AcceptSwapInQueueModal
                open={acceptOfferModal}
                setOpen={setAcceptOfferModal}
                action={swapOfferAcceptConfirm}
                selfUser={userInfoToSwitch}
                otherUser={userInfoToSwitch2}
            />

            {
                queue?.id &&
                <EditQueueModal
                    open={editQueueModal}
                    setOpen={setEditQueueModal}
                    queue={queue}
                    action={editQueue}
                />
            }

            {
                loading ?
                    <>
                        <CardTemplate className={cn(classes.info, 'animate-pulse h-12')}>
                            <div className={classes.infoHeader}>
                            </div>
                        </CardTemplate>
                        <div className={classes.content}>
                            <CardTemplate className={cn(classes.list, 'animate-pulse h-12 xl:h-auto')} children={''}/>

                            <div className={cn(classes.list, classes.offers)}>
                                <div>
                                    <CardTemplate className={cn('animate-pulse h-40')} children={''}/>

                                </div>
                                <div>
                                    <CardTemplate className={cn('animate-pulse h-28')} children={''}/>
                                </div>
                            </div>
                        </div>

                    </>
                    :
                    <>
                        <CardTemplate className={classes.info}>
                            <div className={classes.infoHeader}>
                                <div>
                                    <h1 className={classes.discipline}>{queue?.title}</h1>
                                    {/*<h2 className={classes.name}>{queue.name}</h2>*/}
                                </div>
                                <div className={classes.infoHeaderButtons}>
                                    {

                                        queue.status === 'ACTIVE' && (
                                            placeInQueue !== -1
                                                ?
                                                <Button
                                                    color={"red"}
                                                    size={"md"}
                                                    width={'sm-full'}
                                                    onClick={() => setLeaveQueueModal(true)}
                                                >{t('leaveQueue')}</Button>
                                                :
                                                <Button
                                                    color={"green"}
                                                    size={"md"}
                                                    width={'sm-full'}
                                                    onClick={joinToQueue}
                                                >{t('joinQueue')}</Button>
                                        )
                                    }
                                    {/* Reload */}
                                    <Tooltip title={t('update')} placement="left" arrow>
                                        <Button
                                            color={"gray"}
                                            isIcon={true}
                                            width={'auto'}
                                            onClick={updateQueue}
                                            disabled={updating}
                                        >
                                            <ArrowPathIcon className={
                                                cn(
                                                    'w-6 h-6', {
                                                        [classes.spin]: updating,
                                                    }
                                                )}/>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={classes.params}>
                                <LightBadge
                                    title={'Статус'}
                                    value={queue.status === 'ACTIVE' ? t('open') :
                                        queue.status === 'CLOSED' ? t('close') :
                                            queue.status === 'WAITING' ? t('waiting') : t('archive')}
                                />
                                {
                                    queue.openTime &&
                                    <LightBadge
                                        title={t('queueViewPage.openIn')}
                                        value={DateTimeService.convertBackDateToString(queue.openTime)}
                                    />
                                }
                                {
                                    queue.closeTime &&
                                    <LightBadge
                                        title={t('queueViewPage.closeIn')}
                                        value={DateTimeService.convertBackDateToString(queue.closeTime)}
                                    />
                                }
                                <LightBadge
                                    title={t('queueViewPage.queueCard.shuffle')}
                                    value={queue.willBeShuffled === 'YES' ? t('yes') : queue.willBeShuffled === 'NO' ? t('no') : t('already')}
                                />

                                <LightBadge
                                    title={t('queueViewPage.queueCard.occupied')}
                                    value={queue.takenPlaces.toString()}
                                />
                            </div>
                        </CardTemplate>

                        <div className={classes.content}>
                            <div className={classes.list}>
                                <QueueUsersList
                                    users={queue?.users?.length ? queue?.users : []}
                                    swapAction={switchProposalModalOpen}
                                    canSwitch={queue?.willBeShuffled !== 'YES' && queue?.status === 'ACTIVE' && placeInQueue !== -1}
                                />
                            </div>
                            <div className={cn(classes.list, classes.offers)}>
                                {
                                    store.havePermission(
                                        'GROUP_QUEUE_MANAGEMENT',
                                        true,
                                        groupAlias
                                    ) &&
                                    <CardTemplate>
                                        <BaseTitle title={t('queueViewPage.manage.title')}/>
                                        <div className={classes.manageButtons}>
                                            {
                                                queue?.willBeShuffled === 'YES' && queue?.status === 'ACTIVE' &&
                                                <Button
                                                    color={"blue"}
                                                    size={"md"}
                                                    title={t('queueViewPage.manage.shuffle')}
                                                    // disabled={queue?.users?.length !== 0}
                                                    onClick={shuffleQueue}
                                                />
                                            }
                                            {
                                                queue?.willBeShuffled === 'SHUFFLED' && queue?.status === 'ACTIVE' &&
                                                <Button
                                                    color={"blue"}
                                                    size={"md"}
                                                    disabled={true}
                                                    title={t('shuffle')}
                                                    // disabled={queue?.users?.length !== 0}
                                                    // onClick={shuffleQueue}
                                                />
                                            }
                                            {
                                                queue?.status !== 'ARCHIVED' && queue?.status !== 'CLOSED' &&
                                                <Button
                                                    color={"gray"}
                                                    size={"md"}
                                                    title={t('queueViewPage.manage.edit')}
                                                    // disabled={queue?.users?.length !== 0}
                                                    onClick={() => setEditQueueModal(true)}
                                                />
                                            }
                                            {
                                                queue?.status !== 'ARCHIVED' &&
                                                queue?.status !== 'CLOSED' &&
                                                <Button
                                                    color={"red"}
                                                    size={"md"}
                                                    title={t('queueViewPage.manage.close')}
                                                    // disabled={queue?.users?.length !== 0}
                                                    onClick={() => setCloseQueueModal(true)}
                                                />
                                            }
                                            {
                                                queue?.status !== 'ARCHIVED' &&
                                                <Button
                                                    className={classes.double}
                                                    color={"gray"}
                                                    size={"md"}
                                                    title={t('queueViewPage.manage.archiving')}
                                                    // disabled={queue?.users?.length !== 0}
                                                    onClick={() => setArchiveQueueModal(true)}
                                                />
                                            }
                                        </div>
                                    </CardTemplate>
                                }

                                <QueueSwapOfferList
                                    getPlaceById={getUserPlaceInQueue}
                                    offers={queue.receivedProposals}
                                    swapAction={swapOfferAcceptOpenModal}
                                />

                                <QueueSendSwapOfferList
                                    getPlaceById={getUserPlaceInQueue}
                                    offers={queue.sendProposals}
                                    swapAction={cancelSwitchProposal}
                                />

                                <ConfirmModal
                                    open={closeQueueModal}
                                    setOpen={setCloseQueueModal}
                                    action={closeQueue}
                                    header={t('queueViewPage.modal.closeHeader')}
                                    body={t('queueViewPage.modal.closeBody')}
                                    confirmText={t('queueViewPage.modal.closeButton')}
                                />

                                <ConfirmModal
                                    open={archiveQueueModal}
                                    setOpen={setArchiveQueueModal}
                                    action={archiveQueue}
                                    header={t('queueViewPage.modal.archiveHeader')}
                                    body={t('queueViewPage.modal.archiveBody')}
                                    confirmText={t('queueViewPage.modal.archiveButton')}
                                />

                            </div>
                        </div>
                    </>
            }
        </Dashboard>
    );
};

export default QueueView;