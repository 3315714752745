import React from 'react';
import {DropdownItem} from "../../../../models/ui/DropdownItem";
import st from './Dropdown.module.css'

interface DropdownFormProps {
    name: string;
    title?: string;
    items: DropdownItem[];
    register: any;
    onChange?: (selectedValue: string) => void;
}


const Dropdown = ({name, title, items, register, onChange}: DropdownFormProps) => {
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (onChange) {
            onChange(event.target.value);
        }
    };
    return (
        <div className={st.inputBlock}>
            <label className={st.inputBlockTittle}>
                {title ? title : name}
            </label>
            <select
                {...register(name, {required: 'Пожалуйста, выберите элемент'})}
                onChange={handleSelectChange}
                className={st.input}
            >
                {
                    items.length === 0 &&
                    <option value="" disabled>Загрузка...</option>
                }
                {items.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
            {/*{errors[name] && <p className="text-red-500 text-sm mt-1">{errors[name]?.message}</p>}*/}
        </div>
    );
};

export default Dropdown;