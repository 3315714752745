import React, {useContext, useEffect, useState} from 'react';
import st from "./UserAdminProfile.module.css"
import Dashboard from "../../../components/templates/Dashboard/Dashboard";
import {useParams} from "react-router-dom";
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";
import {IUserAdminProfileResponse} from "../../../models/user/IUserAdminProfileResponse";
import UserMainInfo from "../../../components/admin/users/UserMainInfo/UserMainInfo";
import UserContactsInfo from "../../../components/admin/users/UserContactsInfo/UserContactsInfo";
import UserBansInfo from "../../../components/admin/users/UserBansInfo/UserBansInfo";
import UserStatusInfo from "../../../components/admin/users/UserStatusInfo/UserStatusInfo";

const UserAdminProfile = () => {
    const {userId} = useParams()
    const {store} = useContext(Context)
    const {t} = useTranslation()
    const [isLoad, setIsLoad] = useState(false)
    const [user, setUser] = useState<IUserAdminProfileResponse>({} as IUserAdminProfileResponse)


    useEffect(() => {
        const fetchUser = async () => {
            setUser(await store.user.getUserById(userId || '') as IUserAdminProfileResponse);
            setIsLoad(true)
        }
        fetchUser()

    }, [store, userId, setIsLoad]);

    const reload = async () => {
        setUser(await store.user.getUserById(userId || '') as IUserAdminProfileResponse);
    }

    return (
        <Dashboard title={t('admin.users.view')}>
            {
                isLoad ?
                    <div className={st.content}>
                        <UserMainInfo user={user} reload={reload}/>
                        <UserStatusInfo user={user} reload={reload}/>
                        <UserBansInfo user={user}/>
                        <UserContactsInfo user={user} reload={reload}/>
                    </div>
                    :
                    null
            }
        </Dashboard>
    );
};

export default UserAdminProfile;