import React from 'react';
import {IUserContactResponse} from "../../../../../models/user/IUserContactResponse";
import st from './ContactsBlock.module.css'
import {Tooltip} from "@mui/material";

type Props = {
    data: IUserContactResponse;
}

const ContactsBlock = ({data}: Props) => {
    return (
        <div className={st.list}>

            {
                data?.email &&
                <Tooltip title={data.email} arrow placement={'top'}>
                    <a
                        href={`mailto:${data.email}`}
                        rel={'noreferrer'}
                        target={'_blank'} className={st.icon}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                        >
                            <g
                                fill="none"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                                transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                            >
                                <circle cx="45" cy="45" r="45" fill="#0198e1"></circle>
                                <path
                                    fill="#FFF"
                                    d="M64.567 26.495H25.433a5.689 5.689 0 00-5.689 5.689v25.631a5.689 5.689 0 005.689 5.689h39.135a5.689 5.689 0 005.689-5.689V32.184a5.691 5.691 0 00-5.69-5.689zM63.343 57.47a1.497 1.497 0 01-2.121.041l-9.419-9.065-1.357 1.489A7.386 7.386 0 0145 52.339a7.385 7.385 0 01-5.446-2.404l-1.337-1.467-9.022 9.023c-.292.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121l9.12-9.121-9.563-10.495a1.5 1.5 0 012.217-2.021l12.924 14.18c.838.92 1.985 1.426 3.229 1.426s2.392-.506 3.229-1.426l2.422-2.657 10.502-11.522a1.501 1.501 0 012.217 2.021l-9.546 10.474 9.477 9.121a1.5 1.5 0 01.042 2.12z"
                                ></path>
                            </g>
                        </svg>
                    </a>
                </Tooltip>
            }

            {
                data?.vk &&
                <Tooltip title={data.vk} arrow placement={'top'}>
                    <a
                        href={`https://vk.com/${data.vk}`}
                        rel={'noreferrer'}
                        target={'_blank'}
                        className={st.icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                            <path
                                d="M0 23.04C0 12.1788 0 6.74826 3.37413 3.37413C6.74826 0 12.1788 0 23.04 0H24.96C35.8212 0 41.2517 0 44.6259 3.37413C48 6.74826 48 12.1788 48 23.04V24.96C48 35.8212 48 41.2517 44.6259 44.6259C41.2517 48 35.8212 48 24.96 48H23.04C12.1788 48 6.74826 48 3.37413 44.6259C0 41.2517 0 35.8212 0 24.96V23.04Z"
                                fill="#0077FF"/>
                            <path
                                d="M25.54 34.5801C14.6 34.5801 8.3601 27.0801 8.1001 14.6001H13.5801C13.7601 23.7601 17.8 27.6401 21 28.4401V14.6001H26.1602V22.5001C29.3202 22.1601 32.6398 18.5601 33.7598 14.6001H38.9199C38.0599 19.4801 34.4599 23.0801 31.8999 24.5601C34.4599 25.7601 38.5601 28.9001 40.1201 34.5801H34.4399C33.2199 30.7801 30.1802 27.8401 26.1602 27.4401V34.5801H25.54Z"
                                fill="white"/>
                        </svg>
                    </a>
                </Tooltip>
            }

            {
                data?.telegram &&
                <Tooltip title={data.telegram} arrow placement={'top'}>
                    <a
                        href={`https://t.me/${data.telegram}`}
                        target={'_blank'}
                        rel={'noreferrer'}
                        className={st.icon}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Livello 1"
                            viewBox="0 0 240 240"
                        >
                            <defs>
                                <linearGradient
                                    id="linear-gradient"
                                    x1="120"
                                    x2="120"
                                    y1="240"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop offset="0" stopColor="#1d93d2"></stop>
                                    <stop offset="1" stopColor="#38b0e3"></stop>
                                </linearGradient>
                            </defs>
                            <circle cx="120" cy="120" r="120" fill="url(#linear-gradient)"></circle>
                            <path
                                fill="#c8daea"
                                d="M81.229 128.772l14.237 39.406s1.78 3.687 3.686 3.687 30.255-29.492 30.255-29.492l31.525-60.89L81.737 118.6z"
                            ></path>
                            <path
                                fill="#a9c6d8"
                                d="M100.106 138.878l-2.733 29.046s-1.144 8.9 7.754 0 17.415-15.763 17.415-15.763"
                            ></path>
                            <path
                                fill="#fff"
                                d="M81.486 130.178L52.2 120.636s-3.5-1.42-2.373-4.64c.232-.664.7-1.229 2.1-2.2 6.489-4.523 120.106-45.36 120.106-45.36s3.208-1.081 5.1-.362a2.766 2.766 0 011.885 2.055 9.357 9.357 0 01.254 2.585c-.009.752-.1 1.449-.169 2.542-.692 11.165-21.4 94.493-21.4 94.493s-1.239 4.876-5.678 5.043a8.13 8.13 0 01-5.925-2.292c-8.711-7.493-38.819-27.727-45.472-32.177a1.27 1.27 0 01-.546-.9c-.093-.469.417-1.05.417-1.05s52.426-46.6 53.821-51.492c.108-.379-.3-.566-.848-.4-3.482 1.281-63.844 39.4-70.506 43.607a3.21 3.21 0 01-1.48.09z"
                            ></path>
                        </svg>
                    </a>
                </Tooltip>
            }
        </div>
    );
};

export default ContactsBlock;