import React from 'react';
import st from './Input.module.css'
import PropTypes from "prop-types";
import cn from 'classnames';

const Input = (props) => {
    const {outline, widthFull, title, small, name, register, errors, required, type, validationSchema, disabled, forView} = props

    return (
        <div className={cn(st.inputBlock,
            {
                [st.inputWidthFull]: widthFull,
            }
            )}>
            <label
                htmlFor={name}
                className={`${st.inputBlockTittle} ${disabled && !forView ? st.inputBlockTittleDisabled : ''}`}
            >{title}{required && "*"}<small> {small}</small>
            </label>
            {
                register ?
                    <input
                        id={name}
                        name={name}
                        type={type}
                        disabled={disabled || forView}
                        {...register(name, validationSchema)}
                        {...props}
                        className={cn(st.input, {
                            [st.inputOutLine]: outline,
                            [st.wightFull]: widthFull,
                            [st.forView]: forView,
                        })}
                    />
                    : <input
                        id={name}
                        name={name}
                        type={type}
                        disabled={disabled || forView}
                        {...props}
                        className={cn(st.input, {
                            [st.inputOutLine]: outline,
                            [st.wightFull]: widthFull,
                            [st.forView]: forView,
                        })}
                    />
            }

            {
                errors && errors[name] && (
                    <div className={st.error}>{errors[name]?.message}</div>
                )
            }
        </div>
    );
}

Input.defaultProps = {
    outline: true,
    size: "",
    widthFull: true,
    statement: null,
    title: null,
    children: null,
    forView: false,
};

Input.propsType = {
    outline: PropTypes.bool,
    size: PropTypes.oneOf(["sm", "base", "lg"]),
    widthFull: PropTypes.bool,
    statement: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.elementType,
    forView: PropTypes.bool,
};

export default Input;