import {Dialog} from '@headlessui/react'
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline'
import st from './Modals.module.css'
import Button from "../buttons/Button";
import PropTypes from "prop-types";
import ModalTemplate from "../bases/ModalTemplate";
import {useTranslation} from "react-i18next";


const ConfirmModal = (props) => {
    const {t} = useTranslation();

    const {open, setOpen, action, header, body, confirmText, icon} = props

    if (false) console.log(icon)

    return (
        <ModalTemplate setOpen={setOpen} open={open}>
            <Dialog.Panel
                className={st.panel}>
                <div className={st.mainContent}>
                    <div className={st.mainContentContainer}>
                        <div
                            className={st.iconContainer}>
                            <ExclamationTriangleIcon className={st.icon}
                                                     aria-hidden="true"/>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3"
                                          className={st.header}>
                                {header}
                            </Dialog.Title>
                            <div className={st.body}>
                                <p>
                                    {body}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={st.btnBlock}>
                    <Button
                        color={"red"}
                        onClick={action}
                        width={"sm-full"}
                    >
                        {confirmText}
                    </Button>
                    <Button
                        color={"gray"}
                        outline={true}
                        onClick={() => setOpen(false)}
                        width={"sm-full"}
                    >
                        {t('cancel')}
                    </Button>
                </div>
            </Dialog.Panel>
        </ModalTemplate>
    )
}

ConfirmModal.defaultProps = {
    open: false,
    setOpen: null,
    action: null,
    header: "",
    body: "",
    confirmText: "",
    icon: null,
}

ConfirmModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    action: PropTypes.func,
    header: PropTypes.string,
    body: PropTypes.string,
    confirmText: PropTypes.string,
    icon: PropTypes.oneOf(["danger", "warn", "success"])
}


export default ConfirmModal;