import React, {useContext, useEffect, useState} from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import {Link, useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import {useTranslation} from "react-i18next";
import {Tab, Tabs} from "../../../../components/UI/tabs/Tabs";
import GeneralGroupInfo from "../../../../components/dashboard/group/groupsTabs/GeneralGroupInfo/GeneralGroupInfo";
import GroupUsersInfo from "../../../../components/dashboard/group/groupsTabs/GroupUsersInfo/GroupUsersInfo";
import GroupSettings from "../../../../components/dashboard/group/groupsTabs/GroupSettings/GroupSettings";
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import StandardTitle from "../../../../components/UI/titles/StandardTitle";
import Chip from "../../../../components/UI/badge/Chip/Chip";


const GroupView = () => {
    const {t} = useTranslation();
    const {store} = useContext(Context);

    const {alias} = useParams()
    const navigate = useNavigate();

    const [group, setGroup] = useState({}); // Данные о группе
    const [userInGroup, setUserInGroup] = useState(false); // Пользователь в группе


    useEffect(() => {
        const fetchData = async () => {
            try {
                const groupResponse = await store.getGroupByAlias(alias);
                if (!groupResponse) navigate('/d/groups')
                // Проверяем, состоит ли пользователь в группе
                setUserInGroup(groupResponse.users.some(user => user.id === store.currentUser.id));
                // Сортируем по фамилиям
                groupResponse.users.sort((a, b) => {
                    if (a.lastName > b.lastName) return 1;
                    if (a.lastName < b.lastName) return -1;
                    return 0;
                })
                setGroup(groupResponse);
            } catch (e) {
                console.error(e);
            }
        };
        fetchData().then();
    }, [alias, store, navigate]);

    if (!userInGroup) {
        return (
            <Dashboard title={t('groupPage.groupView.title')}>
                <CardTemplate className={'flex flex-row gap-2 items-baseline'}>
                    <StandardTitle>{t('groupPage.groupView.notInGroup')}</StandardTitle>
                    <Link to={'/d/groups'}>
                        <Chip title={t('groupPage.groupView.backToGroups')}/>
                    </Link>
                </CardTemplate>
            </Dashboard>
        )
    }


    return (
        <Dashboard title={t('groupPage.groupView.title')}>
            {
                group.id &&
                <Tabs>
                    <Tab title={t('groupPage.tabs.general')}>
                        <GeneralGroupInfo group={group}/>
                    </Tab>
                    <Tab title={t('groupPage.tabs.members')}>
                        <GroupUsersInfo group={group}/>
                    </Tab>
                    <Tab title={t('groupPage.tabs.settings')}>
                        <GroupSettings groupData={group}/>
                    </Tab>
                </Tabs>
            }
        </Dashboard>
    );
};

export default observer(GroupView);