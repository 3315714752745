import React from 'react';
import cn from "classnames";

type Props = {
    hovered?: boolean
    children: React.ReactNode
    className?: any
}

const LightBase = ({children, className, hovered, ...other}: Props) => {
    return (
        <div className={cn(
            'template__light__base',
            {'template__light__hovered': hovered},
            className
        )} {...other}>
            {children}
        </div>
    );
};

export default LightBase;