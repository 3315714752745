import React from 'react';
import classes from "./QueueSwapOfferUserCard.module.css";
import Button from "../../../../../UI/buttons/Button";
import cn from "classnames";
import {XMarkIcon, CheckIcon} from "@heroicons/react/24/solid";

const QueueSwapOfferUserCard = ({offer, index, swapAction, movingUp, toPlace}) => {


    return (
        <tr className={classes.mainContainer}>
            <td className={classes.numberContainer}>
                <div className={cn(classes.number, {
                    [classes.movingUpNumber]: movingUp,
                    [classes.movingDownNumber]: !movingUp
                })}>
                    {toPlace + 1}
                </div>
            </td>
            <td>
                <div className={classes.userInfo}>
                    <img src={offer?.user?.avatar}
                         className={classes.avatar}
                         alt={'avatar'}/>
                    <div className={classes.info}>
                        <div className={classes.name}>{offer.user.firstName} <span
                            className="hidden md:inline">{offer.user.lastName}</span> <span
                            className="inline md:hidden">{offer.user.lastName.substring(0, 1)}.</span></div>

                        <div className={classes.role}>
                            {offer.user.username ? 'aka ' + offer.user.username : ''}
                        </div>
                    </div>
                </div>
            </td>

            <td className={'w-full'}>
                <div className={classes.btnBlock}>
                    <Button
                        onClick={() => swapAction(index, true)}
                        widthFull={false}
                        size={"sm"}
                        color={"green"}
                    >
                        <CheckIcon className="w-5 h-5"/>
                    </Button>
                    <Button
                        onClick={() => swapAction(index, false)}
                        widthFull={false}
                        size={"sm"}
                        color={"red"}
                    >
                        <XMarkIcon className="w-5 h-5"/>
                    </Button>
                </div>
            </td>

        </tr>
    );
};

export default QueueSwapOfferUserCard;