import React, {useContext, useState} from 'react';
import Dashboard from "../../../components/templates/Dashboard/Dashboard";
import OfficeBlock from "../../../components/dashboard/office/OfficeBlock/OfficeBlock";
import ThemeMiniCard from "../../../components/dashboard/office/ThemeMiniCard/ThemeMiniCard";
import UserInfoCard from "../../../components/dashboard/office/UserInfoCard/UserInfoCard";
import classes from './UserOffice.module.css';
import Button from "../../../components/UI/buttons/Button";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import EditPasswordModal from "../../../components/UI/modals/EditPasswordModal";
import {useTranslation} from "react-i18next";
import ChangeLanguageModal from "../../../components/UI/modals/ChangeLanguageModal";

const UserOffice = () => {
    const {t} = useTranslation();


    const {store} = useContext(Context);
    const navigate = useNavigate();
    const [editPasswordModal, setEditPasswordModal] = useState(false);
    const [changeLanguageModal, setChangeLanguageModal] = useState(false);

    const logout = () => {
        store.logout().then(
            () => {
                navigate('/login');
            }
        );
    }

    return (
        <Dashboard title={t('office.title')}>

            <EditPasswordModal
                open={editPasswordModal}
                setOpen={setEditPasswordModal}

            />

            <div className={classes.blocksList}>
                <UserInfoCard/>

                <OfficeBlock title={t('office.theme.title')}>
                    <div className={classes.themeList}>
                        <ThemeMiniCard name='default'>{t('office.theme.default')}</ThemeMiniCard>
                        <ThemeMiniCard name='darkBlue'>{t('office.theme.darkBlue')}</ThemeMiniCard>
                        <ThemeMiniCard name='purple'>{t('office.theme.purple')}</ThemeMiniCard>
                        <ThemeMiniCard name='grayCalorie'>{t('office.theme.grayCalorie')}</ThemeMiniCard>
                        <ThemeMiniCard name='graySomeone'>{t('office.theme.graySomeone')}</ThemeMiniCard>
                        <ThemeMiniCard name='graySurfboard'>{t('office.theme.graySurfboard')}</ThemeMiniCard>
                        <ThemeMiniCard name='black'>{t('office.theme.black')}</ThemeMiniCard>
                        <ThemeMiniCard name='purple2'>{t('office.theme.lightPink')}</ThemeMiniCard>
                        <ThemeMiniCard name='rainbowLine'>{t('office.theme.rainbowLine')}</ThemeMiniCard>
                        <ThemeMiniCard name='blackRed'>{t('office.theme.MinusD')}</ThemeMiniCard>
                        <ThemeMiniCard name='rainbowLight'>{t('office.theme.lightRainbow')}</ThemeMiniCard>
                    </div>
                </OfficeBlock>

                <OfficeBlock title={t('office.accountManagement')}>
                    <div className="mt-2">
                        <Button
                            title={t('changeLanguage')}
                            width={'full'}
                            onClick={() => setChangeLanguageModal(true)}
                        />
                    </div>

                    <div className="mt-2">
                        <Button
                            title={t('office.changePassword')}
                            onClick={() => setEditPasswordModal(true)}
                            width={'full'}/>
                    </div>
                    <div className="mt-2">
                        <Button
                            title={t('logout')}
                            color={"red"}
                            onClick={logout}
                            width={'full'}/>
                    </div>
                </OfficeBlock>
            </div>

            <ChangeLanguageModal
                open={changeLanguageModal}
                setOpen={setChangeLanguageModal}
            />

        </Dashboard>
    );
};

export default observer(UserOffice);