import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// @ts-ignore
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import Store from "./store/store";
// @ts-ignore
import App from "./App";

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

const store = new Store();

export const Context = createContext({
    store,
})

root.render(
    // <React.StrictMode>
    <Context.Provider value={{store}}>
        {
            process.env.NODE_ENV !== 'production' &&
            <div className={'w-full bg-red-100 px-2 text-xs fixed z-[98] bottom-0'}>
                Система работает в тестовом режиме |
                Рабочая версия расположена по адресу <a
                href={'https://diary.mbc-studio.ru/'}>diary.mbc-studio.ru</a>
            </div>
        }
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Context.Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
