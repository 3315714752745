import {AxiosError, AxiosResponse} from 'axios';
import {AuthResponse} from '../models/response/AuthResponse';
import $api from '../http';
import {IUserRegister} from '../models/response/IUserRegister';

export default class AuthService {
    static async register(
        user: IUserRegister
    ): Promise<AxiosResponse<AuthResponse>> {
        const json = JSON.stringify(user);
        try {
            return await $api.post<AuthResponse>('/auth/register', json);
        } catch (error: any) {
            throw AuthService.handleError(error);
        }
    }

    static async authenticate(
        username: string,
        password: string
    ): Promise<AxiosResponse<AuthResponse>> {
        const json = JSON.stringify({username: username, password: password});
        try {
            return await $api.post<AuthResponse>(
                '/auth/authenticate',
                json
            );
        } catch (error: any) {
            throw AuthService.handleError(error);
        }
    }

    static handleError(error: AxiosError) {
        if (error.response) {
            // Ошибка пришла от сервера (например, неверный пароль)
            return error.response.data;
        } else if (error.request) {
            // Ошибка при выполнении запроса (например, проблемы с сетью)
            return {
                success: false,
                message: 'Ошибка выполнения запроса',
            };
        } else {
            // Непредвиденная ошибка
            return {
                success: false,
                message: 'Ошибка на стороне сервера',
            };
        }
    }
}
