import React, {useContext, useEffect, useState} from 'react';
import st from './GroupSettings.module.css'
import {IGroup} from "../../../../../models/IGroup";
import CardTemplate from "../../../../UI/bases/CardTemplate";
import StandardTitle from "../../../../UI/titles/StandardTitle";
import {useTranslation} from "react-i18next";
import CopyInput from "../../../../utils/CopyInput/CopyInput";
import {IGroupInfoResponse} from "../../../../../models/group/IGroupInfoResponse";
import {Context} from "../../../../../index";
import Button from "../../../../UI/buttons/Button";
import BaseTitle from "../../../../UI/titles/BaseTitle";
import CreateInviteLinkModal from "../../../../UI/modals/CreateInviteLinkModal.jsx";
import EditGroupInfoModal from "../../../../UI/modals/EditGroupInfoModal.jsx";
import ConfirmModal from "../../../../UI/modals/ConfirmModal.jsx";
import {useNavigate} from "react-router-dom";
import ConfirmDeleteModal from "../../../../UI/modals/ConfirmDeleteModal.jsx";

type GroupSettingsProps = {
    groupData: IGroup;
}

const GroupSettings = ({groupData}: GroupSettingsProps) => {
    const {t} = useTranslation();
    const {store} = useContext(Context);
    const navigate = useNavigate();

    const [editGroupModal, setEditGroupModal] = useState(false);
    const [createInviteLinkModal, setCreateInviteLinkModal] = useState(false);
    const [confirmDeleteGroupModal, setConfirmDeleteGroupModal] = useState(false);
    const [lastConfirmDeleteGroupModal, setLastConfirmDeleteGroupModal] = useState(false);
    const [confirmLeaveGroupModal, setConfirmLeaveGroupModal] = useState(false);


    const [group, setGroup] = useState({} as IGroupInfoResponse);

    useEffect(() => {
        async function getGroupInfo() {
            const groupInfo = await store.group.getGroupInfo(groupData.id);
            if (groupInfo)
                setGroup(groupInfo);
        }

        getGroupInfo();
    }, [
        groupData,
        groupData?.id,
        store.group
    ]);

    const leaveGroup = async () => {
        await store.group.leaveGroup(group.id);
        navigate('/d/groups');
    }

    const deleteGroup = async () => {
        await store.group.deleteGroup(group.id);
        navigate('/d/groups');
    }

    return (
        <div className={st.content}>
            <CardTemplate>
                <StandardTitle title={t('groupPage.settings.systemInfo')}/>
                <div className={st.systemInfo}>
                    <CopyInput value={group.id} title={t('groupPage.settings.groupId')} forView={true}/>
                    <CopyInput value={group.name} title={t('groupPage.settings.groupName')} forView={true}/>
                    <CopyInput value={group.alias} title={t('groupPage.settings.groupAlias')} forView={true}/>
                    <CopyInput value={group.description || ''} title={t('groupPage.settings.groupDescription')}
                               forView={true}/>
                    <CopyInput value={group.createdAt} title={t('groupPage.settings.createdAt')} forView={true}/>
                    <CopyInput value={group.usersCount?.toString()} title={t('groupPage.settings.usersCount')}
                               forView={true}/>
                    <CopyInput value={group.queuesCount?.toString()} title={t('groupPage.settings.queuesCount')}
                               forView={true}/>
                    <CopyInput value={group.queuesOpenCount?.toString()} title={t('groupPage.settings.queuesOpenCount')}
                               forView={true}/>
                    <CopyInput value={group.invitationsCount?.toString()}
                               title={t('groupPage.settings.invitationsCount')} forView={true}/>
                    <CopyInput value={group.invitationsActive ? t('yes') : t('no')}
                               title={t('groupPage.settings.invitationsActive')} forView={true}/>
                </div>
            </CardTemplate>
            <CardTemplate>
                <StandardTitle title={t('groupPage.settings.actions')}/>

                <div className={st.actions}>
                    {
                        (
                            store.havePermission(
                                'GROUP_INFO_MANAGEMENT',
                                true,
                                group.alias
                            )
                        ) &&
                        <CardTemplate>
                            <BaseTitle title={t('groupPage.settings.groupData')}/>
                            <div className={st.btns}>
                                <Button
                                    onClick={() => setEditGroupModal(true)}
                                    color={'blue'}
                                    title={t('groupPage.settings.changeDescription')}
                                    width={'full'}
                                    size={'base'}
                                    outline={false}
                                    className={''}
                                    isIcon={false}/>
                                <div className={'soonBadge'}>
                                    <Button
                                        disabled={true}
                                        color={'gray'}
                                        title={t('groupPage.settings.changeAlias')}
                                        width={'full'}
                                        size={'base'}
                                        outline={false}
                                        className={''}
                                        isIcon={false}/>
                                </div>
                            </div>
                        </CardTemplate>
                    }

                    {
                        store.havePermission(
                            'GROUP_USER_MANAGEMENT', true, group.alias
                        ) &&
                        <CardTemplate>
                            <BaseTitle title={t('groupPage.settings.invites')}/>
                            <div className={st.btns}>
                                <Button
                                    color={'blue'}
                                    title={t('groupPage.settings.manageInviteLinks')}
                                    width={'full'}
                                    onClick={() => setCreateInviteLinkModal(true)}
                                    size={'base'}
                                    outline={false}
                                    className={''}
                                    isIcon={false}/>

                                <div className={'soonBadge'}>
                                    <Button
                                        color={'gray'}
                                        disabled={true}
                                        title={t('groupPage.settings.manageInvites')}
                                        width={'full'}
                                        size={'base'}
                                        outline={false}
                                        className={''}
                                        isIcon={false}/>
                                </div>
                            </div>
                        </CardTemplate>
                    }

                    <CardTemplate className={st.dangerZone}>
                        <BaseTitle title={t('groupPage.settings.dangerZone')}/>
                        {
                            store.havePermission(
                                'GROUP_OWNER', true, group.alias
                            ) ?
                                <Button
                                    onClick={() => setConfirmDeleteGroupModal(true)}
                                    color={'red'}
                                    title={t('deleteGroup')}
                                    width={'full'}
                                    size={'base'}
                                    outline={false}
                                    className={''}
                                    isIcon={false}/>
                                :
                                <Button
                                    onClick={() => setConfirmLeaveGroupModal(true)}
                                    color={'red'}
                                    title={t('leaveGroup')}
                                    width={'full'}
                                    size={'base'}
                                    outline={false}
                                    className={''}
                                    isIcon={false}/>
                        }
                    </CardTemplate>
                </div>
            </CardTemplate>

            <EditGroupInfoModal
                open={editGroupModal}
                setOpen={setEditGroupModal}
                group={group}
            />

            <CreateInviteLinkModal
                open={createInviteLinkModal}
                setOpen={setCreateInviteLinkModal}
                group={group}
            />

            <ConfirmModal
                open={confirmLeaveGroupModal}
                setOpen={setConfirmLeaveGroupModal}
                header={t('leaveGroup')}
                body={t('groupPage.groupView.confirmLeave')}
                action={leaveGroup}
                confirmText={t('leave')}
            />

            <ConfirmModal
                open={confirmDeleteGroupModal}
                setOpen={setConfirmDeleteGroupModal}
                header={t('deleteGroup')}
                body={t('groupPage.groupView.confirmDelete')}
                action={() => {
                    setConfirmDeleteGroupModal(false)
                    setTimeout(
                        () => setLastConfirmDeleteGroupModal(true),
                        100
                    )
                }}
                confirmText={t('delete')}
            />

            <ConfirmDeleteModal
                open={lastConfirmDeleteGroupModal}
                setOpen={setLastConfirmDeleteGroupModal}
                group={group}
                action={deleteGroup}
            />

        </div>
    );
};

export default GroupSettings;