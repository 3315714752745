import React from 'react';
import st from "./UserGroupList.module.css"
import CardTemplate from "../../../UI/bases/CardTemplate";
import StandardTitle from "../../../UI/titles/StandardTitle";
import {useTranslation} from "react-i18next";
import Chip from "../../../UI/badge/Chip/Chip";

type UserGroupListProps = {
    groups: any[]
}

const UserGroupList = ({groups}: UserGroupListProps) => {
    const {t} = useTranslation()

    return (
        <CardTemplate className={st.groups}>
            <StandardTitle title={t('groups')}/>
            <div className={st.groupList}>
                {
                    groups?.length ?
                        groups?.map((group: any) =>
                            <div className={st.groupCard}>
                                <div className={st.groupData}>
                                    <div className={st.groupName}>
                                        {group.name}
                                        <span className={st.groupAlias}> {group.alias}</span>
                                    </div>
                                    <div className={st.groupCounter}>
                                        {t('user.data.groupMembers')}:&nbsp;<Chip title={group.usersCount.toString()}/>
                                    </div>
                                </div>
                                {/*<Link to={'/d/groups/' + group.alias}>*/}
                                {/*    <Button*/}
                                {/*        type={'button'}*/}
                                {/*        color={'gray'}*/}
                                {/*        size={'md'}*/}
                                {/*        outline={false}*/}
                                {/*        title={t('more')}*/}
                                {/*        width={'auto'}*/}
                                {/*        className={''}*/}
                                {/*        isIcon={false} disabled={false}/>*/}
                                {/*</Link>*/}
                            </div>
                        ) :
                        <div className={st.noGroups}>
                            {t('user.data.noGroups')}
                        </div>
                }
            </div>
        </CardTemplate>
    );
};

export default UserGroupList;