import React, {useState} from 'react';
import CardTemplate from "../bases/CardTemplate";
import st from './UsersListTemplate.module.css'
import cn from "classnames";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid";
import Base from "../bases/Base";
import BaseTitle from "../titles/BaseTitle";

// type Props = {
//     title: String,
//     children: React.ReactNode
//     className?: any
// }
const UsersListTemplate = ({title, className, children, ...other}) => {
    const [open, setOpen] = useState(true);

    return (
        <CardTemplate className={cn(className, st.content)} {...other}>
            <>
                <div className={st.header}>
                    <BaseTitle title={title}/>
                    <div className={st.btnBlock}>
                        {
                            open ?

                                <Base className={st.btn} onClick={() => setOpen(false)}>
                                    <EyeSlashIcon className={st.btnIcon}/>
                                </Base>
                                :
                                <Base className={st.btn} onClick={() => setOpen(true)}>
                                    <EyeIcon className={st.btnIcon}/>
                                </Base>
                        }

                    </div>
                </div>
                {
                    open ?
                        <table className={st.table}>
                            <tbody>
                            {children}
                            </tbody>
                        </table>
                        :
                        <></>
                }
            </>
        </CardTemplate>
    );
};

export default UsersListTemplate;