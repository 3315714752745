import React, {useContext, useState} from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import Button from "../../../../components/UI/buttons/Button";
import cl from "./Groups.module.css"
import CreateGroupModal from "../../../../components/UI/modals/CreateGroupModal";
import CurrentGroupCard from "../../../../components/dashboard/group/CurrentGroupCard/CurrentGroupCard";
import GroupsListCard from "../../../../components/dashboard/group/GroupsListCard/GroupsListCard";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import {useTranslation} from "react-i18next";
import Input from "../../../../components/UI/inputs/Input";

const Groups = () => {
    const {t} = useTranslation();

    const {store} = useContext(Context);

    const [createGroupModal, setCreateGroupModal] = useState(false);

    const [searchValue, setSearchValue] = useState("");

    const swapGroup = (id) => {
        store.setCurrentGroupById(id);
    }

    const createGroup = async (data) => {
        if (await store.createGroupOwner(data)) {
            setCreateGroupModal(false);
        }
    }


    return (

        <Dashboard title={t('groups')}>
            <CurrentGroupCard/>

            <CardTemplate className={cl.currentGroupContainer}>
                    <Input
                        widthFull={true}
                        placeholder={t('search')}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                <Button
                    onClick={() => setCreateGroupModal(true)}
                    width={'sm-full'}
                    color={"gray"}
                    outline={true}
                >{t('groupPage.create')}</Button>
            </CardTemplate>

            <CardTemplate className={cl.groupsBlock}>
                <div className={cl.title}>{t('groupPage.groups')}</div>
                <div className={cl.groupsList}>
                    {store.currentUser.groups &&
                        store.currentUser.groups
                            .filter((group) =>
                                group.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                                group.description.toLowerCase().includes(searchValue.toLowerCase()) ||
                                group.alias.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            .map((group) =>
                            <GroupsListCard
                                key={group.id}
                                group={group}
                                isMine={true}
                                isActive={store.currentGroup.id === group.id}
                                action={swapGroup}
                            />
                        )
                    }
                </div>
            </CardTemplate>

            {/*<CardTemplate className={cl.groupsBlock}>*/}
            {/*    <div className={cl.title}>Каталог групп</div>*/}
            {/*    <div className={cl.groupsList}>*/}
            {/*        {*/}
            {/*            myGroups.map((group, index) => (*/}
            {/*                <GroupsListCard*/}
            {/*                    key={group.id}*/}
            {/*                    group={group}*/}
            {/*                    isMine={false}*/}
            {/*                    action={openApplyModal}*/}
            {/*                />*/}
            {/*            ))*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</CardTemplate>*/}

            <CreateGroupModal
                open={createGroupModal}
                setOpen={setCreateGroupModal}
                action={createGroup}
            />
        </Dashboard>
    );
};

export default observer(Groups);