import React, {useContext, useEffect, useState} from 'react';
import Dashboard from "../../../../components/templates/Dashboard/Dashboard";
import {useTranslation} from "react-i18next";
// import st from './GroupSchedule.module.css';
import {useParams} from "react-router-dom";
import {Context} from "../../../../index";
import {IGroup} from "../../../../models/IGroup";
import Schedule from "../../../../components/dashboard/schedule/Schedule";

const GroupSchedule = () => {
    const {t} = useTranslation();
    const {store} = useContext(Context)

    const groupAlias = useParams().group;

    const [group, setGroup] = useState<IGroup | undefined>({} as IGroup)

    // const [schedule, setSchedule] = useState<any[]>([])

    useEffect(() => {
        setGroup(store.getUserGroupByAlias(groupAlias || ''))
    }, [setGroup, store, groupAlias]);


    return (
        <Dashboard title={t('menu.schedule')}>
            <Schedule group={group?.name} university={group?.university}/>
        </Dashboard>
    );
};

export default GroupSchedule;