import React, {useContext, useState} from 'react';
import st from './ForgotPassword.module.css'
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import StandardTitle from "../../../../components/UI/titles/StandardTitle";
import Input from "../../../../components/UI/inputs/Input.jsx";
import Button from "../../../../components/UI/buttons/Button";
import {Context} from "../../../../index";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ForgotPassword = () => {
    const {t} = useTranslation()
    const {store} = useContext(Context)

    const [isButtonActive, setIsButtonActive] = useState(true)

    const [mailSent, setMailSent] = useState(false)

    const [value, setValue] = useState('')

    const sendEmail = async () => {
        setIsButtonActive(false)
        if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i)) {
            store.utils.notify('Неверный формат почты', 'error')
            setIsButtonActive(true)
            return
        }

        if (await store.user.sendResetPasswordEmail(value)) {
            store.utils.notify('Письмо отправлено')
            setMailSent(true)
        }
        setIsButtonActive(true)
    }

    return (
        <div className={st.BG}>
            <div className={st.content}>
                <CardTemplate className={st.modal}>
                    <StandardTitle title={t('login.forgotHeader')}/>
                    <div className={st.text}>
                        <p>
                            {
                                mailSent ?
                                    t('login.forgotDescription2')
                                    :
                                    t('login.forgotDescription')
                            }
                        </p>
                    </div>

                    <Input
                        name={'email'}
                        disabled={mailSent}
                        placeholder={'example@mail.ru'}
                        type={'email'} onChange={(e: any) => setValue(e.target.value)}
                    />

                    <Button
                        type={'button'}
                        title={t('login.forgotButton')}
                        color={'gray'}
                        size={'base'}
                        outline={false}
                        width={'auto'}
                        className={''}
                        disabled={value.length < 4 || mailSent || !isButtonActive}
                        onClick={sendEmail}
                        isIcon={false}/>

                    <div className={st.hiddenText}>
                        <Link to={'/'}>{t('login.goHome')}</Link>
                        <Link to={'/support'}>{t('support')}</Link>
                    </div>
                </CardTemplate>
            </div>
        </div>
    );
};

export default ForgotPassword;