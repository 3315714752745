import React from 'react';
import classes from './Contact.module.css';
import TeamCard from "../../components/home/TeamCard/TeamCard";
import PublicHome from "../../components/templates/PublicHome/PublicHome";

const Contact = () => {

    let team = [
        {
            "name": "Луковников Дмитрий",
            "photo": "Lukovnikov_TeamCard.webp",
            "priority": true,
            "speciality": [
                "Founder",
                "Back-end dev",
                "Front-end dev",
            ],
        },
        {
            "name": "Гальцев Михаил",
            "photo": "Galtsev_TeamCard_2.webp",
            "priority": false,
            "speciality": [
                "Technical lead",
                "Sponsor",
            ],
        },
        {
            "name": "Цаплина Елизавета",
            "photo": "Elisaveta_TeamLogo.webp",
            "priority": false,
            "speciality": [
                "Back-end dev",
            ],
        },
        {
            "name": "Кудлинков Николай",
            "photo": "Kudlinkov_TeamLogo.webp",
            "priority": false,
            "speciality": [
                "Front-end dev",
            ],
        },
        {
            "name": "Гизатуллина Алсу",
            "photo": "Gizatullina_TeamCard.webp",
            "priority": false,
            "speciality": [
                "Designer",
                "Front-end dev"
            ],
        },
        {
            "name": "Бабыкин Александр",
            "photo": "Babikin_TeamLogo.webp",
            "priority": false,
            "speciality": [
                "Back-end dev",
                "Front-end dev",
            ],
        },
        {
            "name": "Скоро",
            "photo": "Unknown_TeamLogo.png",
            "priority": false,
            "speciality": [
                "???",
            ],
        }
    ]

    return (
        <PublicHome>
            <div className={classes.pageContainer}>
                <div className={classes.titleContainer}>
                    <h2 className={classes.upTitle}>Контакты & Команда</h2>
                    <h1 className={classes.title}>Разработчики проекта </h1>
                </div>
                <div className={classes.teamContainer}>
                    {team.map((member, index) =>
                        <TeamCard key={index} member={member}/>
                    )}
                </div>
            </div>
        </PublicHome>
    );
};

export default Contact;