import React, {useContext, useEffect, useState} from 'react';
import st from './ManageAssignment.module.css';
// @ts-ignore
import {convertToRaw, EditorState, ContentState, convertFromHTML} from "draft-js";
// @ts-ignore
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
import CardTemplate from "../../../UI/bases/CardTemplate";
import StandardTitle from "../../../UI/titles/StandardTitle";
import {Context} from "../../../../index";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Input from "../../../UI/inputs/Input.jsx";
import Button from "../../../UI/buttons/Button";
import LightBadge from "../../../UI/badge/LightBadge/LightBadge";
import Chip from "../../../UI/badge/Chip/Chip";
import {IGroup} from "../../../../models/IGroup";
import cn from "classnames";
import Dropdown from "../../../UI/dropdown/Dropdown/Dropdown";
import {useNavigate} from "react-router-dom";
import DateTimeService from "../../../../services/DateTimeService";


type Props = {
    isNew: boolean;
    group?: string;
    assignmentId?: string;
}

const ManageAssignment = ({isNew, group, assignmentId}: Props) => {

    const {store} = useContext(Context);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [uGroup, setUGroup] = useState<IGroup>();
    const [assignment, setAssignment] = useState<any>();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [editorState, setEditorState] = useState<EditorState>(
        () => EditorState.createEmpty()
    );


    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        defaultValues: {
            title: '',
            content: null,
            deadline: null,
        },
        mode: "onBlur"
    });
    const onSetEditorState = async (newState: any) => {
        setEditorState(newState)
        // Устанавливаем в content значение
    }

    const onSubmit = async (
        data: any
    ) => {
        data.content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (data.deadline === '') {
            data.deadline = null;
        }
        try {
            if (isNew) {
                if (await store.assignment.create(uGroup?.id, data)) {
                    navigate(group ? `/g/${group}/assignments` : `/dashboard`)
                } else {
                    store.errorHandler('Не удалось создать задание')
                }
            } else {
                if (await store.assignment.update(assignmentId, data)) {
                    navigate(assignment.type === 'GROUP' ? `/g/${assignment.group.alias}/assignments` : `/dashboard`)
                } else {
                    store.errorHandler('Не удалось обновить задание')
                }
            }
        } catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        if (group) {
            let ugroup = store.getUserGroupByAlias(group);
            if (ugroup) {
                setUGroup(ugroup);
            }
        }
        if (isNew)
            setEditorState(EditorState.createEmpty());
    }, [
        isNew,
        group,
        assignmentId,
        store
    ]);

    useEffect(() => {
        const getData = async () => {
            if (assignmentId) {
                let assignment = await store.assignment.getById(assignmentId);
                if (assignment) {
                    reset({
                        title: assignment.title,
                        // @ts-ignore
                        deadline: assignment?.deadline ? DateTimeService.convertDateToInput(assignment.deadline) : null,
                        priority: assignment.priority,
                    })


                    const blocksFromHTML = convertFromHTML(assignment.content ?? '');
                    const state = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap,
                    );
                    setEditorState(EditorState.createWithContent(state, null));
                    setDataLoaded(true);
                    setAssignment(assignment);


                    if (assignment.type === 'GROUP') {
                        setUGroup(assignment.group);
                    }

                    // try {
                    //     const htmlContent = convertFromHTML(assignment.content ?? '');
                    //     const trimmedHTML = htmlContent.trim();
                    //     console.log('Trimmed HTML:', trimmedHTML);
                    //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(trimmedHTML)));
                    // } catch (error) {
                    //     console.error('Error setting editor state:', error);
                    // }
                }
            }
        }
        getData();
    }, [assignmentId, store, reset]);


    return (
        <form className={st.content} onSubmit={handleSubmit(onSubmit)}>
            <div className={st.mainInfo}>
                <CardTemplate className={cn(st.form, 'testBadge')}>
                    <StandardTitle title={t('assignments.info.title')}/>
                    <Input
                        title={t('title')}
                        name={'title'}
                        register={register}
                        errors={errors}
                        validationSchema={{
                            required: {
                                value: true,
                                message: t('valMessage.requiredField'),
                            },
                            minLength: {
                                value: 3,
                                message: t('valMessage.minSymbols3'),
                            },
                            maxLength: {
                                value: 60,
                                message: t('valMessage.maxSymbols60'),
                            },
                        }}
                        required
                    />
                    {
                        (isNew || dataLoaded) &&
                        <Editor
                            defaultEditorState={editorState}
                            onEditorStateChange={onSetEditorState}
                            // wrapperClassName={st.wrapperClass}
                            editorClassName={st.editorClass}
                            toolbarClassName={st.toolbarClass}
                            codeViewClassName={st.codeView}
                        />
                    }
                </CardTemplate>
            </div>
            <div className={st.extraInfo}>
                <Button color={isNew ? 'blue' : 'green'}
                        size={'base'}
                        outline={false}
                        title={isNew ? t('assignments.manage.publish') : t('assignments.manage.save')}
                        width={'auto'}
                        className={''}
                        isIcon={false}/>
                <CardTemplate className={st.form}>
                    <StandardTitle title={t('assignments.info.extraTitle')}/>
                    {
                        group || assignment?.type === 'GROUP' ?
                            <div className={st.groupTypeInfo}>
                                <LightBadge title={t('assignments.info.type')}
                                            value={t('assignments.info.groupType')}/>
                                <LightBadge title={t('assignments.info.group')}
                                            value={
                                                <Chip
                                                    title={uGroup?.name}
                                                    link={`/d/groups/${uGroup?.alias}`}
                                                    color={"gray"}
                                                />
                                            }/>
                            </div>
                            :
                            <LightBadge title={t('assignments.info.type')}
                                        value={t('assignments.info.personalType')}/>
                    }
                    <Input
                        title={t('assignments.info.deadline')}
                        name={"deadline"}
                        type={"date"}
                        register={register}
                        errors={errors}
                    />

                    <Dropdown
                        title={t('assignments.info.priority') ?? ''}
                        name={'priority'}
                        items={[
                            {value: '1', label: t('priority.default')},
                            {value: '2', label: t('priority.low')},
                            {value: '3', label: t('priority.medium')},
                            {value: '4', label: t('priority.high')},
                        ]}
                        register={register}
                    />
                </CardTemplate>
            </div>
        </form>
    );
};

export default ManageAssignment;