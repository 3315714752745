import React, {useContext} from 'react';
import OfficeBlock from "../OfficeBlock/OfficeBlock";
import classes from "./UserInfoCard.module.css";
// import Button from "../../../UI/buttons/Button";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
// import LightBadge from "../../../UI/badge/LightBadge/LightBadge";
import Input from "../../../UI/inputs/Input";
import {useTranslation} from "react-i18next";

const UserInfoCard = () => {
    const {store} = useContext(Context);
    const {t} = useTranslation();

    return (
        <OfficeBlock title={t('info')}>
            <div className={classes.container}>
                <div className={classes.imgBlock}>
                    <div className={classes.imgContainer}>
                        <div className={classes.avatarImg}>
                            <img src={store.currentUser.avatar} alt={store.currentUser.username}/>
                        </div>
                    </div>
                    <div className={classes.credirionals}>
                        <Input
                            type={'text'}
                            title={t('username')}
                            value={store.currentUser.username}
                            forView={true}
                        />
                        <Input
                            type={'text'}
                            title={t('email')}
                            value={store.currentUser.email}
                            disabled={true}
                            forView={true}
                        />
                        <Input
                            type={'text'}
                            title={t('lastName')}
                            value={store.currentUser.lastName}
                            forView={true}
                        />
                        <Input
                            type={'text'}
                            title={t('firstName')}
                            value={store.currentUser.firstName}
                            forView={true}
                        />
                        <Input
                            type={'text'}
                            title={t('patronymic')}
                            value={store.currentUser.patronymic}
                            forView={true}
                        />
                    </div>
                    {/*<div className={classes.imgButtons}>*/}
                    {/*<FileInput>Загрузить аватар</FileInput>*/}
                    {/*<Button>Обновить</Button>*/}
                    {/*<Button>Удалить</Button>*/}
                    {/*</div>*/}
                </div>
            </div>
            {/*<div className={classes.buttonsContainer}>*/}
            {/*<div className={classes.btnContainer}>*/}
            {/*    <Button color={}>Сохранить</Button>*/}
            {/*</div>*/}
            {/*</div>*/}
        </OfficeBlock>
    );
};

export default observer(UserInfoCard);