import React, {useContext} from 'react';
import st from './GeneralGroupInfo.module.css'
import CardTemplate from '../../../../UI/bases/CardTemplate';
import {useTranslation} from "react-i18next";
import UserInGroupList from "../../UserInGroupList/UserInGroupList";
import {UserInGroup} from '../../../../../models/UserInGroup';
import {Context} from "../../../../../index";
import LightBadge from "../../../../UI/badge/LightBadge/LightBadge";
import {IGroup} from "../../../../../models/IGroup";
import StandardTitle from "../../../../UI/titles/StandardTitle";

type GeneralGroupInfoProps = {
    group: IGroup;
}

const GeneralGroupInfo = ({group}: GeneralGroupInfoProps) => {
    const {t} = useTranslation();
    const {store} = useContext(Context);


    return (
        <div>
            <div className={st.content}>
                <CardTemplate className={st.list}>
                    <StandardTitle>{t('users')}
                        <span className={st.count}>
                                {
                                    group.users &&
                                    `(${group.users.length})`
                                }
                                </span>
                    </StandardTitle>
                    <div className={st.listContainer}>
                        {
                            group.users &&
                            group.users.map((user: UserInGroup, index: number) =>
                                <UserInGroupList index={index} user={user} key={index}/>
                            )
                        }
                        {
                            group.users &&
                            group.users.length < 2 &&
                            <CardTemplate className={st.emptyList}>
                                {t('groupPage.groupView.emptyList1')}
                                <span className={'template__base  rounded-md h-5 text-sm px-1 font-semibold transition'}>{t('groupPage.tabs.settings')}</span>
                                {t('groupPage.groupView.emptyList2')}
                                <span className={'template__base  rounded-md h-5 text-sm px-1 font-semibold transition'}>{t('groupPage.groupView.emptyList3')}</span>.
                            </CardTemplate>
                        }
                    </div>
                </CardTemplate>
                <div className={st.infoBlock}>
                    <CardTemplate
                        className={`${st.mainInfoContainer} ${group?.id === store.currentGroup.id ? 'activeBadge' : ''}`}>
                        <StandardTitle>{t('groupPage.groupView.information')}</StandardTitle>
                        <div className={st.mainInfo}>
                            {
                                group.university &&
                                <LightBadge title={t('university')} value={group.university?.shortName}/>
                            }
                            <LightBadge title={t('group')} value={group.name}/>
                            <LightBadge title={t('code')} value={group.alias}/>
                            {
                                group.description &&
                                <LightBadge title={t('description')} value={group.description}/>
                            }
                        </div>
                    </CardTemplate>
                </div>
            </div>
        </div>
    );
};

export default GeneralGroupInfo;