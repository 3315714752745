import React, {useContext, useState} from 'react';
import st from './ConfirmEmail.module.css'
import CardTemplate from "../../../../components/UI/bases/CardTemplate";
import StandardTitle from "../../../../components/UI/titles/StandardTitle";
import Input from "../../../../components/UI/inputs/Input.jsx";
import Button from "../../../../components/UI/buttons/Button";
import {Context} from "../../../../index";
import {Link, useNavigate} from "react-router-dom";

const ConfirmEmail = () => {

    const {store} = useContext(Context)
    const navigate = useNavigate()

    const [isButtonActive, setIsButtonActive] = useState(true)
    const [value, setValue] = useState('')

    const checkCode = async () => {
        setIsButtonActive(false)
        if (await store.user.confirmEmail(value)) {
            store.utils.notify('Почта подтверждена')
            navigate('/dashboard')
        }
        setIsButtonActive(true)
    }

    const resendCode = async () => {
        setIsButtonActive(false)
        if (await store.user.resendConfirmEmail()) {
            store.utils.notify('Новое письмо отправлено')
        }
        setIsButtonActive(true)
    }

    if (store.currentUser.status === 'ACTIVE') navigate('/dashboard')

    return (
        <div className={st.BG}>
            <div className={st.content}>
                <CardTemplate className={st.modal}>
                    <StandardTitle title={'Подтвердите почту'}/>
                    <div className={st.text}>
                        <p>На вашу почту отправлено письмо с кодом для подтверждения.


                        </p>
                        <CardTemplate className={'font-bold bg-red-500 bg-opacity-50'}>
                                Не покидайте эту страницу, пока не введете код, иначе регистрация будет отменена!
                        </CardTemplate>
                    </div>
                    <Input
                        type={'text'} placeholder={'Введите код'} onChange={(e: any) => setValue(e.target.value)}
                    />

                    <Button
                        type={'button'}
                        title={'Подтвердить'}
                        color={'gray'}
                        size={'base'}
                        outline={false}
                        width={'auto'}
                        className={''}
                        disabled={!isButtonActive || value.length !== 6}
                        onClick={checkCode}
                        isIcon={false}/>

                    <div className={st.hiddenText}>
                        <div>Не пришло письмо?</div>
                        <div onClick={resendCode} className={st.sendAgain}>Отправить повторно</div>
                    </div>
                    <div className={st.hiddenText}>
                        <div onClick={() => store.logout()} className={st.exit}>Выйти из аккаунта</div>
                        <Link to={'/to'}>Поддержка</Link>
                    </div>
                </CardTemplate>
            </div>

        </div>
    );
};

export default ConfirmEmail;