import React from 'react';
import st from './StatusBadge.module.css';
import cn from "classnames";
import {useTranslation} from "react-i18next";


type StatusBadgeProps = {
    status: "ACTIVE" | "WAITING" | "BANNED" | "DELETED"
}

const StatusBadge = ({status}: StatusBadgeProps) => {
    const {t} = useTranslation();
    return (
        <>
            {
                status === "ACTIVE" &&
                <span className={cn(st.status, st.active)}>{t('statuses.active')}</span>
            }
            {
                status === "WAITING" &&
                <span className={cn(st.status, st.waiting)}>{t('statuses.waiting')}</span>
            }
            {
                status === "BANNED" &&
                <span className={cn(st.status, st.banned)}>{t('statuses.banned')}</span>
            }
            {
                status === "DELETED" &&
                <span className={cn(st.status, st.deleted)}>{t('statuses.deleted')}</span>
            }
        </>
    )
};

export default StatusBadge;