import React from "react";
import cl from "./ComponentList.module.css"
import Button from "../../components/UI/buttons/Button";
import Input from "../../components/UI/inputs/Input";
import ComponentBlock from "./ComponentBlock";
import Textarea from "../../components/UI/inputs/Textarea";
import 'react-toastify/dist/ReactToastify.css';
import {observer} from "mobx-react-lite";
import Badge from "../../components/UI/badge/Badge/Badge";
import LightBadge from "../../components/UI/badge/LightBadge/LightBadge";

const ComponentList = () => {
    // const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 3000));
    // toast.promise(
    //     resolveAfter3Sec,
    //     {
    //         pending: 'Promise is pending',
    //         success: 'Promise resolved 👌',
    //         error: 'Promise rejected 🤯'
    //     }
    // )
    //
    // const functionThatReturnPromise = () => new Promise(resolve => setTimeout(resolve, 3000));
    // toast.promise(
    //     functionThatReturnPromise,
    //     {
    //         pending: 'Promise is pending',
    //         success: 'Promise resolved 👌',
    //         error: 'Promise rejected 🤯'
    //     }
    // )

    // const response =  toast.promise(
    //     fetch("A_URL"),
    //     {
    //         pending: 'Promise is pending',
    //         success: 'Promise resolved 👌',
    //         error: 'Promise rejected 🤯'
    //     }
    // );
    // console.log(response)


    return (
        <div className={cl.bgComponent}>
            <div className={cl.componentsContainer}>
                <ComponentBlock title={'Кнопки sm'}>
                    <Button color={"red"} size={"sm"}>Red</Button>
                    <Button color={"gray"} size={"sm"}>Gray</Button>
                    <Button color={"blue"} size={"sm"}>Blue</Button>
                    <Button color={"green"} size={"sm"}>Green</Button>
                    <Button color={"orange"} size={"sm"}>Orange</Button>
                    <Button color={"dark"} size={"sm"}>DarkGray</Button>

                    <Button color={"red"} size={"sm"} outline={true}>Red</Button>
                    <Button color={"gray"} size={"sm"} outline={true}>Gray</Button>
                    <Button color={"blue"} size={"sm"} outline={true}>Blue</Button>
                    <Button color={"green"} size={"sm"} outline={true}>Green</Button>
                    <Button color={"orange"} size={"sm"} outline={true}>Orange</Button>
                    <Button color={"dark"} size={"sm"} outline={true}>DarkGray</Button>
                </ComponentBlock>
                <ComponentBlock title={'Кнопки base'}>
                    <Button color={"red"}>Red</Button>
                    <Button color={"gray"}>Gray</Button>
                    <Button color={"blue"}>Blue</Button>
                    <Button color={"green"}>Green</Button>
                    <Button color={"orange"}>Orange</Button>
                    <Button color={"dark"}>DarkGray</Button>

                    <Button color={"red"} outline={true}>Red</Button>
                    <Button color={"gray"} outline={true}>Gray</Button>
                    <Button color={"blue"} outline={true}>Blue</Button>
                    <Button color={"green"} outline={true}>Green</Button>
                    <Button color={"orange"} outline={true}>Orange</Button>
                    <Button color={"dark"} outline={true}>DarkGray</Button>
                </ComponentBlock>

                <ComponentBlock title={'Кнопки lg'}>
                    <Button color={"red"} size={"lg"}>Red</Button>
                    <Button color={"gray"} size={"lg"}>Gray</Button>
                    <Button color={"blue"} size={"lg"}>Blue</Button>
                    <Button color={"green"} size={"lg"}>Green</Button>
                    <Button color={"orange"} size={"lg"}>Orange</Button>
                    <Button color={"dark"} size={"lg"}>DarkGray</Button>

                    <Button color={"red"} size={"lg"} outline={true}>Red</Button>
                    <Button color={"gray"} size={"lg"} outline={true}>Gray</Button>
                    <Button color={"blue"} size={"lg"} outline={true}>Blue</Button>
                    <Button color={"green"} size={"lg"} outline={true}>Green</Button>
                    <Button color={"orange"} size={"lg"} outline={true}>Orange</Button>
                    <Button color={"dark"} size={"lg"} outline={true}>DarkGray</Button>
                </ComponentBlock>
                <ComponentBlock title={'Инпуты'} className={'flex-col content-start'}>
                    <Input widthFull={true} title={"Your Name "} outline={false}></Input>
                    <Input widthFull={true} title={"Password"} outline={false} statement="Error passwrod"></Input>

                    <Input widthFull={true} title={"Phone number (outline)"}></Input>
                    <Input widthFull={true} title={"E-mail (outline)"} statement="wrong E-mail"></Input>
                    <Textarea outline={false} widthFull={true} title={"Test"} statement="wrong"></Textarea>
                    <Textarea widthFull={true} title={"Test"} statement="wrong"></Textarea>
                </ComponentBlock>

                <ComponentBlock title={'Badges'} className={'flex gap-8'}>
                    <Badge title={'Название'} value={'Содержание (Badge)'}/>
                    <LightBadge title={'Название'} value={'Содержание (LightBadge)'}/>
                </ComponentBlock>

                <ComponentBlock title={'Шрифты'} className={'text-white'}>
                    <div className={'text-sm'}>
                        Text sm
                    </div>
                    <div className={'text-base'}>
                        Text base
                    </div>
                    <div className={'text-lg'}>
                        Text lg
                    </div>
                    <div className={'text-xl'}>
                        Text xl
                    </div>
                    <div className={'text-2xl'}>
                        Text 2xl
                    </div>
                    <div className={'text-3xl'}>
                        Text 3xl
                    </div>
                    <div className={'text-4xl'}>
                        Text 4xl
                    </div>
                    <div className={'text-5xl'}>
                        Text 5xl
                    </div>
                    <div className={'text-6xl'}>
                        Text 6xl
                    </div>
                    <div className={'text-7xl'}>
                        Text 7xl
                    </div>
                    <div className={'text-8xl'}>
                        Text 8xl
                    </div>
                    <div className={'text-9xl'}>
                        Text 9xl
                    </div>
                </ComponentBlock>
            </div>
        </div>
    )
}

export default observer(ComponentList);